import useIsMobile from "@Hooks/selector/useIsMobile";
import {useAppSelector} from "@Hooks/selector/useAppSelector";
import useDispatchRoutePopover from "@Route/hooks/useDispatchRoutePopover";
import useRedirectToUrlWithRouteId from "@SearchResults/hooks/useRedirectToUrlWithRouteId";
import useExpandPreselectedRoute from "@Route/hooks/useExpandPreselectedRoute";
import {PreparedRouteType} from "@Route/types/PreparedRoute.type";
import ParamsType from "@SearchResults/types/params/Params.type";
import useClickTripEvent from "@Events/hooks/useClickTripEvent";
import getTabBasedOnRouteTransferType from "@Route/features/transportType/functions/getTabBasedOnRouteTransferType";
import useNavigateWithGeneratedSearchParams from "@Routing/hooks/useNavigateWithGeneratedSearchParams";
import {useLocation} from "react-router";

/**
 * Handles tour click interaction and scrolling to element
 */

type UseActivateTourProps = {
    tour: PreparedRouteType,
    isCurrentRouteSingle: boolean,
    params: ParamsType,
    routeExpandable: boolean,
    isReturn?: boolean
}

export default function useActivateTour(props: UseActivateTourProps) {
    const {
        tour,
        isCurrentRouteSingle,
        routeExpandable,
        params,
        isReturn,
    } = props

    const {redirectToUrlWithRouteId} = useRedirectToUrlWithRouteId()
    const activateTripEvent = useClickTripEvent({route: tour, params})
    const isMobile = useIsMobile()
    const {dispatchSoldOutPopover} = useDispatchRoutePopover(tour)
    const {elRef, executeScroll} = useExpandPreselectedRoute(tour)
    const {navigateWithSearchParams} = useNavigateWithGeneratedSearchParams()
    const {state: oldState} = useLocation()

    const activateTour = () => {
        activateTripEvent()
        if (!routeExpandable) {
            return dispatchSoldOutPopover()
        }
        if (!isMobile) {
            // we're using raw history here as the method above updates the router state
            // and causes re-render which is not wanted on desktop
            if (isCurrentRouteSingle) {
                redirectToUrlWithRouteId(getTabBasedOnRouteTransferType(tour.type.routeTransferType), tour.id)
            }
            executeScroll()
        } else {
            navigateWithSearchParams(
                getTabBasedOnRouteTransferType(tour.type.routeTransferType),
                tour.id,
                {
                    route: tour,
                    singleConfirmedRoute: (!isMobile && params.isReturn && !isReturn) ? tour : oldState?.singleConfirmedRoute ?? undefined,
                },
                false
            )
        }
    }

    return {
        activateTour,
        elRef,
    }
}