import React from 'react';
import RouteItinerary from "@Route/components/Sections/RouteItinerary";
import TicketInfoLabel from "@CheckOrder/components/Sidebar/TicketInfoLabel";
import {isEmpty} from "@Array/isEmpty";
import CompanySpecificTerms from "@CheckOrder/components/Sidebar/CompanySpecificTerms";
import CompanyType from "@SearchResults/types/company/Company.type";
import RouteType from "@Route/types/Route.type";
import TicketTerms from "@CheckOrder/components/Sidebar/TicketTerms";
import TicketType from "@/components/Ticket/TicketType";
import { displayTicketType } from '@/functions/overrides/displayTicketType';
import {isTourRoute} from "@SearchResults/functions/isTourRoute";
import TourItinerary from "@SearchResults/features/tours/features/itinerary/TourItinerary";
import Row from "@Containers/components/Row";

interface TicketInfoProps {
    company: CompanyType,
    route: RouteType,
    isReturn?: boolean,
    isIntermodal?: boolean,
}

function TicketInfo({company, route, isReturn = false, isIntermodal = false}: TicketInfoProps) {
    const {
        status: {
            hasMTicket,
            providerId,
        },
        travelInfo: {
            departureStation: {
                departureDate,
            },
        },
    } = route

    const isTour = isTourRoute(route)

    return (
        <>
            <TicketInfoLabel
                companyId={company.id}
                companyLogoUrl={company.logoUrl}
                isIntermodal={isIntermodal}
                isReturn={isReturn}
                date={departureDate}
                isTour={isTour}
            />
            {displayTicketType(route) && (
                <TicketType
                    hasMTicket={hasMTicket}
                />
            )}
            <hr/>
            <div className="gb--route-details">
                <div className="route-expanded-details-section">
                    {isTour ? (
                        <Row>
                            <TourItinerary tour={route}/>
                        </Row>
                    ) : (
                        <RouteItinerary
                            route={route}
                            companyLetterCode={company.letterCode}
                        />
                    )}
                </div>
            </div>
            <TicketTerms
                terms=""
            />
            {!isEmpty(route.travelInfo?.notes) && (
                <CompanySpecificTerms
                    notes={route.travelInfo.notes}
                />
            )}
        </>
    )
}

export default TicketInfo