import React from 'react';
import Filters from "@SearchResults/features/sidebar/components/Filters";
import Button from "@Generic/Button";
import {toggleFiltersOverlay} from "@/reducers/mobileSlice";
import MobileOverlay from "./MobileOverlay";
import {useAppDispatch} from "@Hooks/dispatch/useAppDispatch";
import useSearchResults from "@SearchResults/hooks/useSearchResults";

function MobileFilters() {
    const dispatch = useAppDispatch()
    const toggleFilters = () => dispatch(toggleFiltersOverlay())
    const searchResults = useSearchResults()

    return (
        <MobileOverlay title="Filters" backClick={toggleFilters} slideInDirection="down">
            {searchResults?.data && (
                <Filters
                    params={searchResults.data.result.params}
                />
            )}
            <Button
                className="green"
                additionalClasses="gb--mobile-overlay-confirm-button"
                onClick={toggleFilters}
            >
                Apply filters
            </Button>
        </MobileOverlay>
    );
}

export default MobileFilters;