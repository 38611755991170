import React from 'react';
import IconFilterWithCount from "@Icons/IconFilterWithCount";
import {toggleFiltersOverlay} from "@Reducers/mobileSlice";
import {useAppDispatch} from "@Hooks/dispatch/useAppDispatch";

function MobileFiltersButton() {
    const dispatch = useAppDispatch()
    const onClick = () => dispatch(toggleFiltersOverlay())

    return (
        <button onClick={onClick} type="button">
            <IconFilterWithCount/>
        </button>
    );
}

export default MobileFiltersButton;