import React from 'react';
import Translation from "@Translation/components/Translation";
import Row from "@Containers/components/Row";
import CompanyLogo from "@Route/features/company/components/CompanyLogo";
import IconCalendarWithDate from "@Icons/IconCalendarWithDate";
import {useTranslation} from "@Translation/hooks/useTranslation";

interface TicketInfoLabelProps {
    isReturn: boolean,
    isIntermodal: boolean,
    companyId: number | string,
    companyLogoUrl: string,
    date: string,
    isTour: boolean
}

function TicketInfoLabel({isReturn, isIntermodal, companyId, companyLogoUrl, date, isTour}: TicketInfoLabelProps) {
    const regularLabel = isReturn ? "check_order.return_trip_info" : "check_order.outbound_trip_info"
    const label = isIntermodal ? "sys.ticket_info" : regularLabel
    const dayTripDetailsLabel = useTranslation("tour.day_trip_details")

    return (
        <Row justify center>
            {isTour ? (
                <span className="gb--card-title gb--emphasize-default">
                    {dayTripDetailsLabel}
                </span>
            ) : (
                <span className="gb--card-title gb--emphasize-default">
                    <Translation translationKey={label}/>
                </span>
            )}

            <CompanyLogo
                companyId={companyId}
                logoUrl={companyLogoUrl}
            />
        </Row>
    );
}

export default TicketInfoLabel;