import {resetAllFilters} from "@SearchResults/reducers/filterSlice";
import {useAppDispatch} from "@Hooks/dispatch/useAppDispatch";

export default function useResetAllFilters() {
    const dispatch = useAppDispatch()
    const resetFilters = () => {
        dispatch(resetAllFilters())
    }

    return {
        resetFilters,
    }
}