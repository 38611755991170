import React from 'react'
import useRouteActivePassengerGroups from "@Route/hooks/useRouteActivePassengerGroups";
import {useTranslation} from "@Translation/hooks/useTranslation";
import Column from "@Containers/components/Column";
import PriceWithPossibleDiscount from "@Route/components/Price/PriceWithPossibleDiscount";
import RouteWithAddedPropsAndPricesType from "@Route/types/RouteWithAddedPropsAndPrices.type";
import TourWithAddedPropsAndPricesType from "@SearchResults/features/tours/types/TourWithAddedPropsAndPrices.type";

interface RoutePerPassengerPriceProps {
    route: RouteWithAddedPropsAndPricesType | TourWithAddedPropsAndPricesType
    className?: string,
    align?: boolean
}

const RoutePerPassengerPrice = (props: RoutePerPassengerPriceProps) => {
    const {
        route,
        className = "gb--emphasize-default route--price-total",
        align = false,
    } = props

    const activePassengerGroupsWithPrices = useRouteActivePassengerGroups(route)
    const normalTicketGroup = activePassengerGroupsWithPrices.find((group) => group.isActive && group.isNormal)
    const perPersonLabel = useTranslation("tour.per_person")

    const {
        priceWithDiscount,
        priceWithoutDiscount,
    } = normalTicketGroup ?? {}

    return (
        <Column alignEnd={align} className={className}>
            <PriceWithPossibleDiscount
                priceWithoutDiscount={priceWithoutDiscount}
                priceWithDiscount={priceWithDiscount}
                priceClassName="route--price-currency"
            />
            <small>{perPersonLabel}</small>
        </Column>
    )
}

export default RoutePerPassengerPrice