import React from 'react';
import HeroVectorLarge from "@Form/components/HeroVectorLarge";
import HeroVectorSmall from "@Form/components/HeroVectorSmall";
import useRouteMatch from "@Routing/hooks/useRouteMatch";

function HeroBackgroundMain() {
    const isHomepage = useRouteMatch('homepage')
    if (isHomepage) {
        return (
            <HeroVectorLarge/>
        )
    }

    return (
        <HeroVectorSmall/>
    )
}

export default HeroBackgroundMain
