// description need to have a format:
// #title#
// [listItem1 @ listItem2 @ listItem3]/
// #title2#
// [listItem1 @ listItem2 @ listItem3]/
export default function useTourDescription(description: string) {
    const descriptionParts = description.split('/')
    return descriptionParts.filter((item) => item !== '').map((column) => {
        const title = column.substring(column.indexOf('#') + 1, column.lastIndexOf('#')).trim()
        const content = column.substring(column.indexOf('[') + 1, column.lastIndexOf(']'))
        const listItems = content.split('@').filter((item) => item !== '').map((item) => item.trim())

        return {
            title,
            list: listItems,
        }
    })
}