import React from 'react'
import Notification from "@Features/notification/components/Notification";
import {useTranslation} from "@Translation/hooks/useTranslation";

interface TourIncludesNotificationProps {
    content: string,
    className?: string
}

const TourIncludesNotification = ({content, className = ""}: TourIncludesNotificationProps) => {
    const tourIncludesLabel = useTranslation("tour.includes")

    return (
        <Notification notificationType="blue" isBorder additionalClass={`gb--tour-notification ${className}`}>
            <span className="gb--emphasize gb--emphasize-info">
                {tourIncludesLabel}
            </span>
            <br/>
            <span>
                {content}
            </span>
        </Notification>
    )
}

export default TourIncludesNotification