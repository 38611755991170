import React from 'react';
import AncillarySummary from "@CheckOrder/features/paymentSummary/features/ancillariesSummary/components/AncillarySummary";
import {useAppSelector} from "@Hooks/selector/useAppSelector";

const AncillariesSummary = () => {
    const {isBuyingPremiumSupport, isBuyingSMSNotification} = useAppSelector((state) => state.checkOrder);
    const {displayPrice: premiumSupportPrice, displayCurrencySymbol: premiumSupportCurrency} = useAppSelector((state) => state.checkOrder.ticket.ancillariesData.premiumSupport);
    const {displayPrice: smsNotificationPrice, displayCurrencySymbol: smsNotificationCurrency} = useAppSelector((state) => state.checkOrder.ticket.ancillariesData.smsNotification);
    return (
        <>
            {isBuyingPremiumSupport && (
                <AncillarySummary
                    price={premiumSupportPrice}
                    currencySymbol={premiumSupportCurrency}
                    ancillaryNameKey="sys.premium_support"
                />
            )}
            {isBuyingSMSNotification && (
                <AncillarySummary
                    price={smsNotificationPrice}
                    currencySymbol={smsNotificationCurrency}
                    ancillaryNameKey="sys.sms_notification"
                />
            )}
        </>
    );
}

export default AncillariesSummary
