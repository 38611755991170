import PassengerType from "@CheckOrder/types/Passenger.type";
import ajaxHandler from "@Ajax/v2/ajaxHandler";
import {addPassengerToResolvedList} from "@CheckOrder/reducers/checkOrderSlice";
import {useDispatch} from "react-redux";
import IntermodalPassengerType from "@CheckOrder/types/IntermodalPassenger.type";
import {useAppSelector} from "@Hooks/selector/useAppSelector";
import usePrepareAdditionalInfoForPassengerSubmit
    from "@CheckOrder/features/TicketAdditionalInfo/functions/usePrepareAdditionalInfoForPassengerSubmit";

export default function useSubmitPassengers(handleError, cancelToken) {
    const dispatch = useDispatch()
    const {
        isIntermodal,
    } = useAppSelector((state) => state?.checkOrder?.ticket)

    const {prepareAdditionalInfo} = usePrepareAdditionalInfoForPassengerSubmit()

    const updatePassenger = async (params) => {
        try {
            const response = await ajaxHandler({
                url: "/ajax/ticket-update-passenger",
                type: "post",
                handle: "ignore",
                stringify: true,
                params,
                cancelToken,
            })
            if (response?.data?.data?.success) {
                dispatch(addPassengerToResolvedList(response.data.data.passenger.passengerId))
                return response.data.data.passenger.passengerId;
            }
            await handleError('Error adding passenger')
        } catch (error) {
            await handleError(error)
        }
    }

    const areIntermodalPassengers = (passengers, isIntermodal: boolean): passengers is IntermodalPassengerType[] => (isIntermodal)

    const submitRegularPassengers = (passengers: PassengerType[]) => (
        Promise.all(passengers.map(async (passenger) => {
            const params = {
                ...passenger,
                // We only care about the ISO, ignore the name and dial code
                nationality: passenger?.nationality?.isoCode,
                additionalInfo: prepareAdditionalInfo(passenger.additionalInfo),
            };

            return updatePassenger(params)
        }))
    )

    const submitIntermodalPassengers = async (passengers: IntermodalPassengerType[]) => (
        Promise.all(passengers.map((passenger) => (
            passenger.basePassengers.map(async (basePassenger) => (
                updatePassenger({
                    ...basePassenger,
                    dateOfBirth: passenger.dateOfBirth,
                    gender: passenger.gender,
                    nationality: passenger?.nationality?.isoCode,
                    passengerFirstName: passenger.passengerFirstName,
                    passengerLastName: passenger.passengerLastName,
                    travelDocument: passenger.travelDocument,
                    passengerDocumentCode: passenger.passengerDocumentCode,
                })))
        )))
    )

    const submitPassengers = async (passengers: PassengerType[] | IntermodalPassengerType[]) => (
        areIntermodalPassengers(passengers, isIntermodal) ? submitIntermodalPassengers(passengers) : submitRegularPassengers(passengers)
    )

    return {
        submitPassengers,
    }
}