import Row from "@Containers/components/Row";
import useFixedEurConversion from "@Currency/hooks/useFixedEurConversion";

type BookingFeeProps = {
    bookingFeeLabel: string,
    bookingFee: string,
}

function BookingFee({bookingFeeLabel, bookingFee}: BookingFeeProps) {
    const {shouldDisplayPriceInKn, priceInKn} = useFixedEurConversion(
        bookingFee,
        true,
        false
    )

    return (
        <Row justify center>
            <span>
                {bookingFeeLabel}
            </span>
            <span>
                {bookingFee}
                {shouldDisplayPriceInKn && ` ${priceInKn}`}
            </span>
        </Row>
    );
}

export default BookingFee;