import React from 'react';
import {useTranslation} from "@Translation/hooks/useTranslation";

function JadrolinijaTermsAndConditions() {
    const privacyPolicyLabel = useTranslation('menu.footer.privacy_policy')
    const generalTermsLabel = useTranslation('menu.footer.general_terms')
    const generalsTermsHref = useTranslation('check_order.terms_and_conditions_jadrolinija_link')
    const GetByFerryHref = () => (`<a target="_blank" href="//getbyferry.com/en/privacy-policy">${privacyPolicyLabel}</a>`)
    const GetByFerryTerms = () => (`<a target="_blank" href="//getbyferry.com/en/general-terms">${generalTermsLabel}</a>`)
    const JadrolinijaHref = () => (`<a target="_blank" href=${generalsTermsHref}>${generalTermsLabel}</a>`)

    const terms = useTranslation('check_order.terms_and_conditions_jadrolinija', {
        privacyPolicy: GetByFerryHref,
        termsAndConditions: GetByFerryTerms,
        jadrolinijaTermsAndConditions: JadrolinijaHref,
    }, false)

    return (
        <span dangerouslySetInnerHTML={{__html: (`${terms}`)}}/>
    );
}

export default JadrolinijaTermsAndConditions;