import React from 'react';
import {toggleCurrencySelect, toggleSorter} from "@Reducers/mobileSlice";
import IconMoney from "@Icons/IconMoney";
import IconSort from "@Icons/IconSort";
import useCurrency from "@Currency/hooks/useCurrency";
import {useTranslation} from "@Translation/hooks/useTranslation";
import Row from "@Containers/components/Row";
import IconPaxWithLuggage from "@Icons/FilledOutline/IconPaxWithLuggage";
import Column from "@Containers/components/Column";
import {useAppDispatch} from "@Hooks/dispatch/useAppDispatch";
import {useAppSelector} from "@Hooks/selector/useAppSelector";
import MobileSortButton from "@SearchResults/features/sorting/components/MobileSortButton";
import sortingOptions from "@SearchResults/features/sorting/constants/sortingOptions";
import ActiveFiltersNotification from "@SearchResults/components/HeadingContent/ActiveFiltersNotification";

function MobileSorterContainer() {
    const dispatch = useAppDispatch()
    const selectedCurrency = useCurrency()
    const selectedSorting = useAppSelector((state) => state?.filter.sortBy)
    const selectedSortLabel = useTranslation(sortingOptions.find(({value}) => value === selectedSorting).label)

    return (
        <Row justify className="gb--sorting">
            <ActiveFiltersNotification displayFilterCount={false}/>
            <Row className="sort-by-container">
                <Column center>
                    <IconPaxWithLuggage width="26" height="28"/>
                    <MobileSortButton
                        onClick={() => dispatch(toggleSorter())}
                        icon={<IconSort sortingOrientation="ascending"/>}
                        title="sys.sort_by"
                        selectedValue={selectedSortLabel}
                    />
                </Column>
                <MobileSortButton
                    onClick={() => dispatch(toggleCurrencySelect())}
                    icon={<IconMoney/>}
                    // title="currency"
                    selectedValue={selectedCurrency?.val}
                />
            </Row>
        </Row>
    );
}

export default MobileSorterContainer;