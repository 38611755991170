import React from 'react';
import GenericSvgIcon from "@Icons/GenericSvgIcon";
import IconType from "@/types/Icon.type";

interface IconSortProps extends IconType {
    sortingOrientation: 'descending' | 'ascending'
}

function IconSort(props: IconSortProps) {
    const {
        width = '16',
        height = '16',
        fill = '#fff',
        viewBox = '0 0 16 16',
        title = 'IconSort',
        sortingOrientation,
        ...rest
    } = props

    const orientation = sortingOrientation === "ascending" ? "rotate(-180deg)" : null;
    const style = {verticalAlign: "middle", transform: `${orientation}`};
    return (
        <GenericSvgIcon
            width={width}
            height={height}
            fill={fill}
            viewBox={viewBox}
            title={title}
            {...rest}
            style={style}
            svgIconPath={(
                <path
                    d="m0 4.61538462c0 .33986753.27551708.61538461.61538462.61538461h14.76923078c.3398675 0 .6153846-.27551708.6153846-.61538461 0-.33986754-.2755171-.61538462-.6153846-.61538462h-14.76923078c-.33986754 0-.61538462.27551708-.61538462.61538462zm0 3.69230769c0 .33986754.27551708.61538461.61538462.61538461h11.07692308c.3398675 0 .6153846-.27551707.6153846-.61538461s-.2755171-.61538462-.6153846-.61538462h-11.07692308c-.33986754 0-.61538462.27551708-.61538462.61538462zm0 3.69230769c0 .3398675.27551708.6153846.61538462.6153846h7.38461538c.33986754 0 .61538462-.2755171.61538462-.6153846s-.27551708-.6153846-.61538462-.6153846h-7.38461538c-.33986754 0-.61538462.2755171-.61538462.6153846z"
                />
            )}
        />

    );
}

export default IconSort;
