import React from 'react';
import TermWithCheckbox from "@Generic/TermWithCheckbox";
import GbbTermsAndConditions from "./GbbTermsAndConditions";
import JadrolinijaTermsAndConditions from "./JadrolinijaTermsAndConditions";
import CompanySpecificTermsAndConditions
    from "@CheckOrder/components/MainSection/TermsAndConditions/Checkboxes/CompanySpecificTermsAndConditions";
import {useTranslation} from "@Translation/hooks/useTranslation";

type TosAndPrivacyPolicyProps = {
    userConfirmed: boolean,
    singleCompanyId: number,
    onClick: () => void,
    value: boolean,
    singleRouteCompanyTermsUrl: null | string,
    returnRouteCompanyTermsUrl: null | string,
    carrierName: string,
}

function TosAndPrivacyPolicy(props: TosAndPrivacyPolicyProps) {
    const {
        userConfirmed,
        singleCompanyId,
        onClick,
        value,
        singleRouteCompanyTermsUrl,
        returnRouteCompanyTermsUrl,
        carrierName,
    } = props

    return (
        <TermWithCheckbox
            onClick={onClick}
            value={value}
            userConfirmed={userConfirmed}
            errorLabelText={useTranslation("t_summary.you_must_accept")}
        >
            {(singleRouteCompanyTermsUrl || returnRouteCompanyTermsUrl) ? (
                <CompanySpecificTermsAndConditions
                    singleRouteCompanyTermsUrl={singleRouteCompanyTermsUrl}
                    returnRouteCompanyTermsUrl={returnRouteCompanyTermsUrl}
                    carrierName={carrierName}
                />
            ) : (
                <>
                    {singleCompanyId !== 445 ? (
                        <GbbTermsAndConditions/>
                    ) : (
                        <JadrolinijaTermsAndConditions/>
                    )}
                </>
            )}
        </TermWithCheckbox>
    );
}

export default TosAndPrivacyPolicy;