import React from 'react';
import RouteOtherDatesSuggestions from "@RouteSuggestions/components/RouteOtherDatesSuggestions";
import NoResults from "./NoResults";
import {SearchResultsRtkQueryResponseType} from "@SearchResults/api/types/SearchResultsRtkQueryResponse.type";
import {isEmpty} from "@Array/isEmpty";

type NoResultsContainerProps = {
    searchResultsResponse: SearchResultsRtkQueryResponseType
}

function NoResultsContainer({searchResultsResponse}: NoResultsContainerProps) {
    const {
        result: {
            params,
            transfersData,
            getByFerryData,
        },
        suggestionData,
        urls,
    } = searchResultsResponse

    return (
        <div className="no-results">
            {isEmpty(suggestionData?.dates) ? (
                <NoResults
                    urls={urls}
                    getByFerryData={getByFerryData}
                    params={params}
                    transfersData={transfersData}
                />
            ) : (
                <RouteOtherDatesSuggestions
                    params={params}
                    suggestionData={suggestionData}
                />
            )}
        </div>
    )
}

export default NoResultsContainer;