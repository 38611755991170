
import AncillaryType from "@CheckOrder/types/Ancillary.type";
import Row from "@Containers/components/Row";
import IconDiamond from "@Icons/FilledOutline/IconDiamond";
import React from "react";
import IconSMS from "@Icons/FilledOutline/IconSMS";

interface AncillaryDetailsProps {
    ancillaryData: AncillaryType
}

function Ancillary({ancillaryData}: AncillaryDetailsProps) {
    if (!ancillaryData.isEnabled) {
        return;
    }

    let icon = null;
    if (ancillaryData.id === 1) {
        icon = <IconDiamond className="ancillary-icon" width="20" height="20"/>;
    }

    if (ancillaryData.id === 2) {
        icon = <IconSMS className="ancillary-icon" width="20" height="20"/>;
    }
    return (
        <Row
            className="ancillary-pill"
            justify

        >
            <div className="ticket-ancillary-icon">
                {icon}
            </div>
            <div className="ticket-ancillary-name">
                {ancillaryData.name}
            </div>
        </Row>
    );
}

export default Ancillary