import React from 'react'
import Column from "@Containers/components/Column";
import StationDisplay from "@SearchResults/features/tours/features/itinerary/StationDisplay";
import Row from "@Containers/components/Row";

interface ItineraryRowProps {
    stationName: string,
    stationDescription?: string,
    circleColor?: string,
    className?: string,
    lineClassName?: string,
    children?: any
}

const ItineraryRow = ({stationName, stationDescription, circleColor, lineClassName, className, children}: ItineraryRowProps) => (
    <Row>
        <div className={className ?? "tour-itinerary-info-data"}>
            <Column alignEnd>
                <div>
                    {children}
                </div>
            </Column>
        </div>
        <StationDisplay
            name={stationName}
            description={stationDescription}
            circleColor={circleColor}
            lineClassName={lineClassName}
        />
    </Row>
)

export default ItineraryRow