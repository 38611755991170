import React, {ReactNode} from 'react';
import BaseBadgeProps from './GBBadge.types';

const GBBadge = (props: BaseBadgeProps) => {
    const {
        badgeContent,
        icon,
        appearance,
        shape,
    } = props;
    return (
        <span className={`gbBadge gbBadge--${appearance} gbBadge--${shape}`}>
            {icon}
            {badgeContent}
        </span>
    )
}

export default GBBadge;