import React from 'react'
import Time from "@DateTime/components/Time";
import {TourType} from "@SearchResults/features/tours/types/Tour.type";
import RouteWithAddedPropsAndPricesType from "@Route/types/RouteWithAddedPropsAndPrices.type";
import {useTranslation} from "@Translation/hooks/useTranslation";
import ItineraryRow from "@SearchResults/features/tours/features/itinerary/ItineraryRow";
import useTourStations from "@SearchResults/features/tours/hooks/useTourStations";

interface TourItineraryMobileProps {
    tour: TourType | RouteWithAddedPropsAndPricesType
}

const TourItineraryMobile = ({tour}: TourItineraryMobileProps) => {
    const {
        travelInfo: {
            departureStation: {
                name: departureStationName,
                departureTime,
            },
            destinationStation: {
                arrivalTime,
            },
            stations,
        },
    } = tour || {};

    const {
        firstStation,
        lastStation,
        stops,
    } = useTourStations({stations})

    const stopLabel = useTranslation("tour.stop")

    return (
        <div className="gb--route-details gb--tour-stations-container">
            <ItineraryRow
                stationName={departureStationName}
                stationDescription={firstStation?.stationDescription}
                circleColor="green"
                className="tour-itinerary-info-overlay"
                lineClassName="first"
            >
                <Time
                    time={departureTime}
                    className="gb--emphasize-default"
                />
            </ItineraryRow>
            {stops.map((stop, index) => (
                <ItineraryRow
                    stationName={stop.name}
                    stationDescription={stop?.stationDescription}
                    className="tour-itinerary-info-overlay"
                    key={stop.id}
                >
                            <span>
                                 {`${stopLabel} ${index + 1}`}
                            </span>
                </ItineraryRow>
            ))}
            <ItineraryRow
                stationName={departureStationName}
                stationDescription={lastStation?.stationDescription}
                circleColor="orange"
                className="tour-itinerary-info-overlay"
                lineClassName="last"
            >
                <Time
                    time={arrivalTime}
                    className="gb--emphasize-default"
                />
            </ItineraryRow>
        </div>
    )
}

export default TourItineraryMobile