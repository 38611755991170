import React from 'react'
import Row from "@Containers/components/Row";
import StationDisplay from "@SearchResults/features/tours/features/itinerary/StationDisplay";
import IconChevron from "@Icons/IconChevron";

interface StationsToggleProps {
    name: string,
    description: string,
    isExpanded: boolean
}

const StationsToggle = ({name, description, isExpanded}: StationsToggleProps) => (
    <Row gap className="tour-itinerary-row">
        <StationDisplay
            name={name}
            description={description}
        />
        <div>
            <IconChevron chevronOrientation={isExpanded ? "up" : "down"}/>
        </div>
    </Row>
)

export default StationsToggle