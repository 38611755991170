import useEventListener from "@Hooks/eventListeners/useEventListener";
import {useAppDispatch} from "@Hooks/dispatch/useAppDispatch";

/**
 * @deprecated
 * Resets loading indicator if page was loaded from bfcache (safari mobile only - GBW-915)
 */
export default function useBfCacheHandler() {
    const dispatch = useAppDispatch()

    function handleBfCacheLoading(event) {
        if (event.persisted) {
            // dispatch(setSearchResultsLoading(false))
        }
    }

    // eslint-disable-next-line no-restricted-globals
    useEventListener('pageshow', () => handleBfCacheLoading(event))
}