import {createBrowserRouter, RouterProvider} from "react-router-dom";
import React from "react";
import Homepage from "@Pages/homepage/components/Homepage";
import SearchResultsContainer from "@SearchResults/components/SearchResultsContainer";
import TabContentOutlet from "@RoutesList/components/TabContentOutlet";
import Header from "@Header/components/Header";
import AdditionalInfoOutlet from "@SearchResults/features/additionalInfo/components/AdditionalInfoOutlet";
import Form from "@Form/components/Form";
import CheckOrder from "@CheckOrder/components/CheckOrder";
import MyProfile from "@MyProfile/components/MyProfile";
import MyVouchersOutlet from "@MyVouchers/components/MyVouchersOutlet";
import MyTicketsOutlet from "@MyTickets/components/MyTicketsOutlet";
import TicketAdditionalInfo from "@MyTickets/components/TicketAdditionalInfo";
import VoucherAdditionalInfo from "@MyVouchers/components/VoucherAdditionalInfo";
import ReturnTabContentOutlet from "@RoutesList/components/ReturnTabContentOutlet";
import ErrorBoundary from "@Errors/components/ErrorBoundary";
import {routeIds} from "@Routing/constants/routeIds";
import MobileAppLandingPage from "@Pages/mobileApp/components/MobileAppLandingPage";

type RouterProps = {}
const {
    homepage,
    searchResults,
    staticPages,
    additionalInfo,
    checkOrder,
    login,
    returnRoutesList,
    returnAdditionalInfo,
    successfulPurchase,
    myProfile,
    tickets,
    vouchers,
    mobileApp,
} = routeIds

const routesWithHiddenForm = [
    'bus_front_for_companies',
    'meet_the_team',
]

const CheckOrderWithHeader = () => (
    <>
        <Header/>
        <CheckOrder/>
    </>
)

const FallbackRoute = () => (
    <>
        <Header/>
        <Homepage/>
    </>

)

const router = createBrowserRouter([
    {
        id: successfulPurchase,
        path: "successful-purchase",
        element: <Header/>,
        errorElement: <ErrorBoundary/>,
    },
    {
        path: "/:locale",
        element: <Header/>,
        errorElement: <ErrorBoundary/>,
        // loader: () => <FullScreenLoader/>,
        children: [
            {
                id: homepage,
                path: "",
                element: <Homepage/>,
                errorElement: <ErrorBoundary/>,
                // loader: teamLoader,
            },
            {
                id: staticPages,
                path: ":staticPage?/*",
                element: routesWithHiddenForm.includes(window?.currentRoute) ? null : <Form/>,
                errorElement: <ErrorBoundary/>,
            },
            {
                id: searchResults,
                path: ":transportTypeRoute/:fromSlug/:toSlug/:date/:returnDate?",
                element: (window?.currentRoute === 'admin_station_to_station_widget_permalink_preview' || window?.currentRoute === 'admin_port_to_port_widget_permalink_preview') ? <Form/> : <SearchResultsContainer/>,
                errorElement: <ErrorBoundary/>,
                children: [
                    {
                        path: ":tab?",
                        element: <TabContentOutlet/>,
                    },
                    {
                        id: additionalInfo,
                        path: ":tab/:routeId",
                        element: <AdditionalInfoOutlet/>,
                    },
                    {
                        id: returnRoutesList,
                        path: ":tab/:routeId/:returnTab",
                        element: <ReturnTabContentOutlet/>,
                    },
                    {
                        id: returnAdditionalInfo,
                        path: ":tab/:routeId/:returnTab/:returnRouteId",
                        element: <AdditionalInfoOutlet isReturn/>,
                    },
                ],
            },
            {
                id: myProfile,
                path: "my-profile",
                element: <MyProfile/>,
                errorElement: <ErrorBoundary/>,
                children: [
                    {
                        id: tickets,
                        path: "tickets",
                        element: <MyTicketsOutlet/>,
                        children: [
                            {
                                path: ":ticket",
                                element: <TicketAdditionalInfo/>,
                            },
                        ],
                    },
                    {
                        path: vouchers,
                        element: <MyVouchersOutlet/>,
                        children: [
                            {
                                path: ":voucher",
                                element: <VoucherAdditionalInfo/>,
                            },
                        ],
                    },
                ],
                // loader: teamLoader,
            },
            {
                id: mobileApp,
                path: "getby-app",
                element: <MobileAppLandingPage/>,
                errorElement: <ErrorBoundary/>,
            },
        ],
    },
    {
        id: checkOrder,
        path: "/check-order/:ticketCode",
        element: <CheckOrderWithHeader/>,
        errorElement: <ErrorBoundary/>,
    },
    {
        path: "*",
        element: <FallbackRoute/>,
        errorElement: <ErrorBoundary/>,
    },
]);

function Router(props: RouterProps) {
    return (
        <RouterProvider
            router={router}
        />
    );
}

export default Router;