import React from 'react';
import ToggleTag from "@Generic/ToggleTag";
import Translation from "@Translation/components/Translation";
import {useTranslation} from "@Translation/hooks/useTranslation";
import {useAppSelector} from "@Hooks/selector/useAppSelector";
import {useAppDispatch} from "@Hooks/dispatch/useAppDispatch";
import {setSortBy} from "@SearchResults/reducers/filterSlice";
import sortingOptions from "@SearchResults/features/sorting/constants/sortingOptions";

function Sorter() {
    const sortByLabel = useTranslation("sys.sort_by")
    const selectedSortKey = useAppSelector((state) => state?.filter.sortBy)
    const dispatch = useAppDispatch()
    const onSortingChange = (sortingMethod) => dispatch(setSortBy(sortingMethod))

    return (
        <div className="gb--sorter">
            {`${sortByLabel}: `}
            {sortingOptions.map((tag) => (
                <ToggleTag
                    key={tag.label}
                    onClick={onSortingChange}
                    value={tag.value}
                    label={<Translation translationKey={tag.label}/>}
                    active={tag.value === selectedSortKey}
                    inactiveClass="gb--emphasize-default"
                />
            ))}
        </div>
    )
}

export default Sorter;