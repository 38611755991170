import {useAppSelector} from "@Hooks/selector/useAppSelector";
import PassengerAdditionalFields from "@CheckOrder/features/TicketAdditionalInfo/components/PassengerAdditionalFields";
import React from "react";
import Grid from "@Containers/components/Grid";
import {
    TicketAdditionalInfoFieldsType
} from "@CheckOrder/features/TicketAdditionalInfo/types/TicketAdditionalInfoFields.type";

type AdditionalTicketInfoProps = {
    additionalTicketFields: TicketAdditionalInfoFieldsType[]
}

function AdditionalTicketInfo({additionalTicketFields}: AdditionalTicketInfoProps) {
    const {
        userConfirmed,
    } = useAppSelector((state) => state?.checkOrder)

    return (
        <div className="gb--card">
            <span className="gb--card-title">
                Transport details
            </span>
            <Grid>
                <PassengerAdditionalFields
                    updateAllPassengers
                    additionalInfoFields={additionalTicketFields}
                    userConfirmed={userConfirmed}
                    index={0}
                />
            </Grid>
        </div>
    );
}

export default AdditionalTicketInfo;