import usePrepareRoute from "@Route/hooks/usePrepareRoute";
import useActivateTour from "@SearchResults/features/tours/hooks/useActivateTour";
import TourWithAddedPropsAndPricesType from "@SearchResults/features/tours/types/TourWithAddedPropsAndPrices.type";
import ParamsType from "@SearchResults/types/params/Params.type";
import {useState} from "react";
import useClickTripEvent from "@Events/hooks/useClickTripEvent";

interface UseTourDisplayProps {
    tour: TourWithAddedPropsAndPricesType,
    params: ParamsType,
    isReturn?: boolean,
    isActive: boolean,
    setActive: (arg0: number | null) => any
}
export default function useTourDisplay(props: UseTourDisplayProps) {
    const [isOverlayActive, setIsOverlayActive] = useState(false)

    const {
        tour,
        isReturn,
        params,
        isActive,
        setActive,
    } = props

    const {
        routeId: tourId,
        routeExpandable,
    } = usePrepareRoute({
        route: tour,
        isCurrentRouteSingle: !isReturn,
        isReturn: params.isReturn,
        isActive,
    })

    const {
        activateTour,
        elRef,
    } = useActivateTour({
        tour,
        isCurrentRouteSingle: !isReturn,
        routeExpandable,
        params,
        isReturn,
    })

    const clickTripEvent = useClickTripEvent({route: tour, params})

    const closeOverlay = () => {
        setIsOverlayActive(false)
    }

    const openOverlay = () => {
        clickTripEvent()
        setIsOverlayActive(true)
    }

    return {
        isOverlayActive,
        openOverlay,
        closeOverlay,
        activateTour,
        elRef,
        tourId,
        routeExpandable,
    }
}