import React from 'react'
import useBuyTicket from "@Route/features/ticketPurchase/hooks/useBuyTicket";
import ParamsType from "@SearchResults/types/params/Params.type";
import {TourType} from "@SearchResults/features/tours/types/Tour.type";
import LoaderButton from "@Generic/Loaders/LoaderButton";
import IconChevron from "@Icons/IconChevron";

interface BuyButtonMobileProps {
    route: TourType,
    params: ParamsType
}

const BuyButtonMobile = ({route, params}: BuyButtonMobileProps) => {
    const {buyTicket, loading} = useBuyTicket(route, params)

    return (
        <button
            type="submit"
            style={loading ? {paddingTop: "7px", paddingBottom: "7px"} : {}}
            className="btn--blue gb--tour-btn-mobile"
            onClick={buyTicket}
        >
            {loading ? (
                <LoaderButton/>
            ) : (
                <IconChevron
                    chevronOrientation="right"
                    fill="#ffffff"
                />
            )}

        </button>
    )
}

export default BuyButtonMobile