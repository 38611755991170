import React from 'react'
import MobileOverlay from "@MobileOverlays/components/MobileOverlay";
import BackButton from "@Routing/components/BackButton";
import TourItineraryMobile from "@SearchResults/features/tours/features/itinerary/TourItineraryMobile";
import TourItineraryOverlayTitle from "@SearchResults/features/tours/features/itinerary/TourItineraryOverlayTitle";
import Row from "@Containers/components/Row";
import Column from "@Containers/components/Column";
import {TourType} from "@SearchResults/features/tours/types/Tour.type";

interface TourItineraryOverlayProps {
    tour: TourType,
    closeOverlay: () => void
}

const TourItineraryOverlay = ({tour, closeOverlay}: TourItineraryOverlayProps) => {
    const {
        tourInfo: {
            tourName,
        },
    } = tour

    return (
        <MobileOverlay
            title="Tour itinerary"
            isActionSheet
            wrapperClassName="tour-itinerary-overlay"
            slideInDirection="up"
            backClick={closeOverlay}
        >
            <Column center>
                <div className="mobile-tour-itinerary-line"/>
            </Column>
            <Row justify>
                <TourItineraryOverlayTitle tourName={tourName}/>
                <BackButton icon="times" backClick={closeOverlay}/>
            </Row>
            <TourItineraryMobile tour={tour}/>
        </MobileOverlay>
    )
}

export default TourItineraryOverlay