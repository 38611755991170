import ClickTripEventModel from "@Events/models/ClickTripEventModel";
import useSingleTrackingEvent from "./useSingleTrackingEvent";
import RouteWithAddedPropsAndPricesType from "@Route/types/RouteWithAddedPropsAndPrices.type";
import IntermodalRouteWithDetailsType from "@Intermodal/types/IntermodalRouteWithDetails.type";
import ParamsType from "@SearchResults/types/params/Params.type";
import TourWithAddedPropsAndPricesType from "@SearchResults/features/tours/types/TourWithAddedPropsAndPrices.type";

/**
 * Dispatches clickTripEvent
 */

interface UseClickTripEventAttributes {
    route: IntermodalRouteWithDetailsType | RouteWithAddedPropsAndPricesType | TourWithAddedPropsAndPricesType,
    params: ParamsType,
}

export default function useClickTripEvent({route, params}: UseClickTripEventAttributes) {
    return useSingleTrackingEvent("click_trip_details", new ClickTripEventModel(route, params))
}