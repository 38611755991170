import React from 'react';
import Card from "react-bootstrap/Card";
import useIsMobile from "@Hooks/selector/useIsMobile";
import TicketType from "@MyTicket/types/Ticket.type";
import TicketTypeSection from "@MyTicket/features/ticketDetails/components/TicketTypeSection";
import TicketActionsSection from "@MyTicket/features/ticketDetails/components/TicketActionsSection";
import TicketDataSection from "@MyTicket/features/ticketDetails/components/TicketDataSection";
import TicketPaymentSection from "@MyTicket/features/ticketDetails/components/TicketPaymentSection";
import getNumberOfPassengers from "@MyTicket/functions/getNumberOfPassengers";
import {createRouteTransferTypeArray} from "@MyTicket/functions/createRouteTransferTypeArray";
import {checkIsTourTicket} from "@MyTicket/functions/checkIsTourTicket";

interface TicketDetailsProps {
    ticket: TicketType,
}

const TicketDetails = ({ticket}: TicketDetailsProps) => {
    const {
        baseTickets,
        payment,
        status,
        singleRoute,
    } = ticket

    const {
        isReturn,
        isIntermodal,
        isCancelled,
        isApiPendingReservation,
        isApiReservationFailedToApprove,
    } = status

    const isMobile = useIsMobile()
    const isTour = checkIsTourTicket(singleRoute)

    return (
        <Card.Body className="card-expanded ticket-details gb--route-details">
            <TicketTypeSection
                ticketStatus={status}
                ancillariesData={ticket.ancillariesData}
                isTour={isTour}
            />
            {(!isApiPendingReservation && !isApiReservationFailedToApprove) && (
                <TicketActionsSection
                    ticket={ticket}
                    status={status}
                />
            )}
            <TicketDataSection
                ticket={ticket}
            />
            {!isMobile && (
                <hr/>
            )}
            <TicketPaymentSection
                isReturn={isReturn}
                isIntermodal={isIntermodal}
                isCancelled={isCancelled}
                isTour={isTour}
                numberOfPassengers={getNumberOfPassengers(ticket)}
                routeTransferTypeArray={createRouteTransferTypeArray(baseTickets)}
                payment={payment}
            />
        </Card.Body>
    )
}

export default TicketDetails;