import {useAppSelector} from "@Hooks/selector/useAppSelector";

export default function useActiveFiltersCount(): number {
    const {
        filterDuration,
        filterPrice,
        filterRating,
        filterRouteDepartureTime,
        filterRouteArrivalTime,
        filterRouteChange,
    } = useAppSelector((state) => state?.filter)

    const allFilters = {
        filterDuration,
        filterPrice,
        filterRating,
        filterRouteDepartureTime,
        filterRouteArrivalTime,
        filterRouteChange,
    }

    return allFilters ? Object.values(allFilters).filter((value) => value !== null).length : 0
}