import React from 'react';
import {useAppSelector} from "@Hooks/selector/useAppSelector";
import IconDiamond from "@Icons/FilledOutline/IconDiamond";
import {setIsBuyingPremiumSupport, setIsBuyingSMSNotification} from "@CheckOrder/reducers/checkOrderSlice";
import IconSMS from "@Icons/FilledOutline/IconSMS";
import AncillaryCard from "@CheckOrder/features/ancillaries/components/AncillaryCard";
import {useTranslation} from "@Translation/hooks/useTranslation";

const AncillariesSection = () => {
    const {isBuyingPremiumSupport, isBuyingSMSNotification} = useAppSelector((state) => state?.checkOrder);
    const {isCompanyStore} = useAppSelector((state) => state?.page.companyStore);
    const {isIntermodal} = useAppSelector((state) => state?.checkOrder.ticket)
    const {
        smsAncillaryEnabled,
        premiumSupportAncillaryEnabled,
    } = useAppSelector((state) => state.page.enabledClientsideFeatures)

    const enhanceYourJourney = useTranslation("sys.enhance_your_journey");
    if (!isIntermodal && !isCompanyStore) {
        return (
            <div className="gb--card">
                <span className="gb--card-title">{enhanceYourJourney}</span>
                {premiumSupportAncillaryEnabled && (
                    <>
                        <AncillaryCard
                            ancillaryName="premiumSupport"
                            icon={<IconDiamond className="ancillary-icon" width="20" height="20"/>}
                            cardTitleKey="sys.premium_support"
                            cardTextKey="sys.skip_the_line"
                            ancillaryCallback={setIsBuyingPremiumSupport}
                            ancillaryValue={isBuyingPremiumSupport}
                        />
                        {smsAncillaryEnabled && (
                            <hr/>
                        )}
                    </>
                )}
                {smsAncillaryEnabled && (
                    <AncillaryCard
                        ancillaryName="smsNotification"
                        icon={<IconSMS className="ancillary-icon" width="20" height="20"/>}
                        cardTitleKey="sys.sms_notification"
                        cardTextKey="sys.stay_in_the_loop"
                        ancillaryCallback={setIsBuyingSMSNotification}
                        ancillaryValue={isBuyingSMSNotification}
                    />
                )}
            </div>
        );
    }
};

export default AncillariesSection
