import React from 'react';
import RouteOtherDatesSuggestions from "@RouteSuggestions/components/RouteOtherDatesSuggestions";
import RoutesList from "@RoutesList/components/RoutesList";
import useHasSearchResults from "@SearchResults/hooks/useHasSearchResults";
import {useAppSelector} from "@Hooks/selector/useAppSelector";
import useShowNoRoutesNotification from "@SearchResults/features/notifications/hooks/useShowNoRoutesNotification";
import useFilterBySaleBlock from "@Tabs/hooks/useFilterBySaleBlock";
import useShowMobileAppPromoNotice from "../../notifications/hooks/useShowMobileAppPromoNotice";
import {PreparedTabContentDataType} from "@Tabs/types/PreparedTabContentData.type";
import RouteNotifications from "@SearchResults/components/Notifications/RouteNotifications";
import usePassengers from "@Hooks/selector/usePassengers";
import useIsMobile from "@Hooks/selector/useIsMobile";
import useIsCompanyStore from "@Hooks/selector/useIsCompanyStore";
import useCurrency from "@Currency/hooks/useCurrency";
import useSingleSelectedRoute from "@Route/hooks/useSingleSelectedRoute";
import useAdditionalInfo from "@Route/hooks/useAdditionalInfo";
import useCompanyMainTransportType from "@Hooks/selector/useCompanyMainTransportType";
import SuggestedRoutesList from "@RouteSuggestions/components/SuggestedRoutesList";
import RoutesProgressBar from "@RoutesList/components/RoutesProgressBar";
import RoutePlaceholder from "@SearchResults/components/Placeholders/RoutePlaceholder";
import {useTranslation} from "@Translation/hooks/useTranslation";
import TopBanners from "@SearchResults/features/banners/components/TopBanners";
import BottomBanners from "@SearchResults/features/banners/components/BottomBanners";
import ToursList from "@SearchResults/features/tours/components/ToursList";
import useLocale from "@Hooks/selector/useLocale";
import useFilterToursByPagePosition from "@SearchResults/features/tours/hooks/useFilterToursByPagePosition";

type TabContentProps = {
    preparedTabContentData: PreparedTabContentDataType,
}

/**
 * Show tab content.
 */
function TabContent({preparedTabContentData}: TabContentProps) {
    const {
        activeTab,
        singleRoutes,
        suggestedRoutes,
        suggestionRoutesEnabled,
        tourRoutes,
        result: {
            intermodalRoutes,
            params,
            passengerGroups,
            routeIcons,
            companies,
            getByFerryData,
            resolvedApiCount,
            transfersData,
            totalApiCount,
            resolvedSuggestionsCount,
            totalSuggestionsCount,
        },
        tabsToShow,
    } = preparedTabContentData

    const {
        isReturn,
        showUpcomingDeparturesHeader,
        showPastDeparturesHeader,
    } = params

    const currency = useCurrency()
    const passengers = usePassengers()
    const isMobile = useIsMobile()
    const isCompanyStore = useIsCompanyStore()
    const singleSelectedRoute = useSingleSelectedRoute()
    const additionalInfoActive = useAdditionalInfo()
    const {filteredRoutesCount} = useAppSelector((state) => state.filter)
    const companyMainTransportType = useCompanyMainTransportType()
    const apiRoutesLoading = resolvedApiCount < totalApiCount
    const suggestionRoutesLoading = resolvedSuggestionsCount < totalSuggestionsCount
    const searchResultsUpcomingDepartures = useTranslation("search_results.upcoming_departures")
    const SearchResultsPastDepartures = useTranslation("search_results.past_departures")
    const locale = useLocale()
    const noRoutesNotification = useShowNoRoutesNotification({
        singleRoutes,
        activeTab,
        apiRoutesLoading,
        companyMainTransportType,
        suggestionRoutesLoading,
    })

    const {
        pastDepartures,
        upcomingDepartures,
    } = useFilterBySaleBlock(singleRoutes, params)

    const {toursTop, toursBottom} = useFilterToursByPagePosition(tourRoutes)
    // const showTripDelayNotification = useShowTripDelayNotification()
    // const onlyFootPassengersNotification = useShowOnlyFootPassengersNotification()
    const hasSearchResults = useHasSearchResults()
    const showMobileAppPromo = useShowMobileAppPromoNotice(params)
    const hasActiveRoutes = !!upcomingDepartures.length || !!intermodalRoutes.length || !!suggestedRoutes.length
    const displayUpcomingDepartures = ((isMobile && isReturn && !singleSelectedRoute) || (isMobile && !isReturn))
        || !isMobile

    let currentRoutes = (displayUpcomingDepartures && (upcomingDepartures.length > 0 || intermodalRoutes.length > 0)) ? upcomingDepartures : []
    currentRoutes = (pastDepartures.length > 0 && showPastDeparturesHeader) ? pastDepartures : currentRoutes

    return (
        <div className="search-results_main-content">
             {!hasActiveRoutes && tourRoutes.length !== 0 && !isReturn && (
                <ToursList
                    tours={tourRoutes}
                    params={params}
                    singleSelectedRoute={singleSelectedRoute}
                    companies={companies}
                    passengerGroups={passengerGroups}
                    routes={currentRoutes}
                />
             )}
            {!additionalInfoActive && (
                <RouteNotifications
                    companies={companies}
                    params={params}
                    noRoutesNotification={noRoutesNotification}
                    allRoutesFilteredNotification={!apiRoutesLoading && filteredRoutesCount === 0}
                    onlyFootPassengersNotification={false}
                    activeTab={activeTab}
                    tabsToShow={tabsToShow}
                    hasActiveRoutes={hasActiveRoutes}
                    mobileAppPromoNotification={showMobileAppPromo}
                />
            )}

            <TopBanners
                numberOfResults={singleRoutes.length}
                hasActiveRoutes={hasActiveRoutes}
                transfersData={transfersData}
                params={params}
                getByFerryData={getByFerryData}
                activeTab={activeTab}
            />

             {hasActiveRoutes && toursTop.length !== 0 && !isReturn && (
                <ToursList
                    tours={tourRoutes}
                    params={params}
                    singleSelectedRoute={singleSelectedRoute}
                    companies={companies}
                    passengerGroups={passengerGroups}
                    routes={currentRoutes}
                    position="top"
                />
             )}

            {displayUpcomingDepartures && (upcomingDepartures.length > 0 || intermodalRoutes.length > 0) && (
                <RoutesList
                    singleSelectedRoute={singleSelectedRoute}
                    intermodalRoutes={intermodalRoutes}
                    companies={companies}
                    routeIcons={routeIcons}
                    passengerGroups={passengerGroups}
                    routes={upcomingDepartures}
                    params={params}
                    routesTitle={showUpcomingDeparturesHeader ? searchResultsUpcomingDepartures : null}
                    tours={tourRoutes}
                />
            )}

            {pastDepartures.length > 0 && showPastDeparturesHeader && (
                <RoutesList
                    singleSelectedRoute={singleSelectedRoute}
                    intermodalRoutes={intermodalRoutes}
                    companies={companies}
                    routeIcons={routeIcons}
                    passengerGroups={passengerGroups}
                    isPastDeparture
                    routes={pastDepartures}
                    params={params}
                    routesTitle={SearchResultsPastDepartures}
                    tours={tourRoutes}
                />
            )}

            {suggestionRoutesEnabled && (
                <SuggestedRoutesList
                    resolvedSuggestionsCount={resolvedSuggestionsCount}
                    totalSuggestionsCount={totalSuggestionsCount}
                    suggestedRoutes={suggestedRoutes}
                    companies={companies}
                    routeIcons={routeIcons}
                    passengerGroups={passengerGroups}
                    params={params}
                />
            )}
             {hasActiveRoutes && toursBottom.length !== 0 && !isReturn && (
                <ToursList
                    tours={tourRoutes}
                    position="bottom"
                    params={params}
                    singleSelectedRoute={singleSelectedRoute}
                    companies={companies}
                    passengerGroups={passengerGroups}
                    routes={currentRoutes}
                />
             )}
            {apiRoutesLoading && (
                <RoutePlaceholder/>
            )}
            {suggestionRoutesLoading && suggestionRoutesEnabled && (
                <RoutePlaceholder/>
            )}
            <RouteOtherDatesSuggestions
                params={params}
                suggestionData={preparedTabContentData.suggestionData}
            />
            <BottomBanners
                numberOfResults={singleRoutes.length}
                hasActiveRoutes={hasActiveRoutes}
                transfersData={transfersData}
                getByFerryData={getByFerryData}
                params={params}
                apiRoutesLoading={apiRoutesLoading}
                suggestionRoutesLoading={suggestionRoutesLoading}
                isCompanyStore={isCompanyStore}
                passengers={passengers}
                currency={currency}
            />
        </div>
    );
}

export default TabContent
