import React from 'react';
import SidebarSectionContainerItem from "@Sidebar/components/Sections/SidebarSectionContainerItem";
import Translation from "@Translation/components/Translation";
import PriceFilter from "./PriceFilter";
import RatingFilter from "./RatingFilter";
import DurationFilter from "./DurationFilter";
import DepartAfterFilter from "./DepartAfterFilter";
import ArriveBeforeFilter from "./ArriveBeforeFilter";
import ParamsType from "@SearchResults/types/params/Params.type";

type FiltersProps = {
    params: ParamsType,
}
function Filters({params}: FiltersProps) {
    return (
        <>
            <SidebarSectionContainerItem>
            <span>
                <Translation translationKey="sys.price" styleText="upperCase"/>
            </span>
                <PriceFilter/>
            </SidebarSectionContainerItem>

            <SidebarSectionContainerItem>
            <span>
                <Translation translationKey="search_results.filters.duration"/>
            </span>
                <DurationFilter/>
                <DepartAfterFilter
                    params={params}
                />
                <ArriveBeforeFilter
                    params={params}
                />
            </SidebarSectionContainerItem>

            <SidebarSectionContainerItem>
            <span>
                <Translation translationKey="search_results.filters.carrier_ratings"/>
            </span>
                <RatingFilter/>
            </SidebarSectionContainerItem>
        </>
    );
}

export default Filters;