import React, {ReactNode} from 'react'

interface ImageWithOverlayProps {
    imageUrl: string,
    customOverlayClass?: string,
    imgClass?: string,
    containerClass?: string,
    children?: ReactNode
}

// display image with child components absolutely positioned above it
const ImageWithOverlay = ({imageUrl, imgClass = "", customOverlayClass = "", containerClass = "", children}: ImageWithOverlayProps) => (
    <div className={`img-with-overlay ${containerClass}`}>
        <img
            src={imageUrl}
            alt="image with overlay"
            className={imgClass}
        />
        {children && (
            <div className={`img-overlay ${customOverlayClass} `}>
                {children}
            </div>
        )}
    </div>
)

export default ImageWithOverlay