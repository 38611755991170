import React from 'react';
import Row from "@Containers/components/Row";
import IconInfoCircle from "@Icons/FilledOutline/IconInfoCircle";
import IconChevron from "@Icons/IconChevron";
import {useTranslation} from "@Translation/hooks/useTranslation";

interface ReadMoreToggleProps {
    isActive: boolean,
    routeExpandable: boolean,
    onClick?: () => void
}

const ReadMoreToggle = ({isActive, routeExpandable, onClick = () => {}}: ReadMoreToggleProps) => {
    const readMoreAboutItinerary = useTranslation("tour.read_more_itinerary")

    return (
        <Row center gap className="tour-toggle" onClick={onClick}>
            <IconInfoCircle backgroundFill="#4A79E2" fill="#ffffff"/>
            <span className="gb--emphasize-info">
                {readMoreAboutItinerary}
            </span>
            <IconChevron
                chevronOrientation={isActive && routeExpandable ? "up" : "down"}
            />
        </Row>
    )
}

export default ReadMoreToggle