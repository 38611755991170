import React from "react";
import TicketRouteType from "@MyTicket/types/TicketRoute.type";
import TicketNotifications from "@MyTicket/features/ticketDetails/components/TicketNotifications";
import TicketTravelDetails from "@MyTicket/features/ticketDetails/components/TicketTravelDetails";
import {checkIsTourTicket} from "@MyTicket/functions/checkIsTourTicket";
import TourIncludesNotification from "@SearchResults/features/tours/components/TourIncludesNotification";
import TourDescription from "@SearchResults/features/tours/components/TourDescription";

interface TicketDataProps {
    isCanceled: boolean,
    isExchangedForVoucher: boolean,
    isExpired: boolean,
    isMTicket: boolean,
    id: string,
    route: TicketRouteType,
}

function TicketData(props: TicketDataProps) {
    const {
        isCanceled,
        isExchangedForVoucher,
        isExpired,
        isMTicket,
        id,
        route,
    } = props

    const {
        tourInfoModel,
    } = route

    const {
        tourIncludes,
        tourDescription,
    } = tourInfoModel || {}

    const isTour = checkIsTourTicket(route)

    return (
        <>
            <TicketNotifications
                isCanceled={isCanceled}
                isExchangedForVoucher={isExchangedForVoucher}
                isExpired={isExpired}
                isMTicket={isMTicket}
            />
            {isTour && tourIncludes && (
                <TourIncludesNotification
                    className="ticket-notification"
                    content={tourIncludes}
                />
            )}
            <TicketTravelDetails
                id={id}
                route={route}
            />
            {isTour && tourDescription && (
                <TourDescription
                    description={tourDescription}
                    className="gb--tour-description"
                />
            )}

        </>
    );
}

export default TicketData;