import React from 'react'
import RoutePriceBreakdownSection from "@Route/components/Sections/RoutePriceBreakdownSection";
import CompanyPopover from "@Route/features/company/components/CompanyPopover";
import TourItinerary from "@SearchResults/features/tours/features/itinerary/TourItinerary";
import Row from "@Containers/components/Row";
import RouteTotalPriceSection from "@Route/components/Sections/RouteTotalPriceSection";
import MTicketLabel from "@Route/components/MTicket/MTicketLabel";
import TourIncludesNotification from "@SearchResults/features/tours/components/TourIncludesNotification";
import TourDescription from "@SearchResults/features/tours/components/TourDescription";
import ParamsType from "@SearchResults/types/params/Params.type";
import TourWithAddedPropsAndPricesType from "@SearchResults/features/tours/types/TourWithAddedPropsAndPrices.type";
import {useTranslation} from "@Translation/hooks/useTranslation";

interface TourExpandedContainerProps {
    tour: TourWithAddedPropsAndPricesType,
    params: ParamsType,
    isReturn?: boolean,
}

const TourExpandedContainer = ({tour, params, isReturn}: TourExpandedContainerProps) => {
    const bookingDetailsLabel = useTranslation("tour.booking_details")

    const {
        tourInfo: {
            tourDescription,
            tourIncludes,
        },
        company,
    } = tour || {}

    const showButton = (params.isReturn && isReturn) || !params.isReturn

    return (
        <div className="gb--tour-expanded">
            <Row
                justify
                className="route-notes-and-ticket-type"
            >
                <small className="gb--emphasize-inactive">
                    {bookingDetailsLabel}
                </small>
                <MTicketLabel/>
            </Row>
            {tourIncludes && (
                <TourIncludesNotification content={tourIncludes}/>
            )}
            <Row
                gap
                justify
                className="tour-details route-expanded-details-section"
            >
                <TourItinerary
                    tour={tour}
                    className="gb--tour-stations-container-expanded"
                />
                <CompanyPopover
                    company={company}
                    route={tour}
                />
                <div className="gb--tour-price-breakdown">
                    <RoutePriceBreakdownSection
                        route={tour}
                    />
                </div>
            </Row>
            {tourDescription && (
                <TourDescription description={tourDescription}/>
            )}

            <hr/>
            <RouteTotalPriceSection
                params={params}
                showButton={showButton}
                route={tour}
                isReturn={isReturn}
            />
        </div>

    )
}

export default TourExpandedContainer