import React, {MutableRefObject} from 'react'
import MobileContentContainer from "@Route/features/mobile/components/MobileContentContainer";
import ImageWithOverlay from "@Generic/ImageWithOverlay";
import Row from "@Containers/components/Row";
import GBBadge from "@/components/UI/BadgeUI/GBBadge";
import {displayTicketType} from "@/functions/overrides/displayTicketType";
import RouteNotesAndTicketType from "@Route/components/Container/RouteNotesAndTicketType";
import TourItinerary from "@SearchResults/features/tours/features/itinerary/TourItinerary";

import {TourType} from "@SearchResults/features/tours/types/Tour.type";
import RouteWithAddedPropsAndPricesType from "@Route/types/RouteWithAddedPropsAndPrices.type";
import {useTranslation} from "@Translation/hooks/useTranslation";

interface TourInfoProps {
    title: string,
    tour: TourType | RouteWithAddedPropsAndPricesType,
    scrollRef: MutableRefObject<any>,
}

const TourInfo = ({title, tour, scrollRef}: TourInfoProps) => {
    const {
        tourInfo,
        status: {
            hasMTicket,
            isReturn,
        },
        travelInfo: {
            notes,
        },
    } = tour

    const {
        tourName,
        isDayTrip,
        isWaterActivity,
        tourImageUrl,
    } = tourInfo || {}

    const {
        water_activity: waterActivity,
        daytour,
    } = useTranslation("tour.tags")

    return (
        <MobileContentContainer
            title={title}
            scrollRef={scrollRef}
        >
            <ImageWithOverlay
                imageUrl={tourImageUrl}
                imgClass="gb--tour-image-custom"
                customOverlayClass="img-overlay-shift-right gb--tour-image-overlay"
                containerClass="gb--row"
            >
                <Row
                    gap
                    className="tour-labels-container"
                >
                    {isWaterActivity && (
                        <GBBadge
                            badgeContent={waterActivity}
                            appearance="info"
                            shape="rectangle"
                        />
                    )}
                    {isDayTrip && (
                        <GBBadge
                            badgeContent={daytour}
                            appearance="primary"
                            shape="rectangle"
                        />
                    )}
                </Row>
            </ImageWithOverlay>
            <div className="gb--route-details">
                <p className="gb--emphasize gb--emphasize-default">
                    {tourName}
                </p>
                <RouteNotesAndTicketType
                    displayTicketPrintType={displayTicketType(tour)}
                    displayTicketType={false}
                    hasMTicket={hasMTicket}
                    notes={notes}
                    isCurrentRouteSingle={isReturn}
                />
                <TourItinerary tour={tour}/>
            </div>
        </MobileContentContainer>
    )
}

export default TourInfo