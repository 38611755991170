import React, {useLayoutEffect} from "react"
import DatePicker from "react-datepicker";
import {isValid, parseISO} from "date-fns";
import IconCalendar from "@Icons/IconCalendar";
import Translation from "@Translation/components/Translation";
import {useTranslation} from "@Translation/hooks/useTranslation";
import setLocale from "@DateTime/functions/setLocale";
import {getTodayServerDate} from "@DateTime/functions/getTodayServerDate";
import parseDate from "@DateTime/functions/parseDate";
import FadingError from "@Errors/components/FadingError";
import useSetDate from "../../hooks/useSetDate";
import useIsMobile from "@Hooks/selector/useIsMobile";
import useForm from "../../hooks/useForm";
import useActivateCalendar from "../../hooks/useActivateCalendar";
import useSearchParamsExtractedFromRoute from "@SearchResults/hooks/useSearchParamsExtractedFromRoute";
import {useNavigationType} from "react-router"
import permalinkTypeId from "@/constants/PermalinkTypeId";
import formatDate from "@DateTime/functions/formatDate";
import {useAppSelector} from "@Hooks/selector/useAppSelector";
import {setDate} from "@Form/reducers/formSlice";
import {useAppDispatch} from "@Hooks/dispatch/useAppDispatch";

function DatePickerInputFields() {
    const {returnDate} = useForm('urlParams')
    let {date} = useForm('urlParams')
    const formErrors = useForm('formErrors')
    const returnCalendarActive = useForm("returnCalendarActive")
    const activateSingleCalendar = useActivateCalendar("single")
    const activateReturnCalendar = useActivateCalendar("return")
    const dateLocale = setLocale()
    const isMobile = useIsMobile()
    const changeSingle = useSetDate()
    const changeReturn = useSetDate('return')
    const dateSelectLabel = useTranslation("form.date_select")
    const addReturnLabel = useTranslation("form.optional")
    const today = getTodayServerDate()
    const dateFormat = "yyyy-MM-dd"
    const className = `search-datepicker__outbound gb--input-standalone ${returnDate ? 'search-datepicker--animation' : ''}`
    const displayedDate = (date) => (isValid(parseISO(date)) ? parseDate(date) : null)
    const navType = useNavigationType();
    const {
        date: routeProvidedDate,
        returnDate: routeProvidedReturnDate,
    } = useSearchParamsExtractedFromRoute()
    const {mobileStsSearchDatePrefill} = useAppSelector((state) => (state?.page.experimentsJson));
    const dispatch = useAppDispatch();
    // //
    useLayoutEffect(() => {
        if (navType === "POP" && !isMobile) {
            if (routeProvidedDate !== date) {
                changeSingle(displayedDate(routeProvidedDate))
            }
            if (routeProvidedReturnDate !== returnDate) {
                changeReturn(displayedDate(routeProvidedReturnDate))
            }
        }
    }, [routeProvidedDate, routeProvidedReturnDate]);

    const handleReturnCalendarClick = (e) => {
        if (e.target.className !== 'react-datepicker__close-icon') {
            activateReturnCalendar()
        }
    }


    const Label = ({datepickerLabel}) => (
        <span
            className="search__select--label gb--emphasize gb--emphasize-default"
        >
            <Translation translationKey={datepickerLabel}/>
        </span>
    )

    const popper = {
        flip: {
            behavior: ["bottom"],
        },
        preventOverflow: {
            enabled: false,
        },
        hide: {
            enabled: false,
        },
    }

    if (
        mobileStsSearchDatePrefill
        && isMobile
        && !date
        && (window?.permalinkTypeId === permalinkTypeId.portToPort || window?.permalinkTypeId === permalinkTypeId.stationToStation)
    ) {
        const tomorrow = new Date();
        tomorrow.setDate(tomorrow.getDate() + 1);
        date = formatDate(tomorrow, dateFormat);
        dispatch(setDate(date));
    }

    return (
        <div className="search__select search__select--datepicker gb--row gb--row-middle">
            <div
                className="search__select--datepicker-input"
                onClick={activateSingleCalendar}
            >
                <IconCalendar className="search__select--icon"/>
                <FadingError error={!formErrors?.dateValid}/>
                <DatePicker
                    locale={dateLocale}
                    selected={displayedDate(date)}
                    onChange={changeSingle}
                    placeholderText={dateSelectLabel}
                    className={className}
                    calendarClassName="react-search-datepicker"
                    minDate={today}
                    dateFormat={dateFormat}
                    popperPlacement="bottom"
                    popperModifiers={popper}
                    closeOnScroll
                />
                <Label datepickerLabel="form.departing_on"/>
            </div>

            <div
                className="search__select--datepicker-input"
                onClick={(e) => handleReturnCalendarClick(e)}
            >

                <IconCalendar className="search__select--icon"/>
                <DatePicker
                    locale={dateLocale}
                    selected={displayedDate(returnDate)}
                    onChange={changeReturn}
                    placeholderText={!isMobile && returnCalendarActive ? dateSelectLabel : addReturnLabel}
                    className={className}
                    calendarClassName="react-search-datepicker"
                    minDate={date ? parseDate(date) : today}
                    dateFormat={dateFormat}
                    isClearable
                    popperPlacement="bottom"
                    popperModifiers={popper}
                    closeOnScroll
                />
                <Label datepickerLabel="form.returning_on"/>
            </div>

        </div>
    );
}

export default DatePickerInputFields;