import React from "react";
import {isNotEmpty} from "@Array/isNotEmpty";
import SuggestedDates from "@RouteSuggestions/components/SuggestedDates";
import ParamsType from "@SearchResults/types/params/Params.type";
import SuggestionsDataType from "@SearchResults/types/suggestions/SuggestionsData.type";

interface RouteSuggestionsProps {
    suggestionData: SuggestionsDataType,
    params: ParamsType,
}

function RouteOtherDatesSuggestions({suggestionData, params}: RouteSuggestionsProps) {
    if (suggestionData && isNotEmpty(suggestionData?.dates)) {
        return (
            <SuggestedDates
                dates={suggestionData.dates}
                isReturn={params.isReturn}
            />
        )
    }

    return null
}

export default RouteOtherDatesSuggestions
