import React, {useEffect} from 'react';
import useCheckUser from "@User/hooks/useCheckUser";
import Translation from "@Translation/components/Translation";
import {clearUserLoginMethods} from "@MyProfile/reducers/userProfileSlice";
import validateEmail from "@User/functions/validateEmail";
import {useAppDispatch} from "@Hooks/dispatch/useAppDispatch";
import {useAppSelector} from "@Hooks/selector/useAppSelector";
import {checkEmailValidation, setEmail} from "@CheckOrder/reducers/checkOrderSlice";
import CheckOrderInputContainer from "../CheckOrderInputContainer";
import EmailInput from "@Features/email/components/EmailInput";

function EmailInputField() {
    const dispatch = useAppDispatch()
    const checkUser = useCheckUser()
    const {email, userConfirmed, emailValidated} = useAppSelector((state) => state?.checkOrder)
    const user = useAppSelector((state) => state?.userProfile.user)
    const readOnly = user && user.email;

    const handleEmailCheck = async () => {
        if (emailValidated) {
            await checkUser()
        } else {
            dispatch(clearUserLoginMethods())
        }
    }

    const dispatchEmail = (value) => {
        dispatch(setEmail(value))
    }

    useEffect(() => {
        if (!user?.email) {
            handleEmailCheck()
        }
    }, [email, emailValidated])

    const handleEmailInput = async ({target: {value}}) => {
        dispatch(setEmail(value))
        dispatch(checkEmailValidation(validateEmail(email)))
    }

    return (
        <CheckOrderInputContainer label="Email">
            <small>
                <Translation translationKey="check_order.buyer_info_section.email_note"/>
            </small>
            <EmailInput
                emailValue={email}
                handleEmailInput={handleEmailInput}
                emailValidated={emailValidated}
                setEmail={dispatchEmail}
                readOnly={readOnly}
                userConfirmed={userConfirmed}
            />
        </CheckOrderInputContainer>
    );
}

export default EmailInputField;