import React from 'react'
import ItineraryRow from "@SearchResults/features/tours/features/itinerary/ItineraryRow";
import StationWithDateTimesType from "@SearchResults/types/station/StationWithDateTimes.type";

interface ItineraryStopsProps {
    stations: StationWithDateTimesType[]
}

const ItineraryStops = ({stations}: ItineraryStopsProps) => (
    <>
        {stations.map((station, index) => (
            <ItineraryRow
                key={station.id}
                stationName={station.name}
                stationDescription={station.stationDescription}
            />
        ))}
    </>
)

export default ItineraryStops