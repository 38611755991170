import React from 'react';
import IconSlimArrowRight from "@Icons/IconSlimArrowRight";
import useIsMobile from "@Hooks/selector/useIsMobile";
import IconRoundArrow from "@Icons/IconRoundArrow";
import {useTranslation} from "@Translation/hooks/useTranslation";
import TicketRouteType from "@MyTicket/types/TicketRoute.type";
import TicketTripData from "@MyTicket/components/TicketTripData";

interface TicketTripDataInfoProps {
    isReturn: boolean,
    isIntermodal: boolean,
    outboundRoute?: TicketRouteType,
    returnDate?: string,
    baseTickets?: TicketRouteType[],
    isTour?: boolean
}

function TicketTripDataInfo(props: TicketTripDataInfoProps) {
    const {
        isReturn,
        isIntermodal,
        outboundRoute,
        returnDate,
        baseTickets,
        isTour,
    } = props

    const {
        departureStation,
        destinationStation,
        tourInfoModel,
    } = outboundRoute || {}

    const {
        departureDateTime,
    } = departureStation || {}

    const {
        tourName,
    } = tourInfoModel || {}

    const isMobile = useIsMobile()

    const routeIcon = (isReturn = false) => (
        isReturn ? <IconRoundArrow width="20" fill="#9b9b9b"/>
            : <IconSlimArrowRight width="20" height="10" fill="#9b9b9b"/>
    )

    const {
        outbound: outboundLabel,
        return: returnLabel,
    } = useTranslation('sys')

    if (isMobile) {
        return (
            <>
                <TicketTripData
                    departureStationName={isIntermodal ? baseTickets[0]?.departureStation.name : departureStation?.name}
                    destinationStationName={isIntermodal ? baseTickets[baseTickets.length - 1]?.destinationStation.name : destinationStation?.name}
                    routeIcon={routeIcon()}
                    departureDate={isIntermodal ? baseTickets[0]?.departureStation.departureDateTime : departureDateTime}
                    className="outbound-trip-data-infos"
                    directionLabel={isReturn && outboundLabel}
                    isTour={isTour}
                    tourName={tourName}
                />
                {isReturn && (
                    <TicketTripData
                        departureStationName={destinationStation?.name}
                        destinationStationName={departureStation?.name}
                        routeIcon={routeIcon()}
                        departureDate={returnDate}
                        className="return-trip-data-info"
                        directionLabel={returnLabel}
                    />
                )}
            </>
        )
    }

    return (
        <TicketTripData
            departureStationName={isIntermodal ? baseTickets[0]?.departureStation.name : departureStation?.name}
            destinationStationName={isIntermodal ? baseTickets[baseTickets.length - 1]?.destinationStation.name : destinationStation?.name}
            routeIcon={routeIcon(isReturn)}
            departureDate={isIntermodal ? baseTickets[0]?.departureStation.departureDateTime : departureDateTime}
            returnDate={returnDate}
            isTour={isTour}
            tourName={tourName}
        />
    );
}

export default TicketTripDataInfo;