import React from 'react';
import IconHome from "@Icons/IconHome";
import IconBusTab from "@Icons/IconBusTab";
import IconPen from "@Icons/IconPen";
import IconStationLocator from "@Icons/IconStationLocator";
import IconComments from "@Icons/IconComments";
import IconLocationArrow from "@Icons/IconLocationArrow";
import IconInfo from "@Icons/IconInfo";
import IconPercent from "@Icons/IconPercent";
import {DisplayedPagesType} from "@User/types/DisplayedPages.type";
import IconTourPins from "@Icons/IconTourPins";

interface MobileMenuIconsProps {
    tag: DisplayedPagesType,
}

function MobileMenuIcons({tag}: MobileMenuIconsProps) {
    const components = {
        homePage: IconHome,
        rentBus: IconBusTab,
        contact: IconPen,
        faq: IconInfo,
        specialOffer: IconPercent,
        stationLocator: IconStationLocator,
        blog: IconComments,
        availableRoutes: IconLocationArrow,
        tours: IconTourPins,
    };

    const IconMenu = components[tag || ''];

    return (
        <IconMenu
            width="18"
            height="18"
            fill="#9b9b9b"
            style={{
                verticalAlign: 'text-top',
                marginRight: '1rem',
            }}
        />
    );
}

export default MobileMenuIcons;
