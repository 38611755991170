import React from 'react'

interface TourDescriptionItemProps {
    data: {
        title: string,
        list: string[]
    }
}
const TourDescriptionItem = ({data}: TourDescriptionItemProps) => (
    <div className="gb--tour-description-item">
        <h4>{data.title}</h4>
        <ul>
            {data.list.map((item) => (
                <li>{item}</li>
            ))}
        </ul>
    </div>
)

export default TourDescriptionItem