import React from 'react';
import IconCalendarWithDate from "@Icons/IconCalendarWithDate";
import Column from "@Containers/components/Column";
import Row from "@Containers/components/Row";
import formatDate from "@DateTime/functions/formatDate";
import IconSlimArrowRight from "@Icons/IconSlimArrowRight";

interface TravelInfoProps {
    /** Name of departure station based on current locale */
    departureStationName: string,
    /** Name of destination station based on current locale */
    destinationStationName: string,
    /** Date for which we're searching, pass return date if displaying return direction info */
    date: string,
}

const TravelInfo = ({departureStationName, destinationStationName, date}: TravelInfoProps) => (
    <div className="gb--row gb--row-middle gb--travel-info__title">
        <IconCalendarWithDate width="5vh" height="4.5vh" date={date}/>
        <Column>
            <Row center>
                <span className="gb--emphasize gb--emphasize-default">
                    {departureStationName}
                </span>
                <IconSlimArrowRight width="1rem" fill="#070c63"/>
                <span className="gb--emphasize gb--emphasize-default">
                    {destinationStationName}
                </span>
            </Row>
            <small>
                {formatDate(date, "eeee, dd MMMM yyyy", true)}
            </small>
        </Column>
    </div>
);

export default TravelInfo;
