import React from 'react'
import IconMTicketQrLeaf from "@Icons/IconMTicketQrLeaf";
import Row from "@Containers/components/Row";

const MTicketLabel = () => (
    <Row center>
        <IconMTicketQrLeaf/>
        <small className="m-ticket-label">M-TICKET | ECO FRIENDLY</small>
    </Row>
)

export default MTicketLabel
