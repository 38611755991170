import React, {ReactNode} from 'react';

interface ButtonProps {
    onClick: (any?) => any,
    children: ReactNode,
    additionalClasses?: string,
    style?: object,
    className:
        'green' |
        'green-outline' |
        'blue' |
        'orange' |
        'red' |
        'border' |
        'inline' |
        'inactive' |
        'inactive-green' |
        'compact' |
        'disabled' |
        'mobile' |
        'dismiss',
}

const Button = ({children, className, onClick, additionalClasses = "", style}: ButtonProps) => (
    <button
        type="button"
        onClick={onClick}
        style={style}
        className={`btn btn--${className} ${additionalClasses}`}
    >
        {children}
    </button>
)

export default Button;