import {
    TicketAdditionalInfoFieldsType
} from "@CheckOrder/features/TicketAdditionalInfo/types/TicketAdditionalInfoFields.type";
import AdditionalField from "@CheckOrder/features/TicketAdditionalInfo/components/AdditionalField";

type AdditionalFieldsProps = {
    additionalInfoFields: TicketAdditionalInfoFieldsType[],
    index: number,
    userConfirmed: boolean,
    updateAllPassengers?: boolean,
}

function PassengerAdditionalFields({additionalInfoFields, userConfirmed, index, updateAllPassengers}: AdditionalFieldsProps) {
    return (
        <>
            {additionalInfoFields.map((field, fieldIndex) => (
                <AdditionalField
                    updateAllPassengers={updateAllPassengers}
                    /* eslint-disable-next-line react/no-array-index-key */
                    key={`${field.key}-${fieldIndex}`}
                    index={index}
                    userConfirmed={userConfirmed}
                    field={field}
                />
            ))}
        </>
    );
}

export default PassengerAdditionalFields;