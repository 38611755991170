import {googleTagManagerBeginCheckoutEvent} from "@CheckOrder/functions/googleTagManager"
import useOnUnmount from "@Hooks/lifecycle/useOnUnmount"
import useHandleStripePayment from "@CheckOrder/hooks/useHandleStripePayment"
import useCheckOrderSubmissionEvent from "@CheckOrder/hooks/useCheckOrderSubmissionEvent"
import React from "react"
import {useAppSelector} from "@Hooks/selector/useAppSelector"
import {useAppDispatch} from "@Hooks/dispatch/useAppDispatch"
import {
    checkPasswordValidation,
    setCheckOrderUserMessages,
    setPassengerDataUpdating,
    setUserConfirmed,
    showCheckOrderModals,
} from "@CheckOrder/reducers/checkOrderSlice"
import useUpdatePassengers from "@CheckOrder/hooks/useUpdatePassengers"
import loginAndReturnResponse from "@User/functions/loginAndReturnResponse"
import {setUser} from "@MyProfile/reducers/userProfileSlice"

interface UseConfirmAndPayAttributes {
    ipgFormRef: React.MutableRefObject<any>,
    privacyPolicyChecked: boolean,
    mustBePrintedPolicyChecked: boolean,
    newsletterAccepted: boolean,
}

export default function useConfirmAndPay(params: UseConfirmAndPayAttributes) {
    const {
        ipgFormRef,
        privacyPolicyChecked,
        mustBePrintedPolicyChecked,
        newsletterAccepted,
    } = params

    const {
        ticket,
        stripeEnabled,
        tripReviewAccepted,
        password,
        email,
        tokens,
        isBuyingPremiumSupport,
        isBuyingSMSNotification,
    } = useAppSelector((state) => state?.checkOrder)
    const {user} = useAppSelector((state) => (state?.userProfile))
    const {handleStripePayment} = useHandleStripePayment({
        newsletterAccepted,
        tripReviewAccepted,
        privacyPolicyChecked,
    })

    const {updateAllPassengersAndVerifySubmission, checkUserFieldValidation} = useUpdatePassengers()
    const {dispatchRedirectToPaymentEvent} = useCheckOrderSubmissionEvent({
        tripReviewAccepted,
        newsletterAccepted,
        privacyPolicyAccepted: privacyPolicyChecked,
    })

    const dispatch = useAppDispatch()

    useOnUnmount(() => {
        dispatch(setPassengerDataUpdating(false))
        dispatch(setUserConfirmed(false))
    })

    const tryToLoginUserIfPasswordFilled = async () => {
        if (user?.userLoginMethods?.status && (!user?.email && user?.password)) {
            // todo: revisit this IF with LLalic
            const loginResponse = await loginAndReturnResponse(
                email,
                password,
                ticket?.ticketCode,
                tokens?.csrfTokenBuyerInfo,
            )

            if (loginResponse.status !== 200) {
                return false
            }

            if (loginResponse?.data?.data?.errors || loginResponse?.data?.error) {
                dispatch(setCheckOrderUserMessages([{
                    userMessage: Object.values(loginResponse?.data?.data?.errors ?? loginResponse?.data?.error)[0] as string,
                    okBtnText: "OK",
                    deliveryType: "alert",
                }]))

                return false
            }

            dispatch(setUser(loginResponse.data.data.user))
            dispatch(checkPasswordValidation(true))
        }

        return true
    }

    return async () => {
        dispatch(setUserConfirmed(true))
        dispatch(showCheckOrderModals(true))
        const loginResponse = await tryToLoginUserIfPasswordFilled()
        if (loginResponse && checkUserFieldValidation() && privacyPolicyChecked && mustBePrintedPolicyChecked) {
            dispatch(setPassengerDataUpdating(true))
            updateAllPassengersAndVerifySubmission().then((submissionValid) => {
                if (submissionValid) {
                    if (ticket.voucher.ticketPriceFullyCoveredByVoucher && !isBuyingPremiumSupport && !isBuyingSMSNotification) {
                        ipgFormRef.current.submit()
                    } else {
                        dispatchRedirectToPaymentEvent().then(() => {
                            if (stripeEnabled) {
                                handleStripePayment()
                            } else {
                                // by corvus, or by voucher => payment submitting with post request
                                ipgFormRef.current.submit()
                            }
                        })
                    }
                } else {
                    dispatch(setPassengerDataUpdating(false))
                }
            })
        }

        googleTagManagerBeginCheckoutEvent(ticket)
    }
}