import React, {useEffect} from 'react';
import fromUnixTime from "date-fns/fromUnixTime";
import {useComponentDidMount} from "@Hooks/lifecycle/useComponentDidMount";
import {setDate, setReturnDate} from "@Form/reducers/formSlice";
import {useToggle} from "@Hooks/dispatch/useToggle";
import Translation from "@Translation/components/Translation";
import formatDate from "@DateTime/functions/formatDate";
import IconCalendar from "@Icons/IconCalendar";
import usePushNewUrl from "@Routing/hooks/usePushNewUrl";
import useForm from "@Form/hooks/useForm";
import {useAppDispatch} from "@Hooks/dispatch/useAppDispatch";

interface SuggestedDatesProps {
    dates: number[],
    isReturn: boolean,
}

function SuggestedDates({dates, isReturn}: SuggestedDatesProps) {
    const didMount = useComponentDidMount()
    const urlParams = useForm("urlParams")
    const {
        date,
        returnDate,
    } = urlParams
    const pushNewUrl = usePushNewUrl()
    const dispatch = useAppDispatch()
    const searched = useToggle()
    const shouldUpdate = (date || returnDate) && searched.value

    useEffect(() => {
        if (!didMount) {
            setTimeout(() => pushNewUrl(urlParams), 50)
        }
    }, [shouldUpdate])

    const searchSuggestedDate = (date) => {
        const formattedDate = formatDate(fromUnixTime(date))
        searched.onChange()
        if (isReturn) {
            dispatch(setReturnDate(formattedDate))
        } else {
            dispatch(setDate(formattedDate))
        }
    }

    const SuggestedDays = () => (
        <>
            {dates.map((date) => {
                const formattedDate = formatDate(fromUnixTime(date), 'dd.MM.yyyy')
                const day = formatDate(fromUnixTime(date), 'eeee')

                return (
                    <li key={date}>
                        <IconCalendar
                            width="16"
                            height="16"
                            fill="#4a79e2"
                            style={{verticalAlign: 'text-top', marginRight: '.5rem'}}
                        />
                        <a onClick={() => searchSuggestedDate(date)}>
                            {formattedDate}
                            &nbsp;|&nbsp;
                            <span className="suggested--day gb--emphasize-success">
                                {day}
                            </span>
                        </a>
                    </li>
                )
            })}
        </>
    )

    return (
        <div className="gb--suggestions">
            <h2>
                <Translation translationKey="no_res.available_date"/>
                :
            </h2>
            <ul className="suggested--dates gb--row">
                <SuggestedDays/>
            </ul>
        </div>
    );
}

export default SuggestedDates;