import React, {ReactNode} from 'react';
import Column from "@Containers/components/Column";

interface FilterSlideProps {
    step?: number,
    max: number,
    min: number,
    value: number,
    children: ReactNode,
    onChange: ({target: {value}}: { target: { value: any } }) => void,
    onRelease: (event) => void,
    className?: string,
}

const FilterSlider = ({max, min, onChange, children, value, step = 1, className, onRelease}: FilterSlideProps) => (
    <Column className="gb--filters-filter-slider">
        {children}
        <input
            value={value}
            onChange={onChange}
            type="range"
            max={max}
            min={min}
            onMouseUp={onRelease}
            onTouchEnd={onRelease}
            step={step}
            className={className}
        />
    </Column>
)

export default FilterSlider