import React from 'react';
import GenericSvgIcon from "@Icons/GenericSvgIcon";
import IconType from "@/types/Icon.type";

interface IconDiscountLabelProps extends IconType {
    discountText: string,
}

function IconDiscountLabel(props: IconDiscountLabelProps) {
    const {
        width = '45',
        height = '45',
        fill = '#fe5d5d',
        viewBox = '0 0 45 45',
        title = 'IconDiscountLabel',
        discountText,
        ...rest
    } = props;

    return (
        <GenericSvgIcon
            width={width}
            height={height}
            fill={fill}
            viewBox={viewBox}
            title={title}
            discountText={discountText}
            {...rest}
            svgIconPath={(
                <>
                    <defs>
                        <path
                            id="a"
                            d="m17.5 32.3623047-3.2156166 2.3397245-2.1532671-3.3433428-3.84367915 1.0201135-.80010707-3.8954242-3.95263156-.4372696.6611119-3.9214068-3.52775918-1.835597 2.03304404-3.4177818-2.62644337-2.9860168 3.13040232-2.4525664-1.37041183-3.73315853 3.80498242-1.15611835.07070142-3.97611647 3.96567856.29646993 1.502266-3.68207789 3.5907879 1.7090183 2.7309413-2.89075429 2.7309413 2.89075429 3.5907879-1.7090183 1.502266 3.68207789 3.9656786-.29646993.0707014 3.97611647 3.8049824 1.15611835-1.3704118 3.73315853 3.1304023 2.4525664-2.6264434 2.9860168 2.0330441 3.4177818-3.5277592 1.835597.6611119 3.9214068-3.9526316.4372696-.800107 3.8954242-3.8436792-1.0201135-2.1532671 3.3433428z"
                        />
                        <filter id="b" height="139.1%" width="139.6%" x="-19.8%" y="-14.3%">
                            <feOffset dx="0" dy="2" in="SourceAlpha" result="shadowOffsetOuter1"/>
                            <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="2"/>
                            <feColorMatrix
                                in="shadowBlurOuter1"
                                type="matrix"
                                values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.5 0"
                            />
                        </filter>
                    </defs>
                    <g fill="none" fillRule="evenodd" transform="translate(3 2)">
                        <use fill="#000" filter="url(#b)" xlinkHref="#a"/>
                        <use fill="#fe5d5d" fillRule="evenodd" xlinkHref="#a"/>
                    </g>
                    <text x="21" y="24" fontSize="14" fill="#fff" textAnchor="middle">
                        {discountText}
                    </text>
                </>
            )}
        />

    );
}

export default IconDiscountLabel;
