import React, {lazy} from 'react';
import permalinkTypeId from '../../constants/PermalinkTypeId';
import useTrackingEvents from "@Events/hooks/useTrackingEvents";

const CountryCompanies = lazy(() => import(
    /* webpackChunkName: "CountryCompanies" */
    '@Pages/countryPage/features/countryCompanies/components/CountryCompanies'
));
const CountryList = lazy(() => import(
    /* webpackChunkName: "CountryList" */
    '@Pages/countryPage/features/countryList/components/CountryList'
));
const CountryStations = lazy(() => import(
    /* webpackChunkName: "CountryStations" */
    '@Pages/countryPage/features/countryStations/components/CountryStations'
));
const SuccessfulPurchase = lazy(() => import(
    /* webpackChunkName: "SuccessfulPurchaseGetByFerryBannerPortal" */
    '@Pages/successfulPurchase/components/SuccessfulPurchase'
));
const StationToStation = lazy(() => import(
    /* webpackChunkName: "StationToStation" */
    '@Pages/stationToStation/components/StationToStation'
));
const StationPage = lazy(() => import(
    /* webpackChunkName: "StationPage" */
    '@Pages/stationPage/components/StationPage'
));
const CompanyList = lazy(() => import(
    /* webpackChunkName: "CompanyList" */
    '@Pages/companyList/components/CompanyList'
));
const CompanyReview = lazy(() => import(
    /* webpackChunkName: "CompanyReview" */
    '@Pages/reviews/components/ReviewsPage'
));
const FosUserSecurityLogin = lazy(() => import(
    /* webpackChunkName: "FosUserSecurityLogin" */
    '@Pages/fosUserSecurityLogin/components/FosUserSecurityLogin'
));
const CountryPage = lazy(() => import(
    /* webpackChunkName: "CountryPage" */
    '@Pages/countryPage/components/CountryPage'
));
const CompanyPage = lazy(() => import(
    /* webpackChunkName: "CompanyProfilePortal" */
    '@Pages/companyProfile/components/CompanyProfilePortal'
));
const StationLocator = lazy(() => import(
    /* webpackChunkName: "StationLocator" */
    '@Pages/stationLocator/components/StationLocator'
));
const ForCompaniesPage = lazy(() => import(
    /* webpackChunkName: "ForCompaniesPage" */
    '@Pages/forCompaniesPage/components/ForCompaniesPage'
    ));

const ContactForm = lazy(() => import(
    /* webpackChunkName: "ContactForm" */
    '@Pages/contactForm/components/ContactForm'
    ));

const FaqPortal = lazy(() => import(
    /* webpackChunkName: "StationLocator" */
    '@Generic/FaqPortal'
    ));

const PositiveReviewSuccess = lazy(() => import(
    /* webpackChunkName: "PositiveReviewSuccess" */
    '@Pages/reviews/features/reviewSuccess/components/PositiveReviewSuccess'
    ));

const BusRent = lazy(() => import(
    /* webpackChunkName: "BusRent" */
    '@Pages/busRent/components/BusRent'
    ));

const SalesPage = lazy(() => import(
    /* webpackChunkName: "SpecialOfferPage" */
    '@Pages/specialOffersPage/components/SpecialOfferPage'
    ));

const MeetTheTeamPage = lazy(() => import(
    /* webpackChunkName: "MeetTheTeamPage" */
    '@Pages/meetTheTeam/components/MeetTheTeamPage'
    ));

/**
 * These pages inject react portal within content but are otherwise static (not using router for navigation)
 */
function PortalPages() {
    useTrackingEvents()
    const {
        currentRoute,
    } = window
    const type = window?.permalinkTypeId ?? null

    const {
        stationToStation,
        portToPort,
        station,
        port,
        busCountry,
        busCompany,
        ferryCountry,
        islandPage,
        ferryCompany,
    } = permalinkTypeId

    const portToPortOrStationToStation = type === stationToStation || type === portToPort
    const portOrStation = type === port || type === station
    const stationOrPortLocator = currentRoute === 'bus_station_locator' || currentRoute === 'ferry_port_locator'

    const pageToDisplay = {
        [`${currentRoute === 'fos_user_security_login'}`]: <FosUserSecurityLogin/>,
        [`${currentRoute === 'company_list'}`]: <CompanyList/>,
        [`${currentRoute === 'ferry_company_list'}`]: <CompanyList/>,
        [`${currentRoute === 'bus_countries_list'}`]: <CountryList/>,
        [`${currentRoute === 'ferry_countries_list'}`]: <CountryList/>,
        [`${currentRoute === 'bus_stations_in_country'}`]: <CountryStations/>,
        [`${currentRoute === 'ferry_ports_in_country'}`]: <CountryStations/>,
        [`${currentRoute === 'bus_companies_in_country'}`]: <CountryCompanies/>,
        [`${currentRoute === 'company_profile_reviews'}`]: <CompanyReview/>,
        [`${currentRoute === 'global_review_page'}`]: <CompanyReview/>,
        [`${currentRoute === 'meet_the_team'}`]: <MeetTheTeamPage/>,
        [`${currentRoute === 'global_review_page_filtered'}`]: <CompanyReview/>,
        [`${currentRoute === 'gbb_front_successful_purchase' || window.location.pathname === '/successful-purchase'}`]: <SuccessfulPurchase/>,
        [`${stationOrPortLocator}`]: <StationLocator/>,
        [`${type === busCountry}`]: <CountryPage/>,
        [`${portToPortOrStationToStation}`]: <StationToStation/>,
        [`${portOrStation}`]: <StationPage/>,
        [`${type === busCompany}`]: <CompanyPage/>,
        [`${currentRoute === 'bus_front_for_companies'}`]: <ForCompaniesPage/>,
        [`${currentRoute === 'gbb_front_contact'}`]: <ContactForm/>,
        [`${type === ferryCompany}`]: <CompanyPage />,
        [`${type === islandPage}`]: <FaqPortal />,
        [`${type === port}`]: <StationPage />,
        [`${type === ferryCountry}`]: <FaqPortal />,
        [`${currentRoute === 'thank-you-for-review' || currentRoute === "company_review_submit"}`]: <PositiveReviewSuccess/>,
        [`${currentRoute === 'bus_front_rent_a_bus'}`]: <BusRent/>,
        [`${currentRoute === 'special_offers'}`]: <SalesPage/>,
    }

    return pageToDisplay?.true ?? null
}

export default PortalPages;