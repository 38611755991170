import React from 'react';
import EmphasizedStringSection from "@Generic/EmphasizedStringSection";
import useMillisecondsToDateConverter from "@DateTime/hooks/useMillisecondsToDateConverter";
import {useTranslation} from "@Translation/hooks/useTranslation";
import {setFilterDepartAfter} from "@SearchResults/reducers/filterSlice";
import FilterSlider from "@SearchResults/features/sidebar/components/FilterSlider";
import useFilterSliderValue from "@SearchResults/features/sidebar/hooks/useFilterSliderValue";
import FilterSliderLabels from "@SearchResults/features/sidebar/components/FilterSliderLabels";
import ParamsType from "@SearchResults/types/params/Params.type";

function DepartAfterFilter({params}: {params: ParamsType}) {
    const {
        value,
        updateValue,
        min,
        max,
        setValueState,
    } = useFilterSliderValue({
        onChange: setFilterDepartAfter,
        boundValue: "RouteDepartureTime",
        invert: true,
    })

    const {
        date,
        departureStation: {
            name,
        },
    } = params

    const {
        depart_from: departFrom,
        after,
    } = useTranslation("search_results.filters")

    const formattedDate = useMillisecondsToDateConverter(Math.abs(value), date)
    const formattedMinDate = useMillisecondsToDateConverter(Math.abs(max), date)
    const formattedDateToReturn = value ? formattedDate : formattedMinDate

    return (
        <FilterSlider
            onChange={setValueState}
            value={value}
            min={min}
            max={max}
            onRelease={updateValue}
            className="gb--slider-flip"
        >
            <FilterSliderLabels
                label={(
                    <EmphasizedStringSection
                        prefix={departFrom}
                        emphasized={name}
                        suffix={after}
                    />
                )}
                value={formattedDateToReturn}
            />
        </FilterSlider>
    )
}

export default DepartAfterFilter;