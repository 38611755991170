import Column from "@Containers/components/Column";
import React, {ReactNode} from "react";
import useIsMobile from "@Hooks/selector/useIsMobile";
import dateIntervalFormatter from "@MyProfile/functions/dateIntervalFormatter";
import TicketRouteTitle from "@MyTicket/components/TicketRouteTitle";
import TicketTourTitle from "@MyTicket/components/TicketTourTitle";

interface TicketTripDataProps {
    departureStationName: string,
    destinationStationName: string,
    routeIcon: ReactNode,
    departureDate: string,
    returnDate?: string,
    className?: string,
    directionLabel?: string,
    isTour?: boolean,
    tourName?: string
}

function TicketTripData(props: TicketTripDataProps) {
    const {
        departureStationName,
        destinationStationName,
        routeIcon,
        departureDate,
        returnDate,
        className = "",
        directionLabel,
        isTour,
        tourName,
    } = props

    const isMobile = useIsMobile()

    return (
        <Column className={`trip-data-info ${className} info-field-large`}>
            {isTour ? (
                <TicketTourTitle
                    tourName={tourName}
                />
            ) : (
                <TicketRouteTitle
                    departureStationName={departureStationName}
                    destinationStationName={destinationStationName}
                    routeIcon={routeIcon}
                />
            )}
            <div>
                {isMobile && directionLabel && !isTour && (
                    <span className="gb--emphasize-info">
                        {directionLabel}
                        :
                        &nbsp;
                    </span>
                )}
                {dateIntervalFormatter({
                    startDate: departureDate,
                    endDate: returnDate,
                    includeHours: true,
                })}
            </div>
        </Column>
    )
}

export default TicketTripData;