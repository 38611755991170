import React from "react"
import useIsMobile from "@Hooks/selector/useIsMobile"
import {isMinivanRoute} from "@Route/features/transportType/functions/isMinivanRoute"
import {isPrivateBusRoute} from "@Route/features/transportType/functions/isPrivateBusRoute"
import IntermodalRouteWithDetailsType from "@Intermodal/types/IntermodalRouteWithDetails.type"
import RouteWithAddedPropsAndPricesType from "@Route/types/RouteWithAddedPropsAndPrices.type"
import SuggestedRouteType from "@Route/types/SuggestedRoute.type"
import RouteClosedOrUnavailable from "@Route/components/Price/RouteClosedOrUnavailable"
import TotalAllotmentLeft from "@Route/components/Price/TotalAllotmentLeft"
import Discount from "@Route/components/Price/Discount/Discount"
import RouteActionButton from "@Route/features/ticketPurchase/components/RouteActionButton"
import RouteTotalPriceContainer from "@Route/components/Price/RouteTotalPriceContainer"
import ParamsType from "@SearchResults/types/params/Params.type"
import RouteStatusLabels from "@Route/components/Labels/RouteStatusLabels"
import usePassengers from "@Hooks/selector/usePassengers";

interface RouteStatusContainerProps {
    route: IntermodalRouteWithDetailsType | RouteWithAddedPropsAndPricesType | SuggestedRouteType,
    isReturn: boolean,
    params: ParamsType,
}

function RouteStatusContainer(props: RouteStatusContainerProps) {
    const {
        route,
        isReturn,
        params,
    } = props

    const {
        prices,
        status: {
            status,
            soldOut,
            isSuggestion,
        },
        allotment: {
            totalAllotmentLeft,
        },
        travelInfo: {
            departureStation: {
                departureDate,
            },
            destinationStation: {
                arrivalDate,
            },
        },
        type,
    } = route

    const passengers = usePassengers()
    const {discounts, price} = prices
    const isMobile = useIsMobile()
    const hasDiscounts = !isSuggestion && !!Object.keys(discounts)?.length && status === "open"
    const overnightTravel = departureDate !== arrivalDate
    const isMinivan = isMinivanRoute(type.routeTransferType)
    const isPrivateBus = isPrivateBusRoute(type.routeTransferType)
    const showOvernightTravel = (totalAllotmentLeft - passengers.passengers) > 10

    return (
        <div className="gb--row gb--route-status">
            {(status === "closed" || status === "unavailable") ? (
                <RouteClosedOrUnavailable
                    soldOut={soldOut}
                    closed={status === "closed"}
                    unavailable={status === "unavailable"}
                />
            ) : (
                <>
                    <TotalAllotmentLeft
                        totalAllotmentLeft={totalAllotmentLeft}
                    />
                    <RouteTotalPriceContainer
                        route={route}
                        totalPrice={price}
                    />
                </>
            )}

            <RouteStatusLabels
                showOvernightTravel={showOvernightTravel}
                isMinivan={isMinivan}
                isPrivateBus={isPrivateBus}
                overnightTravel={overnightTravel}
                isPrivate={type.isPrivate}
            />

            {hasDiscounts && (
                <Discount
                    prices={prices}
                />
            )}

            {status === "open" && !isMobile && (
                <RouteActionButton
                    params={params}
                    route={route}
                    expandedPanel={false}
                    isReturn={isReturn}
                />
            )}
        </div>
    )
}

export default RouteStatusContainer