import React from 'react';
import Column from "@Containers/components/Column";
import TicketPrintConfirmation
    from "@CheckOrder/components/MainSection/TermsAndConditions/Checkboxes/TicketPrintConfirmation";
import TosAndPrivacyPolicy from "@CheckOrder/components/MainSection/TermsAndConditions/Checkboxes/TosAndPrivacyPolicy";
import HiddenSubmissionForm from "@CheckOrder/components/MainSection/SubmissionForm/HiddenSubmissionForm";
import Newsletter from "@CheckOrder/components/MainSection/TermsAndConditions/Checkboxes/Newsletter";

interface TermsAndConditionsProps {
    userConfirmed: boolean,
    newsletter: {
        value: boolean,
        set: (value: any) => void
        onChange: () => void,
    },
    needsToBePrintedNotice: {
        value: boolean,
        set: (value: any) => void
        onChange: () => void,
    },
    privacyPolicy: {
        value: boolean,
        set: (value: any) => void
        onChange: () => void,
    },
    needsToBePrinted: boolean,
    singleCompanyId: number | string,
    ipgFormRef: React.ForwardedRef<any>,
    singleRouteCompanyTermsUrl: string | null,
    returnRouteCompanyTermsUrl: string | null,
    carrierName: string | null,
}

function TermsAndConditions(props: TermsAndConditionsProps) {
    const {
        newsletter,
        needsToBePrintedNotice,
        privacyPolicy,
        needsToBePrinted,
        userConfirmed,
        singleCompanyId,
        ipgFormRef,
        singleRouteCompanyTermsUrl,
        returnRouteCompanyTermsUrl,
        carrierName,
    } = props

    return (
        <Column className="gb--check-order-terms-and-conditions">
            <Newsletter
                onClick={newsletter.onChange}
                value={newsletter.value}
            />
            {needsToBePrinted && (
                <TicketPrintConfirmation
                    userConfirmed={userConfirmed}
                    onClick={needsToBePrintedNotice.onChange}
                    value={needsToBePrintedNotice.value}
                />
            )}
            <TosAndPrivacyPolicy
                singleRouteCompanyTermsUrl={singleRouteCompanyTermsUrl}
                returnRouteCompanyTermsUrl={returnRouteCompanyTermsUrl}
                carrierName={carrierName}
                userConfirmed={userConfirmed}
                singleCompanyId={parseInt(`${singleCompanyId}`, 10)}
                onClick={privacyPolicy.onChange}
                value={privacyPolicy.value}
            />
            <HiddenSubmissionForm
                ipgFormRef={ipgFormRef}
                newsletterAccepted={newsletter.value}
                privacyPolicyAccepted={privacyPolicy.value}
            />
        </Column>
    );
}

export default TermsAndConditions;