import React from 'react';
import TabsWrapper from '@Tabs/components/TabsWrapper';
import SearchResultsPlaceholder from "@SearchResults/components/Placeholders/SearchResultsPlaceholder";
import {SearchResponseType} from "@SearchResults/types/SearchResponse.type";
import NoResultsContainer from "@SearchResults/components/NoResults/NoResultsContainer";
import hasNoResults from "@SearchResults/functions/hasNoResults";
import useIsCompanyStore from "@Hooks/selector/useIsCompanyStore"

function SearchResults({searchQuery, suggestionRoutesEnabled}: {searchQuery: SearchResponseType, suggestionRoutesEnabled: boolean}) {
    const isCompanyStore = useIsCompanyStore()

    if (searchQuery.isLoading) {
        return (
            <SearchResultsPlaceholder/>
        );
    }

    /* we're not loading results and don't have any result in state so show no results component */

    if (hasNoResults(searchQuery, isCompanyStore)) {
        return (
            <NoResultsContainer
                searchResultsResponse={searchQuery.data}
            />
        )
    }

    return (
        <TabsWrapper
            suggestionRoutesEnabled={suggestionRoutesEnabled}
            searchQuery={searchQuery}
        />
    )
}

export default SearchResults
