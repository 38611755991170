import {useCountArchivedTicketsQuery} from "@MyProfile/API/myProfileApi";

export default function useCountArchivedTickets() {
    const {
        data,
        isUninitialized,
        isLoading,
    } = useCountArchivedTicketsQuery()

    const archivedTicketsCountLoading = isUninitialized || isLoading

    return {
        archivedTicketsCount: data?.count,
        archivedTicketsCountLoading,
    }
}