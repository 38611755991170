import React from 'react'
import {useTranslation} from "@Translation/hooks/useTranslation";

interface TourItineraryOverlayTitleProps {
    tourName: string
}

const TourItineraryOverlayTitle = ({tourName}: TourItineraryOverlayTitleProps) => {
    const tourItineraryLabel = useTranslation("tour.itinerary")

    return (
        <div>
            <h2>{tourItineraryLabel}</h2>
            <h2>{tourName}</h2>
        </div>
    )
}

export default TourItineraryOverlayTitle
