import React from 'react';
import EmphasizedStringSection from "@Generic/EmphasizedStringSection";
import useMillisecondsToDateConverter from "@DateTime/hooks/useMillisecondsToDateConverter";
import {useTranslation} from "@Translation/hooks/useTranslation";
import {setFilterArriveBefore} from "@SearchResults/reducers/filterSlice";
import FilterSlider from "@SearchResults/features/sidebar/components/FilterSlider";
import useFilterSliderValue from "@SearchResults/features/sidebar/hooks/useFilterSliderValue";
import FilterSliderLabels from "@SearchResults/features/sidebar/components/FilterSliderLabels";
import ParamsType from "@SearchResults/types/params/Params.type";

function ArriveBeforeFilter({params}: {params: ParamsType}) {
    const {
        value,
        updateValue,
        min,
        max,
        setValueState,
    } = useFilterSliderValue({
        onChange: setFilterArriveBefore,
        boundValue: "RouteArrivalTime",
    })

    const {
        date,
        destinationStation: {
            name,
        },
    } = params

    const formattedDate = useMillisecondsToDateConverter(value, date)
    const formattedMaxDate = useMillisecondsToDateConverter(max, date)
    const formattedDateToReturn = value ? formattedDate : formattedMaxDate
    const {
        arrive_in: arriveIn,
        before,
    } = useTranslation("search_results.filters")

    return (
        <FilterSlider
            onChange={setValueState}
            value={value}
            min={min}
            max={max}
            onRelease={updateValue}
        >
            <FilterSliderLabels
                label={(
                    <EmphasizedStringSection
                        prefix={arriveIn}
                        emphasized={name}
                        suffix={before}
                    />
                )}
                value={formattedDateToReturn}
            />
        </FilterSlider>
    )
}

export default ArriveBeforeFilter;