import React from 'react';
import Row from "@Containers/components/Row";
import IconFilterWithCount from "@Icons/IconFilterWithCount";
import Translation from "@Translation/components/Translation";
import Filters from "@SearchResults/features/sidebar/components/Filters";
import useSearchResults from "@SearchResults/hooks/useSearchResults";
import SidebarSectionLoader from "@Sidebar/components/Sections/SidebarSectionLoader";

function FiltersContainer() {
    const searchResults = useSearchResults()
    return (
        <div className="gb--sidebar-section gb--sidebar-section-filters">
            <Row center className="gb--sidebar-section-title">
                <div className="icon-container">
                    <IconFilterWithCount width="25" height="25"/>
                </div>
                <h3>
                    <Translation translationKey="search_results.filters.filters"/>
                </h3>
            </Row>
            <div className="gb--sidebar-section-content">
                {searchResults?.data?.result?.params ? (
                    <Filters
                        params={searchResults.data.result.params}
                    />
                ) : (
                    <SidebarSectionLoader expectedDataCount={14}/>
                )}
            </div>
        </div>
    );
}

export default FiltersContainer;