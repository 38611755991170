import {TourType} from "@SearchResults/features/tours/types/Tour.type";
import {calculateTripDuration} from "@Route/functions/calculateTripDuration";

export const createTour = ({id, route}): TourType => (
    {
        id: `${id}`,
        companyId: route.companyId,
        prices: {},
        travelInfo: {
            // @ts-ignore
            departureStation: {
                name: route.departureStation.name,
                departureTime: route.departureStation.departureTime,
                departureDate: route.departureStation.departureDate,
            },
            // @ts-ignore
            destinationStation: {
                name: route.destinationStation.name,
                arrivalTime: route.destinationStation.arrivalTime,
                arrivalDate: route.destinationStation.arrivalDate,
            },
            stations: route.tourInfoModel.tourStops,
            travelDuration: calculateTripDuration(
                `${route.departureStation.departureDate} ${route.departureStation.departureTime}`,
                `${route.destinationStation.arrivalDate} ${route.destinationStation.arrivalTime}`
            ),
        },

    }
)