import {useScroll} from "@Scroll/hooks/useScroll";
import {useEffect, useState} from "react";
import {useLazyGetValidVouchersQuery, useLoadMoreValidVouchersMutation} from "@MyProfile/API/myProfileApi";

export default function useGetValidVouchers(showValid: boolean) {
    const [page, setPage] = useState(1)

    const {elRef, executeScroll} = useScroll({block: "start"})

    const [fetchValidVouchers, {data, isLoading}] = useLazyGetValidVouchersQuery()
    const [fetchMoreValidVouchers, {isLoading: moreDataLoading}] = useLoadMoreValidVouchersMutation()

    useEffect(() => {
        if (elRef.current && !isLoading) {
            executeScroll()
        }
    }, [isLoading])

    // On initial render, or if data was changed and data is empty (on clearing state) fetch first batch of vouchers
    useEffect(() => {
        if (!data && showValid) {
            fetchValidVouchers({page, showValid})
        }
    }, [data, showValid])

    // if page changes and data exists, fetch more valid vouchers
    useEffect(() => {
        if (!!data && showValid) {
            fetchMoreValidVouchers({page, showValid})
        }
    }, [page])

    return {
        data,
        elRef,
        page,
        setPage,
        loading: isLoading,
        batchLoading: moreDataLoading,
    }
}
