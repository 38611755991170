import {ReactNode} from "react";

type GridProps = {
    children: ReactNode,
    className?: string,
    autofill?: boolean
}

function Grid(props: GridProps) {
    return (
        <div
            className={`gb--grid ${props.className ?? ''}`}
            style={{
                gridTemplateColumns: props.autofill ? "repeat( auto-fill, minmax(300px, 1fr))" : "repeat( auto-fit, minmax(300px, 1fr))",
            }}
        >
            {props.children}
        </div>
    );
}

export default Grid;