import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import ValidTicketsRequestType from "@MyProfile/types/ticket/ValidTicketsRequest.type";
import ArchivedTicketsRequestType from "@MyProfile/types/ticket/ArchivedTicketsRequest.type";
import ValidVouchersRequestType from "@MyProfile/types/voucher/ValidVouchersRequest.type";
import ArchivedVouchersRequestType from "@MyProfile/types/voucher/ArchivedVouchersRequest.type";
import RebookTicketResponseType from "@MyProfile/types/ticket/RebookTicketResponse.type";
import CountResponseType from "@MyProfile/types/CountResponse.type";
import TicketType from "@MyProfile/features/tickets/features/ticket/types/Ticket.type";
import TicketsType from "@MyProfile/features/tickets/types/Tickets.type";
import VouchersType from "@MyProfile/features/vouchers/types/Vouchers.type";
import VoucherType from "@MyProfile/features/vouchers/features/voucher/types/Voucher.type";
import GetCurrentUserResponseType from "@MyProfile/types/GetCurrentUserResponse.type";

export default interface GetMyTicketsQueryResponseType {
    data: {
        tickets: TicketType[]
    }
}

export const myProfileApi = createApi({
    reducerPath: "myProfileApi",
    baseQuery: fetchBaseQuery({
        baseUrl: "/ajax/",
        prepareHeaders: (headers, {getState}) => {
            headers.set("X-Requested-With", "XMLHttpRequest")
            return headers
        },
    }),
    tagTypes: ["ValidVouchers", "ArchivedVouchers", "ValidTickets", "ArchivedTickets"],
    endpoints: (builder) => ({
        getCurrentUser: builder.query<GetCurrentUserResponseType, null>({
            query: () => ({
                url: "get-current-user",
                method: 'GET',
            }),
        }),
        getValidTickets: builder.query<TicketsType, ValidTicketsRequestType>({
            providesTags: ["ValidTickets"],
            query: ({page, showValid}) => ({
                url: 'get-my-tickets',
                method: 'GET',
                params: {
                    page,
                    showValid,
                },
            }),
        }),
        loadMoreValidTickets: builder.mutation<TicketsType, ValidTicketsRequestType>({
            query: ({page, showValid}) => ({
                url: 'get-my-tickets',
                method: 'GET',
                params: {
                    page,
                    showValid,
                },
            }),
            async onQueryStarted(request, {dispatch, queryFulfilled}) {
                try {
                    const response = await queryFulfilled

                    const updateValidTickets = ({tickets, paths}: { tickets: TicketType[], paths: any }) => ({
                        paths,
                        tickets: [...tickets, ...response.data.tickets],
                    })

                    // @ts-ignore
                    dispatch(myProfileApi.util.updateQueryData(
                        'getValidTickets',
                        // Original query is always first page
                        {showValid: true, page: 1},
                        updateValidTickets
                    ))
                } catch (error) {
                }
            },
        }),
        getArchivedTickets: builder.query<TicketsType, ArchivedTicketsRequestType>({
            providesTags: ["ArchivedTickets"],
            query: ({page, showArchived}) => ({
                url: 'get-my-tickets',
                method: 'GET',
                params: {
                    page,
                    showArchived,
                },
            }),
        }),
        loadMoreArchivedTickets: builder.mutation<TicketsType, ArchivedTicketsRequestType>({
            query: ({page, showArchived}) => ({
                url: 'get-my-tickets',
                method: 'GET',
                params: {
                    page,
                    showArchived,
                },
            }),
            async onQueryStarted(request, {dispatch, queryFulfilled}) {
                try {
                    const response = await queryFulfilled

                    const updateArchivedTickets = ({tickets, paths}: { tickets: TicketType[], paths: any }) => ({
                        paths,
                        tickets: [...tickets, ...response.data.tickets],
                    })

                    dispatch(myProfileApi.util.updateQueryData(
                        'getArchivedTickets',
                        // Original query is always first page
                        {showArchived: true, page: 1},
                        updateArchivedTickets
                    ))
                } catch (error) {
                }
            },
        }),
        countValidTickets: builder.query<CountResponseType, void>({
            query: () => ({
                url: 'count-user-tickets',
                method: 'GET',
                params: {
                    showValid: true,
                },
            }),
        }),
        countArchivedTickets: builder.query<CountResponseType, void>({
            query: () => ({
                url: 'count-user-tickets',
                method: 'GET',
                params: {
                    showArchived: true,
                },
            }),
        }),
        getValidVouchers: builder.query<VouchersType, ValidVouchersRequestType>({
            providesTags: ["ValidVouchers"],
            query: ({page, showValid}) => ({
                url: 'get-my-vouchers',
                method: 'GET',
                params: {
                    page,
                    showValid,
                },
            }),
        }),
        loadMoreValidVouchers: builder.mutation<VouchersType, ValidVouchersRequestType>({
            query: ({page, showValid}) => ({
                url: 'get-my-vouchers',
                method: 'GET',
                params: {
                    page,
                    showValid,
                },
            }),
            async onQueryStarted(request, {dispatch, queryFulfilled}) {
                try {
                    const response = await queryFulfilled
                    const updateValidVouchers = ({vouchers}: { vouchers: VoucherType[] }) => ({
                        vouchers: [...vouchers, ...response.data.vouchers],
                    })
                    dispatch(myProfileApi.util.updateQueryData(
                        'getValidVouchers',
                        // Original query is always first page
                        {showValid: true, page: 1},
                        updateValidVouchers
                    ))
                } catch (error) {
                }
            },
        }),
        getArchivedVouchers: builder.query<VouchersType, ArchivedVouchersRequestType>({
            providesTags: ["ArchivedVouchers"],
            query: ({page, showArchived}) => ({
                url: 'get-my-vouchers',
                method: 'GET',
                params: {
                    page,
                    showArchived,
                },
            }),
        }),
        loadMoreArchivedVouchers: builder.mutation<VouchersType, ArchivedVouchersRequestType>({
            query: ({page, showArchived}) => ({
                url: 'get-my-vouchers',
                method: 'GET',
                params: {
                    page,
                    showArchived,
                },
            }),
            async onQueryStarted(request, {dispatch, queryFulfilled}) {
                try {
                    const response = await queryFulfilled
                    const updateValidVouchers = ({vouchers}: { vouchers: VoucherType[] }) => ({
                        vouchers: [...vouchers, ...response.data.vouchers],
                    })
                    dispatch(myProfileApi.util.updateQueryData(
                        'getArchivedVouchers',
                        // Original query is always first page
                        {showArchived: true, page: 1},
                        updateValidVouchers
                    ))
                } catch (error) {
                }
            },
        }),
        countValidVouchers: builder.query<CountResponseType, void>({
            providesTags: ["ValidVouchers"],
            query: () => ({
                url: 'count-user-vouchers',
                method: 'GET',
                params: {
                    showValid: true,
                },
            }),
        }),
        countArchivedVouchers: builder.query<CountResponseType, void>({
            providesTags: ["ArchivedVouchers"],
            query: () => ({
                url: 'count-user-vouchers',
                method: 'GET',
                params: {
                    showArchived: true,
                },
            }),
        }),
        rebookTicket: builder.mutation<RebookTicketResponseType, string>({
            invalidatesTags: ["ValidTickets", "ArchivedTickets", "ValidVouchers"],
            query: (ticketId) => ({
                url: `rebook-ticket`,
                method: 'POST',
                params: {
                    ticketId,
                },
            }),
        }),
    }),
})

export const {
    useLazyGetCurrentUserQuery,
    useLazyGetValidTicketsQuery,
    useLoadMoreValidTicketsMutation,
    useLazyGetArchivedTicketsQuery,
    useLoadMoreArchivedTicketsMutation,
    useCountValidTicketsQuery,
    useCountArchivedTicketsQuery,
    useLazyGetValidVouchersQuery,
    useLoadMoreValidVouchersMutation,
    useLazyGetArchivedVouchersQuery,
    useLoadMoreArchivedVouchersMutation,
    useCountValidVouchersQuery,
    useCountArchivedVouchersQuery,
    useRebookTicketMutation,
} = myProfileApi