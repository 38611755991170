import React from 'react';
import SearchResultsSidebar from "@Sidebar/components/SearchResultsSidebar";
import useIsDesktop from "@Hooks/selector/useIsDesktop";
import {PromiseTokenProvider} from "@SearchResults/context/PromiseTokenContext";
import SearchResults from "./SearchResults";
import ErrorBoundary from "@Errors/components/ErrorBoundary";
import useSearchResultsFetcher from "@SearchResults/api/hooks/useSearchResultsFetcher";
import {useLocation} from "react-router";
import useApiRoutesFetcher from "@SearchResults/api/hooks/useApiRoutesFetcher";
import TravelInfoContainer from "@SearchResults/components/HeadingContent/TravelInfoContainer";
import {ActiveTicketProvider} from "@Route/features/mobile/context/ActiveTicketContext";
import Form from "@Form/components/Form";
import useIsMobile from "@Hooks/selector/useIsMobile";
import useSuggestionRoutesFetcher from "@SearchResults/api/hooks/useSuggestionRoutesFetcher";
import SearchResultsPlaceholder from "@SearchResults/components/Placeholders/SearchResultsPlaceholder";
import SearchResultsError from "@SearchResults/components/SearchResultsError"

function SearchResultsContainer() {
    const searchQuery = useSearchResultsFetcher()
    useApiRoutesFetcher(searchQuery.data)
    const {suggestionRoutesEnabled} = useSuggestionRoutesFetcher(searchQuery.data)
    const isDesktop = useIsDesktop()
    const params = useLocation()
    const isMobile = useIsMobile()

    return (
        <ErrorBoundary shouldReload>
            {!isMobile && (
                <Form/>
            )}
            <div className="gb--main-content">
                {searchQuery.data && (
                    <ActiveTicketProvider singleSelectedRoute={params.state?.route}>
                        <TravelInfoContainer
                            isLoading={searchQuery.isLoading}
                            params={searchQuery.data.result.params}
                        />
                        <div className={`${!isDesktop ? '' : 'gb-container gb--main-content__container'}`}>
                            <div className="search-results_main-content">
                                <PromiseTokenProvider>
                                    <SearchResults
                                        suggestionRoutesEnabled={suggestionRoutesEnabled}
                                        searchQuery={searchQuery}
                                    />
                                </PromiseTokenProvider>
                            </div>
                            <SearchResultsSidebar/>
                        </div>
                    </ActiveTicketProvider>
                )}
                {(searchQuery.isLoading && !searchQuery.error) && (
                    <SearchResultsPlaceholder/>
                )}
                {searchQuery.error && (
                    <SearchResultsError
                        error={searchQuery.error}
                    />
                )}
            </div>
        </ErrorBoundary>
    );
}

export default SearchResultsContainer;