import React from 'react';
import FiltersContainer from "@SearchResults/features/sidebar/components/FiltersContainer";
import Sidebar from "@Sidebar/components/Sidebar";
import useHasSearchResults from "@SearchResults/hooks/useHasSearchResults";

function SearchResultsSidebar() {
    const hasSearchResults = useHasSearchResults(2)
    return (
        <Sidebar className="">
            {hasSearchResults && (
                <FiltersContainer/>
            )}
        </Sidebar>
    );
}

export default SearchResultsSidebar;