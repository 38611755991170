import Column from "@Containers/components/Column";
import React from "react";
import {useTranslation} from "@Translation/hooks/useTranslation";
import {PreparedRouteType} from "@Route/types/PreparedRoute.type";
import useIsMobile from "@Hooks/selector/useIsMobile";
import {TourType} from "@SearchResults/features/tours/types/Tour.type";

type BuyTicketLabelProps = {
    route: PreparedRouteType | TourType,
}

function BuyTicketLabel({route}: BuyTicketLabelProps) {
    const buyTicketTranslation = useTranslation("search_results.buy_ticket")
    const buyTourTranslation = useTranslation("tour.buy_day_tour")
    const isMobile = useIsMobile()
    const {
        travelInfo: {
            departureStation: {
                cityName: departureStationName,
            },
            destinationStation: {
                cityName: destinationStationName,
            },
        },
        status: {
            isTour,
        },
    } = route

    const label = isTour ? buyTourTranslation : buyTicketTranslation
    const tourClassName = isTour ? "buy-tour-label" : ""

    return (
        <Column center className={tourClassName}>
            <span>
                {label}
            </span>
            {(route.status.isSuggestion || route.status.isIntermodal) && isMobile && (
                <small>
                    {`${departureStationName} - ${destinationStationName}`}
                </small>
            )}
        </Column>
    );
}

export default BuyTicketLabel;