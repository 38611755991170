import React, {MutableRefObject} from 'react';
import useTransportTypeAwareTranslation from "@Translation/hooks/useTransportTypeAwareTranslation";
import {capitalize} from "@String/capitalize";
import RouteWithAddedPropsAndPricesType from "@Route/types/RouteWithAddedPropsAndPrices.type";
import CompanyType from "@SearchResults/types/company/Company.type";
import MobileContentContainer from "@Route/features/mobile/components/MobileContentContainer";
import CompanyDetails from "@Route/features/company/components/CompanyDetails";
import {useTranslation} from "@Translation/hooks/useTranslation";

interface MobileCompanyDetailsProps {
    route: RouteWithAddedPropsAndPricesType,
    company: CompanyType,
    scrollRef: MutableRefObject<any>,
    isTour?: boolean
}

function MobileCompanyDetails({route, company, scrollRef, isTour = false}: MobileCompanyDetailsProps) {
    const companyType = useTransportTypeAwareTranslation('search_results.bus_company')
    const tourOperatorLabel = useTranslation("tour.operator")
    const title = isTour ? `${tourOperatorLabel} - ${company.name}` : `${capitalize(companyType)} - ${company.letterCode}`

    return (
        <MobileContentContainer title={title} scrollRef={scrollRef}>
            <CompanyDetails
                company={company}
                route={route}
            />
        </MobileContentContainer>
    );
}

export default MobileCompanyDetails;