import {useEffect} from "react";
import {setUser} from "@MyProfile/reducers/userProfileSlice";
import ajaxHandler from "@Ajax/v2/ajaxHandler";
import updateArrayMember from "@Array/updateArrayMember";
import useAsyncEffect from "@Hooks/promises/useAsyncEffect";
import {
    clearCheckOrderData,
    setActiveTicket,
    setCheckOrderData,
    setCheckOrderDataFromUser,
    setCheckOrderFatalError,
    setIsCheckOrder
} from "@CheckOrder/reducers/checkOrderSlice";
import {useAppSelector} from "@Hooks/selector/useAppSelector";
import {useAppDispatch} from "@Hooks/dispatch/useAppDispatch";
import CheckOrderResponseType from "@CheckOrder/types/CheckOrderResponse.type";
import PassengerType from "@CheckOrder/types/Passenger.type";
import UserType from "@CheckOrder/types/User.type";
import IntermodalRouteWithDetailsType from "@Intermodal/types/IntermodalRouteWithDetails.type";
import usePrepareIntermodalRoute from "@Intermodal/hooks/usePrepareIntermodalRoute";
import useCurrency from "@Currency/hooks/useCurrency";

export default function useGetCheckOrderData() {
    const {
        activeTicket,
        ticket,
        checkOrderRouteUrl,
        stripeClientSecret,
        stripePublicApiKey,
        stripeEnabled,
    } = useAppSelector((state) => state.checkOrder)

    // const currency = useCurrency()
    // const {prepareIntermodalRoute} = usePrepareIntermodalRoute()
    const dispatch = useAppDispatch()
    const userPassenger = useAppSelector((state) => state?.checkOrder.passengers[0])
    let checkOrderUrl = window.location.href.split('#')[0];
    // add time param to avoid browser caching
    if (checkOrderUrl.includes("?")) {
        checkOrderUrl = `${checkOrderUrl}&time=${new Date().getTime()}`;
    } else {
        checkOrderUrl = `${checkOrderUrl}?time=${new Date().getTime()}`;
    }

    const getCheckOrderData = async () => {
        try {
            const response = await ajaxHandler({
                url: checkOrderUrl,
                handle: "ignore",
            })
            if (response) {
                const {data: {checkOrder}} = response

                const {
                    purchasedRoute,
                    ticket,
                    passengers: passengersList,
                    user,
                } = checkOrder as CheckOrderResponseType

                let preparedIntermodalRoute: IntermodalRouteWithDetailsType;
                // if (ticket.isIntermodal) {
                //     preparedIntermodalRoute = prepareIntermodalRoute({
                //         passengerGroups: purchasedRoute.passengerGroups,
                //         currency,
                //         companies: purchasedRoute.companies,
                //         baseRoutes: purchasedRoute.singleRoutes,
                //         isIntermodalRoute: true,
                //         id: "",
                //     })
                // }
                // In case user data has already been resolved prior to useGetCheckOrderData method being ran
                if (userPassenger && !user) {
                    const passengers: PassengerType[] = updateArrayMember(passengersList, 0, '', userPassenger, true)
                    const mergedData: CheckOrderResponseType = {
                        ...checkOrder,
                        passengers,
                    }
                    dispatch(setCheckOrderData(mergedData))
                } else {
                    dispatch(setCheckOrderData(checkOrder))
                }
                if (user) {
                    const mergedUser: UserType = {...user, ...checkOrder.user}
                    dispatch(setUser(mergedUser))
                    dispatch(setCheckOrderDataFromUser(mergedUser))
                }
            } else {
                dispatch(setCheckOrderFatalError(true))
            }
        } catch (e) {
            dispatch(setCheckOrderFatalError(true))
            // eslint-disable-next-line no-console
            console.warn(e)
        }
    }

    const fetchTicketIfNoneExists = async () => {
        if (!ticket) {
            await getCheckOrderData()
        }
    }

    useAsyncEffect(fetchTicketIfNoneExists, [])

    const handleUnloadEvent = () => (() => {
        if (activeTicket) {
            localStorage.removeItem('activeTicket')
            dispatch(setActiveTicket(null))
        }
    })

    const checkForExistingCheckOrderSession = () => {
        const activeTicket = localStorage.getItem('activeTicket')
        if (activeTicket) {
            dispatch(setActiveTicket(activeTicket))
        } else {
            localStorage.setItem('activeTicket', window.location.href)
        }
    }

    useEffect(() => {
        dispatch(setIsCheckOrder(true))
        checkForExistingCheckOrderSession()
        window.scrollTo(0, 0)

        window.addEventListener('beforeunload', () => {
            handleUnloadEvent()
            sessionStorage.setItem('unloadEventOccurred', checkOrderRouteUrl)
        })

        return () => {
            dispatch(clearCheckOrderData())
            handleUnloadEvent()
            window.removeEventListener('beforeunload', handleUnloadEvent())
        }
    }, [])

    return {
        activeTicket,
        ticket,
        checkOrderRouteUrl,
        stripeClientSecret,
        stripePublicApiKey,
        stripeEnabled,
    }
}