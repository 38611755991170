import React from 'react'
import Card from "react-bootstrap/Card";
import Accordion from "react-bootstrap/Accordion";
import useIsMobile from "@Hooks/selector/useIsMobile";
import TourExpandedContainer from "@SearchResults/features/tours/components/TourExpandedContainer";
import ParamsType from "@SearchResults/types/params/Params.type";
import TourItineraryOverlay from "@SearchResults/features/tours/features/itinerary/TourItineraryOverlay";
import TourStatusContainer from "@SearchResults/features/tours/components/TourStatusContainer";
import TourOverview from "@SearchResults/features/tours/components/TourOverview";
import Row from "@Containers/components/Row";
import ImageWithOverlay from "@Generic/ImageWithOverlay";
import TourWithAddedPropsAndPricesType from "@SearchResults/features/tours/types/TourWithAddedPropsAndPrices.type";
import useTourDisplay from "@SearchResults/features/tours/hooks/useTourDisplay";
import ErrorBoundary from "@Errors/components/ErrorBoundary";
import ReadMoreToggle from "@SearchResults/features/tours/components/ReadMoreToggle";

interface TourItemProps {
    tour: TourWithAddedPropsAndPricesType,
    params: ParamsType,
    isReturn?: boolean,
    isActive: boolean,
    setActive: (arg0: number | null) => any
}

const Tour = (props: TourItemProps) => {
    const {
        tour,
        params,
        isReturn,
        isActive,
        setActive,
    } = props;

    const tourData = {
        ...tour,
        status: {
            ...tour.status,
            isTour: true,
        },
    }

    const isMobile = useIsMobile()

    const {
        tourInfo: {
            tourImageUrl,
        },
    } = tour

    const {
        isOverlayActive,
        openOverlay,
        closeOverlay,
        activateTour,
        elRef,
        tourId,
        routeExpandable,
    } = useTourDisplay({
        tour,
        isReturn,
        params,
        isActive,
        setActive,
    })

    return (
        <ErrorBoundary shouldReload={false}>
            <Card className={`panel gb--card gb--tour ${isActive ? 'gb--tour-active' : ''}`}>
                <Accordion.Toggle
                    ref={elRef}
                    onClick={activateTour}
                    as={Card.Header}
                    id={tourId}
                    eventKey={tour.key}
                    className="gb--tour-card-toggle"
                >
                    {isMobile && (
                        <div className="img-with-overlay-container">
                            <ImageWithOverlay
                                imageUrl={tourImageUrl}
                                imgClass="gb--tour-image-custom "
                                customOverlayClass="gb--tour-image-overlay"
                            />
                        </div>
                    )}
                    <Row
                        gap
                        justify
                        className="gb--tour-card"
                    >
                        <TourOverview tour={tour}/>
                        <TourStatusContainer
                            route={tourData}
                            isReturn={isReturn}
                            params={params}
                        />
                    </Row>
                    {!isMobile && (
                        <ReadMoreToggle
                            isActive={isActive}
                            routeExpandable={routeExpandable}
                        />
                    )}
                </Accordion.Toggle>
                {isMobile && (
                    <ReadMoreToggle
                        isActive={isOverlayActive}
                        routeExpandable={routeExpandable}
                        onClick={openOverlay}
                    />
                )}
                {!isMobile && routeExpandable && (
                    <Accordion.Collapse eventKey={tour.key}>
                        <Card.Body className="gb--route-details gb--tour-details">
                            <TourExpandedContainer
                                tour={tourData}
                                params={params}
                                isReturn={isReturn}
                            />
                        </Card.Body>
                    </Accordion.Collapse>
                )}
            </Card>
            {isMobile && isOverlayActive && routeExpandable && (
                <TourItineraryOverlay
                    tour={tour}
                    closeOverlay={closeOverlay}
                />
            )}
        </ErrorBoundary>
    );
}

export default Tour