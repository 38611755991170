import React, {useState} from 'react'
import Accordion from "react-bootstrap/Accordion";
import Tour from "@SearchResults/features/tours/components/Tour";
import usePassengers from "@Hooks/selector/usePassengers";
import ParamsType from "@SearchResults/types/params/Params.type";
import PassengerGroupType from "@SearchResults/types/passenger/PassengerGroup.type";
import CompanyType from "@SearchResults/types/company/Company.type";
import {PreparedRouteType} from "@Route/types/PreparedRoute.type";
import usePrepareAndSortRoutes from "@RoutesList/hooks/usePrepareAndSortRoutes";
import {TourType} from "@SearchResults/features/tours/types/Tour.type";
import ErrorBoundary from "@Errors/components/ErrorBoundary";
import useFilterToursByPagePosition from "@SearchResults/features/tours/hooks/useFilterToursByPagePosition";
import RouteType from "@Route/types/Route.type";

interface ToursListProps {
    tours: TourType[],
    routes: RouteType[],
    params: ParamsType,
    isReturn?: boolean,
    isPastDeparture?: boolean,
    passengerGroups: Record<string, PassengerGroupType>
    companies: Record<string, CompanyType>,
    singleSelectedRoute?: PreparedRouteType,
    position?: string,
}

const ToursList = (props: ToursListProps) => {
    const [activeKey, setActiveKey] = useState(null)
    const passengers = usePassengers()
    const {
        params,
        isReturn,
        tours,
        isPastDeparture = false,
        passengerGroups,
        companies,
        singleSelectedRoute,
        position,
        routes,
    } = props;

    const {
        preparedAndSortedTours,
    } = usePrepareAndSortRoutes({
        intermodalRoutes: [],
        companies,
        passengerGroups,
        routes,
        isCurrentRouteSingle: !isReturn,
        isPastDeparture,
        params,
        singleSelectedRoute,
        tours,
    })

    const {toursTop, toursBottom} = useFilterToursByPagePosition(preparedAndSortedTours)

    const toursList = position === "bottom" ? toursBottom : toursTop

    return (
        <ErrorBoundary shouldReload={false}>
            <Accordion className="panel-group routes-list" onSelect={setActiveKey}>
                {toursList.map((tour) => (
                    <Tour
                        isActive={tour.key === activeKey}
                        key={tour.key}
                        tour={tour}
                        params={params}
                        isReturn={isReturn}
                        totalPassengers={passengers.passengers}
                        setActive={setActiveKey}
                    />
                ))}
            </Accordion>
        </ErrorBoundary>
    )
}

export default ToursList