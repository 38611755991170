import React, {useState} from 'react'
import Row from "@Containers/components/Row";
import Column from "@Containers/components/Column";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import {TourType} from "@SearchResults/features/tours/types/Tour.type";
import RouteWithAddedPropsAndPricesType from "@Route/types/RouteWithAddedPropsAndPrices.type";
import ItineraryRow from "@SearchResults/features/tours/features/itinerary/ItineraryRow";
import ItineraryTimestamp from "@SearchResults/features/tours/features/itinerary/ItineraryTimestamp";
import {useTranslation} from "@Translation/hooks/useTranslation";
import ItineraryStops from "@SearchResults/features/tours/features/itinerary/ItineraryStops";
import StationsToggle from "@SearchResults/features/tours/features/itinerary/StationsToggle";
import useTourStations from "@SearchResults/features/tours/hooks/useTourStations";

interface TourItineraryProps {
    tour: TourType | RouteWithAddedPropsAndPricesType,
    className?: string
}

const TourItinerary = ({tour, className = ""}: TourItineraryProps) => {
    const {
        travelInfo: {
            departureStation: {
                name: departureStationName,
                departureTime,
                departureDate,
            },
            destinationStation: {
                name: destinationStationName,
                arrivalTime,
                arrivalDate,
            },
            stations,
            travelDuration,
        },
    } = tour || {};

    const tourItineraryLabel = useTranslation("tour.itinerary")

    const {
        stops,
        isToggle,
        toggleStation,
        firstStation,
        lastStation,
        expandedStops,
    } = useTourStations({stations})

    const [isExpanded, setIsExpanded] = useState(false)

    return (
        <div className={`gb--tour-stations-container ${className}`}>
            <ItineraryRow
                stationName={departureStationName}
                stationDescription={firstStation?.stationDescription}
                circleColor="green"
                lineClassName="first"
            >
                <ItineraryTimestamp
                    date={departureDate}
                    time={departureTime}
                />
            </ItineraryRow>
            {isToggle && (
                <Accordion>
                    <>
                        {toggleStation && (
                            <Row className="tour-itinerary-row">
                                <Column alignEnd className="tour-itinerary-info-data">
                                <span className="gb--emphasize-default gb--truncate tour-title-label">
                                        {tourItineraryLabel}
                                </span>
                                    <small>{travelDuration}</small>
                                </Column>
                                <Accordion.Toggle
                                    className="gb--row gb--row-middle gb--row-justify tour-itinerary-row"
                                    as={Card.Header}
                                    eventKey="expand-stations"
                                    onClick={() => setIsExpanded(!isExpanded)}
                                >
                                    <StationsToggle
                                        name={toggleStation.name}
                                        description={toggleStation.stationDescription}
                                        isExpanded={isExpanded}
                                    />
                                </Accordion.Toggle>
                            </Row>
                        )}
                        <Accordion.Collapse
                            eventKey="expand-stations"
                        >
                            <ItineraryStops stations={expandedStops}/>
                        </Accordion.Collapse>
                    </>
                </Accordion>
            )}
            {!isToggle && (
                <>
                    {stops.map((stop) => (
                        <ItineraryRow
                            stationName={stop.name}
                            stationDescription={stop.stationDescription}
                        />
                    ))}
                </>
            )}
            <ItineraryRow
                stationName={departureStationName}
                stationDescription={lastStation?.stationDescription}
                circleColor="orange"
                lineClassName="last"

            >
                <ItineraryTimestamp
                    date={arrivalDate}
                    time={arrivalTime}
                />
            </ItineraryRow>
        </div>
    )
}

export default TourItinerary