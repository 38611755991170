import useRedirectUrlGenerator from "@Route/hooks/useRedirectUrlGenerator";
import useRouteRedirectToPartnerEvent from "@Events/hooks/useRouteRedirectToPartnerEvent";
import useSingleSelectedRouteRedirectToPartnerEvent from "@Events/hooks/useSingleSelectedRouteRedirectToPartnerEvent";
import useRedirectInNewTab from "@Route/hooks/useRedirectInNewTab";
import RouteWithAddedPropsAndPricesType from "@Route/types/RouteWithAddedPropsAndPrices.type";
import useSingleSelectedRoute from "@Route/hooks/useSingleSelectedRoute";
import ParamsType from "@SearchResults/types/params/Params.type";
import {TourType} from "@SearchResults/features/tours/types/Tour.type";

export default function useRedirectToPartner(route: RouteWithAddedPropsAndPricesType | TourType, params: ParamsType) {
    const {
        generateRedirectUrlPrams,
        isReturn,
    } = useRedirectUrlGenerator(params)

    const singleSelectedRoute = useSingleSelectedRoute()
    const redirectInSameTab = useRedirectInNewTab(route, isReturn)
    const singleSelectedRouteRedirectToPartnerEvent = useSingleSelectedRouteRedirectToPartnerEvent(route, params)
    const routeRedirectToPartnerEvent = useRouteRedirectToPartnerEvent({
        route,
        isReturn,
        params,
    })

    const redirect = (route: RouteWithAddedPropsAndPricesType, newTab: boolean) => {
        const {
            redirectUrl,
            partner,
        } = generateRedirectUrlPrams(route)

        // dataLayer is created by google tag manager in base.html.twig and it's available everywhere
        window.dataLayer.push({
            event: 'trackEvent',
            eventCategory: 'Partner',
            eventAction: 'Buy',
            eventLabel: partner,
        });

        return newTab
            ? setTimeout(() => {
                window.open(redirectUrl, '_blank');
            }, 100)
            // eslint-disable-next-line no-return-assign
            : setTimeout(() => window.location.href = redirectUrl, 100)
    }

    const redirectToPartner = async () => {
        // Event for single or return, depending on params
        await routeRedirectToPartnerEvent()

        if (isReturn) {
            // Event for single route
            await singleSelectedRouteRedirectToPartnerEvent()
            redirect(singleSelectedRoute, false)
        }

        redirect(route, redirectInSameTab)
    }

    return {
        redirectToPartner,
    }
}