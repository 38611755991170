import ajaxHandler from "@Ajax/v2/ajaxHandler";
import getCookie from "@Cookies/functions/getCookie";
import generateIntermodalCheckOrderData from "@Intermodal/functions/generateIntermodalCheckOrderData";
import IntermodalRouteWithDetailsType from "@Intermodal/types/IntermodalRouteWithDetails.type";
import RouteWithAddedPropsAndPricesType from "@Route/types/RouteWithAddedPropsAndPrices.type";
import {isIntermodalRoute} from "@SearchResults/functions/isIntermodalRoute";
import RouteType from "@Route/types/Route.type";
import {TourType} from "@SearchResults/features/tours/types/Tour.type";
import {travelierConnect} from "@/constants/ApiProviders";

/**
 * @param route {object}
 * @param singleSelectedRoute {object}
 * @param isReturnSearch {boolean}
 * @param date {string}
 * @param returnDate {string}
 * @param passengers {object}
 * @param experiments {string}
 * @return {Promise<any|boolean>}
 */

interface BuyTicketAttributes {
    route: IntermodalRouteWithDetailsType | RouteWithAddedPropsAndPricesType | TourType,
    singleSelectedRoute: RouteType,
    isReturnSearch: boolean,
    date: string,
    returnDate: string,
    passengers: any,
    experiments: string,
}

export default async function buyTicket(props: BuyTicketAttributes) {
    const {
        route,
        singleSelectedRoute,
        isReturnSearch,
        date,
        returnDate,
        passengers,
        experiments,
    } = props

    const {
        passengersAdult,
        passengersChildren,
        passengersPensioner,
        passengersStudent,
    } = passengers

    const singleRoute = () => (isReturnSearch ? singleSelectedRoute : route)

    let postParams = {}
    postParams = {
        ticket_type_id: isReturnSearch ? 2 : 1,
        departure_station_id: singleRoute().travelInfo.departureStation.id,
        destination_station_id: singleRoute().travelInfo.destinationStation.id,
        single_route_id: singleRoute().id,
        adults: passengersAdult,
        children: passengersChildren,
        students: passengersStudent,
        pensioners: passengersPensioner,
        sold_via_suggestion: route.status?.isSuggestion ? 1 : 0,
        single_route_date: date,
        visitorId: getCookie("visitor_id", false),
        experiments,
    };

    if (isIntermodalRoute(route) && route.status.providerId !== travelierConnect) {
        postParams = {
            ...postParams,
            ...generateIntermodalCheckOrderData(route),
        }
    }

    const integrationData = (route) => route.status?.jsonEncodedApiFullIntegrationData

    if (isReturnSearch) {
        postParams = {
            ...postParams,
            return_route_id: route.id,
            return_route_date: returnDate,
        }
        if (integrationData(singleSelectedRoute)) {
            postParams = {
                ...postParams,
                single_json_encoded_api_full_integration_data: integrationData(singleSelectedRoute),
                seatClass: singleSelectedRoute.travelInfo.seatClass?.seatClassId ?? null,
            }
        }
        if (integrationData(route)) {
            postParams = {
                ...postParams,
                return_json_encoded_api_full_integration_data: integrationData(route),
                seatClass: route.travelInfo.seatClass?.seatClassId ?? null,
            }
        }
    } else if (integrationData(route)) {
        postParams = {
            ...postParams,
            single_json_encoded_api_full_integration_data: integrationData(route),
            seatClass: route.travelInfo.seatClass?.seatClassId ?? null,
        }
    }

    const response = await ajaxHandler({
        url: "/check-order",
        type: "post",
        params: postParams,
        stringify: true,
    })

    return response.data ?? false
}