import React, {useEffect} from 'react';
import useCurrency from "@Currency/hooks/useCurrency";
import {useTranslation} from "@Translation/hooks/useTranslation";
import {useAppDispatch} from "@Hooks/dispatch/useAppDispatch";
import {setFilterPrice} from "@SearchResults/reducers/filterSlice";
import FilterSlider from "@SearchResults/features/sidebar/components/FilterSlider";
import useFilterSliderValue from "@SearchResults/features/sidebar/hooks/useFilterSliderValue";
import FilterSliderLabels from "@SearchResults/features/sidebar/components/FilterSliderLabels";

function PriceFilter() {
    const dispatch = useAppDispatch()
    const {sign} = useCurrency()
    const {
        value,
        updateValue,
        max,
        min,
        setValueState,
    } = useFilterSliderValue({
        onChange: setFilterPrice,
        boundValue: "Price",
    })

    useEffect(() => {
        dispatch(setFilterPrice(null))
    }, [sign])

    const displayValue = value ? `${value} ${sign}` : `${max} ${sign}`

    return (
        <FilterSlider
            onChange={setValueState}
            value={value}
            min={min}
            max={max}
            onRelease={updateValue}
        >
            <FilterSliderLabels
                label={useTranslation("search_results.filters.less_than")}
                value={displayValue}
            />
        </FilterSlider>
    );
}

export default PriceFilter;