import React from 'react';
import Row from "@Containers/components/Row";
import usePassengers from "@Hooks/selector/usePassengers";
import {useTranslation} from "@Translation/hooks/useTranslation";
import EuroConversionNotice from "@Currency/components/EuroConversionNotice";
import {getYear} from "date-fns";
import IntermodalRouteWithDetailsType from "@Intermodal/types/IntermodalRouteWithDetails.type";
import RouteWithAddedPropsAndPricesType from "@Route/types/RouteWithAddedPropsAndPrices.type";
import GroupPrices from "@Route/components/Price/PassengerGroup/GroupPrices";
import PriceWithCurrency from "@Route/components/Price/PriceWithCurrency";
import {TourType} from "@SearchResults/features/tours/types/Tour.type";

interface PriceDetailsProps {
    route: IntermodalRouteWithDetailsType | RouteWithAddedPropsAndPricesType | TourType,
    totalPrice: number,
}

function PriceDetails({route, totalPrice}: PriceDetailsProps) {
    const {passengers} = usePassengers()
    const {
        total: totalLabel,
        passengers: passengersLabel,
        charged_in: currencyDisclaimer,
        prices_calculated: exchangeRateDisclaimer,
    } = useTranslation("sys")

    return (
        <>
            {!route.type.isPrivate && (
                <>
                    <GroupPrices
                        route={route}
                    />
                    <hr/>
                </>
            )}
            <Row justify className="gb--emphasize-default">
                <span>
                    {`${totalLabel} (${passengers} ${passengersLabel})*`}
                </span>
                <PriceWithCurrency
                    linebreak={false}
                    price={totalPrice}
                />
            </Row>
            <div>
                {(getYear(new Date()) < 2023) && (
                    <>
                        <span className="gb--emphasize-default">*</span>
                        {currencyDisclaimer}
                        <br/>
                    </>
                )}
                <span className="gb--emphasize-default">*</span>
                {exchangeRateDisclaimer}
                <EuroConversionNotice
                    price={totalPrice}
                />
            </div>
        </>
    )
}

export default PriceDetails;