import isBefore from "date-fns/isBefore";
import {calculateTravelTime} from "@Route/functions/calculateTravelTime";
import getRouteDateTime from "@Route/functions/getRouteDateTime";
import {addCondition, filterArrayByConditions} from "@Array/filterArrayByConditions";
import {useAppSelector} from "@Hooks/selector/useAppSelector";
import RouteWithAddedPropsAndPricesType from "@Route/types/RouteWithAddedPropsAndPrices.type";
import IntermodalRouteWithDetailsType from "@Intermodal/types/IntermodalRouteWithDetails.type";

export default function useRouteFilters() {
    const {
        filterDuration,
        filterPrice,
        filterRating,
        filterRouteArrivalTime,
        filterRouteDepartureTime,
        filterRouteChange,
    } = useAppSelector((state) => state?.filter)

    // @ts-ignore
    const filterByRating = (route: RouteWithAddedPropsAndPricesType) => (parseFloat(route.ratings.rating) * 10 >= parseInt(filterRating, 10))
    const filterByPrice = (route: RouteWithAddedPropsAndPricesType) => (Math.round(route.prices.price) <= filterPrice)
    const filterByDuration = (route: RouteWithAddedPropsAndPricesType) => (calculateTravelTime(route) <= filterDuration)

    const filterByArriveBefore = (route: RouteWithAddedPropsAndPricesType) => {
        // - 1 to consider equal times as "isBefore"
        const arrivalTime = Date.parse(getRouteDateTime({
            route,
            type: "arrival",
        })) - 1
        // @ts-ignore
        const arriveBefore = new Date(parseInt(filterRouteArrivalTime, 10))

        return isBefore(arrivalTime, arriveBefore)
    }

    const filterByDepartAfter = (route: RouteWithAddedPropsAndPricesType) => {
        const departureTime = Date.parse(getRouteDateTime({
            route,
            type: "departure",
        }))
        // @ts-ignore
        const departAfter = new Date(parseInt(filterRouteDepartureTime, 10))

        return !isBefore(departureTime, departAfter)
    }

    const filterAll = (routes: (RouteWithAddedPropsAndPricesType | IntermodalRouteWithDetailsType)[]) => (
        filterArrayByConditions(routes, [
            addCondition(filterPrice, filterByPrice),
            addCondition(filterDuration, filterByDuration),
            addCondition(filterRating, filterByRating),
            addCondition(filterRouteArrivalTime, filterByArriveBefore),
            addCondition(filterRouteDepartureTime, filterByDepartAfter),
        ])
    )

    const filterRoutes = (routes: (RouteWithAddedPropsAndPricesType | IntermodalRouteWithDetailsType)[]) => {
        return filterAll(routes)
    }

    return {
        filterRoutes,
    }
}