import React from 'react'
import TourDescriptionItem from "@SearchResults/features/tours/components/TourDescriptionItem";
import useTourDescription from "@SearchResults/features/tours/hooks/useTourDescription";
import Row from "@Containers/components/Row";

interface TourDescriptionProps {
    description: string,
    className?: string
}

const TourDescription = ({description, className = ""}: TourDescriptionProps) => {
    const formattedDescription = useTourDescription(description)

    if (formattedDescription.length === 0) {
        return (
            <></>
        )
    }

    return (
        <>
            <hr/>
            <Row gap className={`gb--tour-description-container ${className}`}>
                {formattedDescription.map((data) => (
                    <TourDescriptionItem
                        data={data}
                        key={data.title}
                    />
                ))}
            </Row>
        </>
    )
}

export default TourDescription