import IntermodalRouteWithDetailsType from "@Intermodal/types/IntermodalRouteWithDetails.type";
import RouteWithAddedPropsAndPricesType from "@Route/types/RouteWithAddedPropsAndPrices.type";
import {useAppSelector} from "@Hooks/selector/useAppSelector";
import useIsMobile from "@Hooks/selector/useIsMobile";
import {useParams} from "react-router";
import {TourType} from "@SearchResults/features/tours/types/Tour.type";

/**
 * Returns prepared route status for other hooks & components to consume
 */
interface UsePrepareRouteAttributes {
    route: IntermodalRouteWithDetailsType | RouteWithAddedPropsAndPricesType | TourType,
    isCurrentRouteSingle: boolean,
    isReturn: boolean,
    isActive: boolean,
}

export default function usePrepareRoute({route, isCurrentRouteSingle, isReturn, isActive}: UsePrepareRouteAttributes) {
    const {routeId} = useParams()
    const {
        status: {
            status,
        },
        companyId,
    } = route

    const {
        excludedApiPartnerIds,
    } = useAppSelector((state) => state.searchResults)

    const isMobile = useIsMobile()

    const {status: {locked, isSuggestion, soldOut, isJadrolinijaStateLine}} = route
    const excludedByCompany = excludedApiPartnerIds.includes(companyId) && routeId !== route.id
    const isRouteExcluded = () => (isSuggestion ? false : (excludedByCompany || isJadrolinijaStateLine))
    const isRouteOpen = () => status === 'open'
    const routeOpen = isRouteOpen()

    const hideRoutesThatAreNotExpandedOnReturnTrips = () => (
        isCurrentRouteSingle && (isReturn || isMobile)
        && !!routeId
        && route.id !== routeId
    )

    const isActiveClassName = isActive ? 'gb--card-expanded' : ''

    const setPanelClassName = () => (
        status === 'unavailable' || soldOut ? ' gb--route-unavailable' : isActiveClassName
    )

    return {
        routeExpandable: routeOpen && !soldOut,
        routeId: route.id,
        routeExcluded: isRouteExcluded(),
        routeHiddenOnReturnTrip: hideRoutesThatAreNotExpandedOnReturnTrips(),
        routeOpen,
        panelClassName: setPanelClassName(),
    }
}