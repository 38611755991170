import React, {useContext} from 'react';
import RouteMobileAdditionalInfo from "@Route/features/mobile/components/RouteMobileAdditionalInfo";
import StickyContainerAvoidingDiv from "@Containers/components/StickyContainerAvoidingDiv";
import IntermodalAdditionalInfo from "@Intermodal/components/mobile/IntermodalAdditionalInfo";
import RouteType from "@Route/types/Route.type";
import ParamsType from "@SearchResults/types/params/Params.type";
import CompanyType from "@SearchResults/types/company/Company.type";
import {ActiveTicketContext} from "@Route/features/mobile/context/ActiveTicketContext";
import PassengerGroupType from "@SearchResults/types/passenger/PassengerGroup.type";
import IntermodalRouteType from "@Intermodal/types/IntermodalRoute.type";
import {isIntermodalRoute} from "@SearchResults/functions/isIntermodalRoute";
import {isSuggestedRoute} from "@SearchResults/functions/isSuggestedRoute";
import {isTourRoute} from "@SearchResults/functions/isTourRoute";
import TourAdditionalInfo from "@SearchResults/features/tours/features/additionalInfo/TourAdditionalInfo";
import {TourType} from "@SearchResults/features/tours/types/Tour.type";

type AdditionalInfoContainerProps = {
    singleSelectedRoute: RouteType | IntermodalRouteType | TourType,
    params: ParamsType,
    companies: Record<number, CompanyType>,
    passengerGroups: Record<number, PassengerGroupType>,
    isReturn: boolean,
}

function AdditionalInfoContainer(props: AdditionalInfoContainerProps) {
    const {
        singleSelectedRoute,
        params,
        companies,
        passengerGroups,
        isReturn,
    } = props;

    const activeTicketSections = useContext(ActiveTicketContext)

    if (isTourRoute(singleSelectedRoute)) {
        return (
            <StickyContainerAvoidingDiv
                className="mobile-additional-info-container"
                heightCorrection={3}
                // style={style}
            >
                <TourAdditionalInfo
                    tour={singleSelectedRoute}
                    params={params}
                    companies={companies}
                    passengerGroups={passengerGroups}
                    isReturn={isReturn}
                    activeTicketSections={activeTicketSections}
                />
            </StickyContainerAvoidingDiv>
        )
    }

    return (
        <StickyContainerAvoidingDiv
            className="mobile-additional-info-container"
            heightCorrection={3}
            // style={style}
        >
            {isIntermodalRoute(singleSelectedRoute) || isSuggestedRoute(singleSelectedRoute) ? (
                <IntermodalAdditionalInfo
                    companies={companies}
                    passengerGroups={passengerGroups}
                    route={singleSelectedRoute}
                    params={params}
                    isReturn={isReturn}
                />
            ) : (
                <RouteMobileAdditionalInfo
                    // @ts-ignore
                    activeTicketSections={activeTicketSections}
                    params={params}
                    companies={companies}
                    passengerGroups={passengerGroups}
                    route={singleSelectedRoute}
                    isReturn={isReturn}
                />
            )}

        </StickyContainerAvoidingDiv>
    );
}

export default AdditionalInfoContainer;