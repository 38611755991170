import React from 'react';
import Column from "@Containers/components/Column";
import useRouteActivePassengerGroups from "pages/searchResults/features/route/hooks/useRouteActivePassengerGroups";
import RouteWithAddedPropsAndPricesType from "@Route/types/RouteWithAddedPropsAndPrices.type";
import ActivePassengerGroupPrice from "@Route/components/Price/PassengerGroup/ActivePassengerGroupPrice";
import {TourType} from "@SearchResults/features/tours/types/Tour.type";

interface RoutePriceBreakdownSectionProps {
    route: RouteWithAddedPropsAndPricesType | TourType,
}

function RoutePriceBreakdownSection({route}: RoutePriceBreakdownSectionProps) {
    const activePassengerGroupsWithPrices = useRouteActivePassengerGroups(route)

    return (
        <Column alignEnd className="gb--emphasize-default price-breakdown">
            {activePassengerGroupsWithPrices.map((group) => (
                <ActivePassengerGroupPrice
                    key={group.id}
                    group={group}
                    showPrice={!route.status.isIntermodal}
                />
            ))}
        </Column>
    );
}

export default RoutePriceBreakdownSection;