import StationWithDateTimesType from "@SearchResults/types/station/StationWithDateTimes.type";

interface UseTourStationsProps {
    stations: StationWithDateTimesType[],
}

export default function useTourStations({stations}: UseTourStationsProps) {
    const firstStation = stations[0]

    const lastStation = {
        ...stations[stations.length - 1],
        name: firstStation.name,
        stationDescription: "",
    }

    // stations without departure station
    const stops = stations.filter((station, index) => {
        return !station.doNotDisplayOnSearchResults && index !== 0
    })

    const expandedStops = stops.slice(1, stops.length)

    const isToggle = stops.length > 1

    const toggleStation = isToggle ? stops[0] : null

    return {
        stops,
        isToggle,
        toggleStation,
        firstStation,
        lastStation,
        expandedStops: isToggle ? expandedStops : [],
    }
}