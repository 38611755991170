import React from 'react';
import GenericPopover from "@Popover/components/GenericPopover";
import IntermodalRouteWithDetailsType from "@Intermodal/types/IntermodalRouteWithDetails.type";
import RouteWithAddedPropsAndPricesType from "@Route/types/RouteWithAddedPropsAndPrices.type";
import {isIntermodalRoute} from "@SearchResults/functions/isIntermodalRoute";
import PriceDetails from "@Route/components/Price/PriceDetails";
import RouteTotalPrice from "@Route/components/Price/RouteTotalPrice";
import {TourType} from "@SearchResults/features/tours/types/Tour.type";
import {isTourRoute} from "@SearchResults/functions/isTourRoute";
import useIsPassengerCountBeingUpdatedForFlixbusPrice
    from "@SearchResults/api/hooks/useIsPassengerCountBeingUpdatedForFlixbusPrice"
import {isEmpty} from "@Array/isEmpty";
import {PreparedRouteType} from "@Route/types/PreparedRoute.type";
import min from "lodash/min";

interface RouteTotalPriceContainerProps {
    route: IntermodalRouteWithDetailsType | RouteWithAddedPropsAndPricesType | TourType,
    totalPrice: number,
    className?: string
}

const RouteTotalPriceContainer = ({route, totalPrice, className}: RouteTotalPriceContainerProps) => {
    const {shouldDisplayPlaceholder} = useIsPassengerCountBeingUpdatedForFlixbusPrice()
    const displayFromNotice = !isEmpty(route?.passengerClasses)
    const findCheapestPassengerClass = (passengerClasses: RouteWithAddedPropsAndPricesType[]) => {
        const prices = passengerClasses.map((passengerClass) => (passengerClass.prices.price))
        return min(prices)
    }

    const cheapestPassengerClass = displayFromNotice ? findCheapestPassengerClass(route?.passengerClasses) : null

    if (shouldDisplayPlaceholder(route)) {
        return (
            <div>
                <RouteTotalPrice
                    displayFromNotice={displayFromNotice}
                    price={cheapestPassengerClass ?? totalPrice}
                    loading
                    className={className}
                />
            </div>
        )
    }
    if (isIntermodalRoute(route) || route.status.isApi) {
        return (
            <div>
                <RouteTotalPrice
                    displayFromNotice={displayFromNotice}
                    price={totalPrice}
                    className={className}
                />
            </div>
        )
    }

    return (
        <GenericPopover
            id={route.id}
            overlay={(
                <PriceDetails
                    totalPrice={cheapestPassengerClass ?? totalPrice}
                    route={route}
                />
            )}
            trigger={(
                <RouteTotalPrice
                    displayFromNotice={displayFromNotice}
                    displayPassengers={!route.type.isPrivate && !isTourRoute(route)}
                    price={cheapestPassengerClass ?? totalPrice}
                    className={className}
                    displayPerPerson={isTourRoute(route)}
                />
            )}
        />
    );
}

export default RouteTotalPriceContainer;