import React from 'react';
import useQuantityAwareTranslation from "../hooks/useQuantityAwareTranslation";

interface QuantityAwareTranslationProps {
    translationKey: string,
    quantity: number,
}

function QuantityAwareTranslation({translationKey, quantity}: QuantityAwareTranslationProps) {
    const translation = useQuantityAwareTranslation(translationKey, quantity)
    return (
        <>
            {translation}
        </>
    )
}

export default QuantityAwareTranslation;