import React, {ReactNode, useState} from 'react';
import GenericCheckbox from "@Generic/Checkbox/GenericCheckbox";

interface CheckboxProps {
    children?: ReactNode,
    onChange: (any?) => any,
    isChecked?: boolean,
    disableChange?: boolean,
    className?: string,
}

function Checkbox({children, onChange, isChecked = false, disableChange = false, className = ""}: CheckboxProps) {
    const [checked, setChecked] = useState(isChecked)
    const onClick = () => {
        if (!disableChange) {
            setChecked(!checked);
            onChange()
        }
    }

    return (
        <GenericCheckbox
            className={className}
            checked={checked}
            onClick={onClick}
        >
            {children}
        </GenericCheckbox>
    );
}

export default Checkbox;