import {TourType} from "@SearchResults/features/tours/types/Tour.type";

export default function useFilterToursByPagePosition(toursData: TourType[]) {
    const toursTop = []
    const toursBottom = []
    toursData.map((tour) => {
        if (tour.pagePosition === 'bottom') {
            toursBottom.push(tour)
        } else {
            toursTop.push(tour)
        }
    })

    return {
        toursTop,
        toursBottom,
    }
}