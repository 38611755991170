import React from 'react';
import IntermodalRouteWithDetailsType from "@Intermodal/types/IntermodalRouteWithDetails.type";
import RouteWithAddedPropsAndPricesType from "@Route/types/RouteWithAddedPropsAndPrices.type";
import {isIntermodalRoute} from "@SearchResults/functions/isIntermodalRoute";
import ConfirmButton from "@Route/features/ticketPurchase/components/ConfirmButton";
import RedirectButton from "@Route/features/ticketPurchase/components/RedirectButton";
import BuyButton from "@Route/features/ticketPurchase/components/BuyButton";
import SuggestionButton from "@Route/features/ticketPurchase/components/SuggestionButton";
import ParamsType from "@SearchResults/types/params/Params.type";
import {TourType} from "@SearchResults/features/tours/types/Tour.type";
import {isTourRoute} from "@SearchResults/functions/isTourRoute";

interface RouteActionButtonProps {
    route: IntermodalRouteWithDetailsType | RouteWithAddedPropsAndPricesType | TourType,
    expandedPanel?: boolean,
    isReturn: boolean,
    hideLabels?: boolean,
    params: ParamsType,
    className?: string
}

function RouteActionButton(props: RouteActionButtonProps) {
    const {
        expandedPanel = true,
        route,
        isReturn,
        hideLabels,
        className,
        params,
    } = props;

    const {
        status: {
            isApi,
            isSuggestion,
            hasRedirectLink,
        },
    } = route

    const hasPassengerClasses = route?.passengerClasses?.length > 0

    if (!isReturn && params.isReturn) {
        return (
            <div className="gb--route-element route--select">
                <ConfirmButton
                    route={route as RouteWithAddedPropsAndPricesType}
                    isApi={isApi}
                />
            </div>
        );
    }

    if (isSuggestion && !expandedPanel) {
        return (
            <div className="gb--route-element route--select">
                <SuggestionButton/>
            </div>
        );
    }
    // TODO Remove-redirect-attribute-from-intermodal-status-so-that-this-is-never-rendered-for-intermodal
    if (isApi && hasRedirectLink && !isIntermodalRoute(route)) {
        return (
            <div className="gb--route-element route--select">
                <RedirectButton
                    params={params}
                    hideLabels={hideLabels}
                    route={route}
                />
            </div>
        );
    }

    return (
        <div className={`${className ?? "gb--route-element"} route--select`}>
            <BuyButton
                params={params}
                route={route}
            />
        </div>
    )
}

export default RouteActionButton