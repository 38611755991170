import React from 'react';
import GenericSvgIcon from "@Icons/GenericSvgIcon";
import IconType from "@/types/Icon.type";

function IconMoney(props: IconType) {
    const {
        width = '16',
        height = '16',
        fill = '#fff',
        viewBox = '0 0 16 16',
        style = {verticalAlign: 'middle'},
        title = 'IconMoney',
        ...rest
    } = props

    return (
        <GenericSvgIcon
            width={width}
            height={height}
            fill={fill}
            viewBox={viewBox}
            style={style}
            title={title}
            {...rest}
            svgIconPath={(
                <path
                    d="m1.71428571 2c-.94196457 0-1.71428571.77232114-1.71428571 1.71428571v5.71428572c0 .94196457.77232114 1.71428567 1.71428571 1.71428567h12.57142859c.9419646 0 1.7142857-.7723211 1.7142857-1.71428567v-5.71428572c0-.94196457-.7723211-1.71428571-1.7142857-1.71428571zm.57142858 1.14285714h11.42857141c0 .625.5178572 1.14285715 1.1428572 1.14285715v4.57142857c-.625 0-1.1428572.51785714-1.1428572 1.14285714h-11.42857141c0-.625-.51785715-1.14285714-1.14285715-1.14285714v-4.57142857c.625 0 1.14285715-.51785715 1.14285715-1.14285715zm5.71428571 1.14285715c-1.254464 0-2.28571429 1.03125028-2.28571429 2.28571428s1.03125029 2.28571429 2.28571429 2.28571429 2.2857143-1.03125029 2.2857143-2.28571429-1.0312503-2.28571428-2.2857143-2.28571428zm-4.57142857 1.14285714c-.629464 0-1.14285714.51339314-1.14285714 1.14285714s.51339314 1.14285714 1.14285714 1.14285714 1.14285714-.51339314 1.14285714-1.14285714-.51339314-1.14285714-1.14285714-1.14285714zm4.57142857 0c.63839314 0 1.14285714.504464 1.14285714 1.14285714s-.504464 1.14285714-1.14285714 1.14285714-1.14285714-.504464-1.14285714-1.14285714.504464-1.14285714 1.14285714-1.14285714zm4.5714286 0c-.629464 0-1.1428572.51339314-1.1428572 1.14285714s.5133932 1.14285714 1.1428572 1.14285714 1.1428571-.51339314 1.1428571-1.14285714-.5133931-1.14285714-1.1428571-1.14285714zm-11.42857146 6.85714287c.23660686.6651783.866072 1.1428571 1.60714286 1.1428571h1.22321486c1.883928 0 3.78571428.1473212 5.64732114.4330355l3.258928.5c.0892857.0133931.1785714.0223222.2633931.0223222.4062503 0 .8035715-.1428571 1.116072-.4151794.379464-.3258926.5982138-.7991063.5982138-1.3035714v-.379464h-1.2857143c-.5803566 0-1.0267852.370536-1.1071429.8571428l-2.66964227-.4107142c-1.91964343-.2946423-3.87946514-.4464286-5.82142857-.4464286z"
                />
            )}
        />

    );
}

export default IconMoney;
