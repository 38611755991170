import {useAppDispatch} from "@Hooks/dispatch/useAppDispatch";
import {updateAdditionalFieldPassengerValue} from "@CheckOrder/reducers/checkOrderSlice";
import {useAppSelector} from "@Hooks/selector/useAppSelector";
import {ChangeEvent} from "react";
import {AdditionalFieldOptionType} from "@CheckOrder/features/TicketAdditionalInfo/types/AdditionalFieldOption.type";

export default function useUpdateAdditionalFieldPassengerValue(fieldName: string, passengerIndex: number, updateAllPassengers?: boolean) {
    const dispatch = useAppDispatch()
    const {passengers} = useAppSelector((state) => state.checkOrder)

    const updateValue = (value: string | number | boolean | AdditionalFieldOptionType, validateFunction: (value: string | number | boolean | AdditionalFieldOptionType) => (boolean) = () => true) => {
        if (validateFunction(value)) {
            if (updateAllPassengers) {
                passengers.map((passenger, index) => {
                    dispatch(updateAdditionalFieldPassengerValue(
                        {
                            value,
                            fieldName,
                            passengerIndex: index,
                        }
                    ))
                })
            } else {
                dispatch(updateAdditionalFieldPassengerValue(
                    {
                        value,
                        fieldName,
                        passengerIndex,
                    }
                ))
            }
        }
    }

    const updateAdditionalField = (validateFunction?: (value: string | number | boolean) => (boolean)) => (event: ChangeEvent<HTMLInputElement>) => {
        updateValue(event.target.value, validateFunction)
    }

    const onClick = (value: string) => {
        updateValue(value)
    }

    const onCountrySelect = (value: AdditionalFieldOptionType) => {
        updateValue(value)
    }

    const getFieldValue = () => {
        return passengers[passengerIndex].additionalInfo?.[fieldName]
    }

    return {
        updateAdditionalField,
        onClick,
        onCountrySelect,
        value: getFieldValue(),
        updateValue,
    }
}