import React from 'react';
import Sorter from "./Sorter";
import ActiveFiltersNotification from "../../../components/HeadingContent/ActiveFiltersNotification";
import MobileSorterContainer from "./MobileSorterContainer";
import useHasSearchResults from "../../../hooks/useHasSearchResults";

interface SortContainerProps {
    isMobile: boolean,
    isSmallTablet: boolean,
}

const SortContainer = ({isMobile, isSmallTablet}: SortContainerProps) => {
    const hasResults = useHasSearchResults()
    const filterClassName = (isMobile || isSmallTablet)
        ? 'gb--column'
        : 'gb--row gb--row-middle gb--row-justify'

    if (hasResults) {
        return (
            <div className={filterClassName}>
                {isMobile ? (
                    <MobileSorterContainer/>
                ) : (
                    <>
                        <ActiveFiltersNotification/>
                        <Sorter/>
                    </>
                )}
            </div>
        );
    }

    return (
        <div style={{height: "28.8px"}}/>
    )
}

export default SortContainer;