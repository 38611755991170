import {SearchResultsRtkQueryResponseType} from "@SearchResults/api/types/SearchResultsRtkQueryResponse.type";
import getSearchResultsCountPerType from "@SearchResults/functions/getSearchResultsCountPerType";

export default function getSearchResultsCount(searchResults: SearchResultsRtkQueryResponseType, includeUnavailable = true) {
    const {
        suggestedRoutesCount,
        singleRoutesCount,
        intermodalRoutesCount,
        tourRoutesCount,
    } = getSearchResultsCountPerType(searchResults, includeUnavailable)

    return singleRoutesCount + intermodalRoutesCount + suggestedRoutesCount + tourRoutesCount
}