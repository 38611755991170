export default function displayFooterAndCreditCardElement(display: boolean) {
    const footer = document.getElementsByTagName('footer')[0];
    const creditCards = document.getElementsByClassName('gb-credit__cards')[0];
    if (footer && creditCards) {
        if (display) {
            creditCards.classList.remove('hide')
            footer.classList.remove('hide')
        } else {
            creditCards.classList.add('hide')
            footer.classList.add('hide')
        }
    }
}