import {useScroll} from "@Scroll/hooks/useScroll";
import {useEffect, useState} from "react";
import {useLazyGetArchivedVouchersQuery, useLoadMoreArchivedVouchersMutation} from "@MyProfile/API/myProfileApi";

export default function useGetArchivedVouchers(showArchived: boolean) {
    const [page, setPage] = useState(1)

    const {elRef, executeScroll} = useScroll({block: "start"})

    const [fetchArchivedVouchers, {data, isLoading}] = useLazyGetArchivedVouchersQuery()
    const [fetchMoreArchivedVouchers, {isLoading: moreDataLoading}] = useLoadMoreArchivedVouchersMutation()

    useEffect(() => {
        if (elRef.current && !isLoading) {
            executeScroll()
        }
    }, [isLoading])

    useEffect(() => {
        if (!data && showArchived) {
            fetchArchivedVouchers({page, showArchived})
        }
    }, [data, showArchived])

    useEffect(() => {
        if (!!data && showArchived) {
            fetchMoreArchivedVouchers({page, showArchived})
        }
    }, [page])

    return {
        data,
        elRef,
        page,
        setPage,
        loading: isLoading,
        batchLoading: moreDataLoading,
    }
}
