import UserEventModel from "../models/UserEventModel";
import useTrackingEventProcessor from "./useTrackingEventProcessor";
import {useAppSelector} from "@Hooks/selector/useAppSelector";

/**
 * Dispatches single event via useTrackingEventProcessor
 */
export default function useSingleTrackingEvent(eventName: string, model: object) {
    const {user} = useAppSelector((state) => state.userProfile)
    const {experiments} = useAppSelector((state) => state.page)
    const processEvent = useTrackingEventProcessor()

    return () => {
        const event = UserEventModel(
            eventName,
            user?.id,
            experiments,
            model
        )

        return processEvent(event)
    }
}