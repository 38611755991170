import React, {useEffect} from 'react'
import TourInfo from "@SearchResults/features/tours/features/additionalInfo/TourInfo";
import usePrepareRouteForAdditionalInfo
    from "@SearchResults/features/additionalInfo/hooks/usePrepareRouteForAdditionalInfo";
import {useScroll} from "@Scroll/hooks/useScroll";
import MobileCompanyDetails from "@Route/features/mobile/components/MobileCompanyDetails";
import TourItineraryMobile from "@SearchResults/features/tours/features/itinerary/TourItineraryMobile";
import MobileContentContainer from "@Route/features/mobile/components/MobileContentContainer";
import MobileSummarySection from "@Route/features/mobile/components/MobileSummarySection";
import {TourType} from "@SearchResults/features/tours/types/Tour.type";
import ParamsType from "@SearchResults/types/params/Params.type";
import RouteActiveTicketContextType from "@Route/features/mobile/types/RouteActiveTicketContext.type";
import CompanyType from "@SearchResults/types/company/Company.type";
import PassengerGroupType from "@SearchResults/types/passenger/PassengerGroup.type";
import RouteActiveTourContextType from "@Route/features/mobile/types/RouteActiveTourContext.type";
import {
    IntermodalRouteActiveTicketContextType
} from "@Route/features/mobile/types/IntermodalRouteActiveTicketContext.type";
import {useTranslation} from "@Translation/hooks/useTranslation";
import TourDescription from "@SearchResults/features/tours/components/TourDescription";

interface TourAdditionalInfoProps {
    tour: TourType,
    displayActionButton?: boolean,
    params: ParamsType,
    isReturn?: boolean,
    activeTicketSections: RouteActiveTourContextType | RouteActiveTicketContextType | IntermodalRouteActiveTicketContextType,
    companies: Record<number, CompanyType>,
    passengerGroups: Record<number, PassengerGroupType>,
}

const TourAdditionalInfo = (props: TourAdditionalInfoProps) => {
    const tourItineraryLabel = useTranslation("tour.itinerary")
    const {
        tour,
        displayActionButton = true,
        params,
        companies,
        passengerGroups,
        isReturn,
        activeTicketSections,
    } = props

    const {
        tourInfoSection,
        companySection,
        notesSection,
        travelInfoSection,
    } = activeTicketSections

    const {prepareRoute} = usePrepareRouteForAdditionalInfo({
        companies,
        isReturn,
        passengerGroups,
    })

    const preparedRoute = prepareRoute(tour)

    const {scrollToTop} = useScroll({block: "center", behavior: "instant"})

    useEffect(() => {
        scrollToTop()
    }, [])

    return (
        <>
            <TourInfo
                tour={preparedRoute}
                title=""
                scrollRef={tourInfoSection?.refs.elRef}
            />
            <MobileCompanyDetails
                route={preparedRoute}
                company={preparedRoute.company}
                scrollRef={companySection?.refs.elRef}
                isTour
            />
            {tour.tourInfo.tourDescription && (
                <MobileContentContainer scrollRef={notesSection?.refs.elRef}>
                    <TourDescription description={tour.tourInfo.tourDescription}/>
                </MobileContentContainer>
            )}
            <MobileContentContainer
                title={tourItineraryLabel}
                scrollRef={travelInfoSection?.refs.elRef}
            >
                <TourItineraryMobile tour={preparedRoute}/>
            </MobileContentContainer>
            {displayActionButton && (
                <MobileSummarySection
                    isReturn={isReturn}
                    route={preparedRoute}
                    params={params}
                />
            )}
        </>
    )
}

export default TourAdditionalInfo