import React, {useEffect, useState} from 'react';
import Column from "@Containers/components/Column";
import {useAppSelector} from "@Hooks/selector/useAppSelector";
import Row from "@Containers/components/Row";
import AncillaryCheckbox from "@CheckOrder/features/ancillaries/components/AncillaryCheckbox";
import {useAppDispatch} from "@Hooks/dispatch/useAppDispatch";
import {useTranslation} from "@Translation/hooks/useTranslation";

type AncillaryCardProps = {
    ancillaryName: string,
    icon: any,
    cardTitleKey: string,
    cardTextKey: string,
    ancillaryCallback: any,
    ancillaryValue: any,
}
const AncillaryCard = ({ancillaryName, icon, cardTitleKey, cardTextKey, ancillaryCallback, ancillaryValue}: AncillaryCardProps) => {
    const dispatch = useAppDispatch();
    const onClick = () => {
        dispatch(ancillaryCallback(!ancillaryValue));
    };
    const ancillaryData = useAppSelector((state) => state.checkOrder.ticket.ancillariesData[ancillaryName]);
    const [isChecked, setIsChecked] = useState(ancillaryValue);
    useEffect(() => {
        setIsChecked(ancillaryValue);
    })

    return (
        <Row className="gb--card-ancillary" onClick={onClick}>
            <Row>
                <Column className="ancillary-checkbox-container">
                    <AncillaryCheckbox callback={onClick} ancillaryValue={isChecked}/>
                </Column>
                <Column className="ancillary-card-title-container">
                <span className="gb--card-title ancillary-card-title">
                    {useTranslation(cardTitleKey)}
                    {icon}
                </span>
                    <div className="ancillary-text-container">
                        {useTranslation(cardTextKey)}
                    </div>
                </Column>
            </Row>
            <Column className="ancillary-price-container">
                {`+ ${ancillaryData.displayPrice} ${ancillaryData.displayCurrencySymbol}`}
            </Column>
        </Row>
    )
};

export default AncillaryCard
