import React from 'react'
import Date from "@DateTime/components/Date";
import Time from "@DateTime/components/Time";

interface ItineraryTimestampProps {
    date: string,
    time: string
}

const ItineraryTimestamp = ({date, time}: ItineraryTimestampProps) => (
    <>
        <Date
            date={date}
            format="dd MMM | "
            className="route--date gb--emphasize-info"
        />
        <Time
            time={time}
            className="gb--emphasize-default"
        />
    </>
)

export default ItineraryTimestamp