import React from 'react';
import useIsDesktop from "@Hooks/selector/useIsDesktop";
import TravelInfo from "@SearchResults/components/HeadingContent/TravelInfo";
import MobileAppTitle from "@SearchResults/components/HeadingContent/Mobile/MobileAppTitle";
import Row from "@Containers/components/Row";
import BackButton from "@Routing/components/BackButton";
import Timer from "@CheckOrder/components/Sidebar/Timer";
import CheckOrderPurchasedRouteType from "@CheckOrder/types/CheckOrderPurchasedRoute.type";
import RouteType from "@Route/types/Route.type";
import useResolvePreviousRouteFromCheckOrderData from "@CheckOrder/hooks/useResolvePreviousRouteFromCheckOrderData";
import {useNavigate} from "react-router-dom";
import {TourType} from "@SearchResults/features/tours/types/Tour.type";
import {isTourRoute} from "@SearchResults/functions/isTourRoute";
import TourTitle from "@SearchResults/components/HeadingContent/TourTitle";

function CheckOrderTitle({singleRoute}: {singleRoute: RouteType | TourType}) {
    const {
        travelInfo: {
            departureStation: {
                cityName: departureStationName,
                departureDate,
            },
            destinationStation: {
                cityName: destinationStationName,
            },
        },
    } = singleRoute
    const isDesktop = useIsDesktop()
    const {goBack} = useResolvePreviousRouteFromCheckOrderData()

    const isTour = isTourRoute(singleRoute)

    return (
        <div className="gb--travel-info">
            {!isDesktop ? (
                <MobileAppTitle
                    backClick={goBack}
                    filtersButtonVisible={false}
                    subtitleComponent={<Timer/>}
                >
                    {isTour ? (
                        <TourTitle
                            departureStationName={departureStationName}
                            date={departureDate}
                            tourName={singleRoute?.tourInfo.tourName}
                        />
                    ) : (
                        <TravelInfo
                            departureStationName={departureStationName}
                            destinationStationName={destinationStationName}
                            date={departureDate}
                        />
                    )}
                </MobileAppTitle>
            ) : (
                <Row center justify className="gb-container">
                    <Row center>
                        <BackButton
                            backClick={goBack}
                        />
                        {isTour ? (
                            <TourTitle
                                departureStationName={departureStationName}
                                date={departureDate}
                                tourName={singleRoute?.tourInfo.tourName}
                            />
                        ) : (
                                <TravelInfo
                                departureStationName={departureStationName}
                                destinationStationName={destinationStationName}
                                date={departureDate}
                                />
                        )}
                    </Row>
                    <Timer/>
                </Row>
            )}
        </div>
    )
}

export default CheckOrderTitle;