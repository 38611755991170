import React from 'react';
import BuyerInfoSection from "./BuyerInfo/BuyerInfoSection";
import PassengersList from "./PassengersInfo/PassengersList";
import SubmissionForm from "./SubmissionForm/SubmissionForm";
import Voucher from "./Voucher/Voucher";
import PaymentSection from "./Summary/PaymentSection";
import AddonsSection from "@CheckOrder/features/addons/components/AddonsSection";
import Column from "@Containers/components/Column";
import AdditionalTicketInfo from "@CheckOrder/components/MainSection/AdditionalTicketInfo/AdditionalTicketInfo";
import {
    TicketAdditionalInfoFieldsType
} from "@CheckOrder/features/TicketAdditionalInfo/types/TicketAdditionalInfoFields.type";
import {isEmpty} from "@Array/isEmpty";
import AncillariesSection from "@CheckOrder/features/ancillaries/components/AncillariesSection";

type CheckOrderMainSectionProps = {
    isIntermodal: boolean,
    hasAddons: boolean,
    additionalTicketFields: TicketAdditionalInfoFieldsType[],
}
const CheckOrderMainSection = ({isIntermodal, hasAddons, additionalTicketFields}: CheckOrderMainSectionProps) => {
    return (
        <div className="check-order__main">
            <Column className="gb--card gb--check-order-passengers">
                <PassengersList/>
                {hasAddons && (
                    <AddonsSection/>
                )}
            </Column>
            {!isEmpty(additionalTicketFields) && (
                <AdditionalTicketInfo
                    additionalTicketFields={additionalTicketFields}
                />
            )}
            <BuyerInfoSection/>
            {!isIntermodal && (
                <Voucher/>
            )}
            <AncillariesSection/>
            <PaymentSection/>
            <SubmissionForm/>
        </div>
    )
};

export default CheckOrderMainSection;