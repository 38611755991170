import React from 'react'
import IconMTicketQrLeaf from "@Icons/IconMTicketQrLeaf";
import GenericPopover from "@Popover/components/GenericPopover";
import MTicketTransportTypeString from "@Route/components/MTicket/MTicketTransportTypeString";

interface MTicketIconTooltipProps {
    id: string,
    className?: string,
}

const MTicketIconTooltip = ({id, className = ""}: MTicketIconTooltipProps) => (
    <GenericPopover
        trigger={(
            <div className={className}>
                <IconMTicketQrLeaf/>
            </div>
        )}
        overlay={(
            <span>
                <MTicketTransportTypeString/>
            </span>
        )}
        id={id}
    />
)

export default MTicketIconTooltip