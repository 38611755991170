import React from 'react'
import OvernightTravelLabel from "@Route/components/Labels/OvernightTravelLabel";
import MinivanLabel from "@Route/components/Labels/MinivanLabel";
import PrivateBusLabel from "@Route/components/Labels/PrivateBusLabel";

interface RouteStatusLabelsProps {
    isMinivan?: boolean,
    isPrivateBus?: boolean,
    overnightTravel?: boolean,
    isPrivate?: boolean
    showOvernightTravel?: boolean
}

const RouteStatusLabels = (props: RouteStatusLabelsProps) => {
    const {
        isMinivan,
        isPrivateBus,
        overnightTravel,
        isPrivate,
        showOvernightTravel,
    } = props

    return (
        <>
            {showOvernightTravel && !isMinivan && !isPrivateBus && overnightTravel && (
                <OvernightTravelLabel/>
            )}

            {isMinivan && (
                <MinivanLabel isPrivate={isPrivate}/>
            )}

            {isPrivateBus && (
                <PrivateBusLabel/>
            )}
        </>
    )
}

export default RouteStatusLabels