import React, {ReactNode} from 'react';
import Translation from "@Translation/components/Translation";
import IconChevronDown from "@Icons/IconChevronDown";

interface MobileSortButtonProps {
    onClick: () => void,
    icon: ReactNode,
    title?: string,
    selectedValue: string,
}

const MobileSortButton = ({onClick, icon, title, selectedValue}: MobileSortButtonProps) => (
    <button
        className="sort gb--sorting-label"
        type="submit"
        onClick={onClick}
    >
        {title && (
            <>
                <Translation translationKey={title}/>
                : &nbsp;
            </>
        )}
        <span className="gb--tag gb--tag-green">
            {selectedValue}
            <IconChevronDown
                fill="#fff"
                style={{
                    stroke: 'white',
                    marginRight: '0',
                    verticalAlign: 'bottom',
                }}
            />
        </span>
    </button>
);

export default MobileSortButton;