import React from 'react';
import Row from "@Containers/components/Row";
import {setFilterRating} from "@SearchResults/reducers/filterSlice";
import FilterSlider from "@SearchResults/features/sidebar/components/FilterSlider";
import useFilterSliderValue from "@SearchResults/features/sidebar/hooks/useFilterSliderValue";

function RatingFilter() {
    const {
        value,
        updateValue,
        setValueState,
    } = useFilterSliderValue({
        onChange: setFilterRating,
        boundValue: "Rating",
        invert: true,
        override: 20,
    })
    const values = ["2.0", "4.0", "6.0", "8.0", "10.0"]

    return (
        <FilterSlider
            onChange={setValueState}
            value={value}
            min={-100}
            max={-20}
            onRelease={updateValue}
            step={20}
            className="gb--slider-flip"
        >
            <Row center justify className="gb--filters-filter-slider-steps">
                {values.map((value) => (
                    <span key={value} className="gb--filters-filter-slider-steps-step">
                       {value}
                        <span className="gb--filters-filter-slider-steps-step-indicator"/>
                    </span>
                ))}
            </Row>
        </FilterSlider>
    )
}

export default RatingFilter;