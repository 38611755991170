import React from 'react'
import Row from "@Containers/components/Row";
import IconClock from "@Icons/IconClock";
import GBBadge from "@/components/UI/BadgeUI/GBBadge";
import useIsMobile from "@Hooks/selector/useIsMobile";
import {TourType} from "@SearchResults/features/tours/types/Tour.type";
import formatDate from "@DateTime/functions/formatDate";
import parse from "date-fns/parse";
import ImageWithOverlay from "@Generic/ImageWithOverlay";

interface TourOverviewProps {
    tour: TourType
}

const TourOverview = ({tour}: TourOverviewProps) => {
    const {
        companyId,
        company: {
            logoUrl,
            name: companyName,
        },
        tourInfo: {
            tourName,
            isDayTrip,
            isWaterActivity,
            tourImageUrl,
        },
        travelInfo: {
            departureStation: {
                name: departureStationName,
                departureTime: departureTravelTime,
            },
            stations,
            travelDuration,
        },
    } = tour

    const isMobile = useIsMobile()

    const formatTime = (time: string) => {
        return formatDate(parse(time, 'HH:mm:ss', new Date()), 'HH:mm')
    }

    return (
        <div className={`gb--tour-overview ${isMobile ? 'gb--column' : 'gb--row'}`}>
            {!isMobile && (
                <ImageWithOverlay
                    imageUrl={tourImageUrl}
                    imgClass="gb--tour-image-custom "
                    customOverlayClass="gb--tour-image-overlay"
                />
            )}
            <div className={isMobile ? "" : "gb--tour-overview-data"}>
                <h2 className="tour-name">
                    {tourName}
                </h2>
                <span className="gb--emphasize-info gb--tour-departure-label">
                    {`${formatTime(departureTravelTime)} | ${departureStationName}`}
                </span>
                <span className="gb--emphasize-default gb--tour-timetable-label">
                    <IconClock/>
                    {' '}
                    {travelDuration}
                </span>
                <Row gap className="tour-labels-container">
                    {isWaterActivity && (
                        <GBBadge
                            badgeContent="Water activity"
                            appearance="info"
                            shape="rectangle"
                        />
                    )}
                    {isDayTrip && (
                        <GBBadge
                            badgeContent="Daytour"
                            appearance="primary"
                            shape="rectangle"
                        />
                    )}
                </Row>
            </div>
        </div>
    )
}

export default TourOverview