import React from 'react';
import GenericSvgIcon from "./GenericSvgIcon";
import IconType from "@/types/Icon.type";

const IconTourPins = (props: IconType) => {
    const {
        width = '16',
        height = '16',
        fill = '#070c63',
        viewBox = '0 0 16 16',
        title = 'IconTourPins',
        ...rest
    } = props

    return (
        <GenericSvgIcon
            width={width}
            height={height}
            fill={fill}
            viewBox={viewBox}
            title={title}
            {...rest}
            svgIconPath={(
                <g fill="none" transform="translate(0 1)">
                    <ellipse cx="12.307692" cy="8.307783" rx="1.230769" ry="1.230783"/>
                    <ellipse cx="4" cy="4.000043" rx="1.230769" ry="1.230783"/>
                    <g fill={props.fill}>
                        <path
                            d="m5.53846154 4.00004348c0-.84831691-.69015385-1.53847826-1.53846154-1.53847826s-1.53846154.69016135-1.53846154 1.53847826.69015385 1.53847826 1.53846154 1.53847826 1.53846154-.69016135 1.53846154-1.53847826zm-2.46153846 0c0-.50892861.41415384-.92308696.92307692-.92308696s.92307692.41415835.92307692.92308696-.41415384.92308695-.92307692.92308695-.92307692-.41415834-.92307692-.92308695z"
                        />
                        <path
                            d="m13.8461538 8.30778261c0-.84831691-.6901538-1.53847826-1.5384615-1.53847826s-1.5384615.69016135-1.5384615 1.53847826.6901538 1.53847826 1.5384615 1.53847826 1.5384615-.69016135 1.5384615-1.53847826zm-2.4615384 0c0-.50892861.4141538-.92308696.9230769-.92308696s.9230769.41415835.9230769.92308696-.4141538.92308696-.9230769.92308696-.9230769-.41415835-.9230769-.92308696z"
                        />
                        <path
                            d="m16 8.30778261c0-2.03602213-1.6563077-3.69234783-3.6923077-3.69234783s-3.69230768 1.6563257-3.69230768 3.69234783c0 2.04002219 1.46153848 4.20127639 2.61507688 5.23082609h-6.92276919c-.33938462 0-.61538462-.276003-.61538462-.6153913s.276-.6153913.61538462-.6153913h1.23076923c.67876923 0 1.23076923-.552006 1.23076923-1.2307826s-.552-1.23078263-1.23076923-1.23078263h-.45384616c1.228-1.03539587 2.91538462-3.26803552 2.91538462-5.84621739 0-2.20556244-1.79446154-4.00004348-4-4.00004348s-4 1.79448104-4 4.00004348c0 2.57818187 1.68738462 4.81082152 2.91538462 5.84621739h-2.91538462v.61539133h5.53846154c.33938461 0 .61538461.276003.61538461.6153913s-.276.6153913-.61538461.6153913h-1.23076923c-.67876923 0-1.23076923.552006-1.23076923 1.2307826s.552 1.2307826 1.23076923 1.2307826h11.69230769v-.6153913h-2.6150769c1.1535384-1.0295497 2.6150769-3.1908039 2.6150769-5.23082609zm-15.38461538-4.30773913c0-1.86617413 1.51846153-3.38465218 3.38461538-3.38465218s3.38461538 1.51847805 3.38461538 3.38465218c0 3.2834203-2.95076923 5.78621674-3.37630769 5.84621739h-.00153846c-.44061538-.06000065-3.39138461-2.56279709-3.39138461-5.84621739zm8.61538461 4.30773913c0-1.69663383 1.38030767-3.07695652 3.07692307-3.07695652s3.0769231 1.38032269 3.0769231 3.07695652c0 2.61756689-2.6190769 5.16097919-3.068 5.23082609h-.0015385c-.4652307-.0698469-3.08430767-2.6132592-3.08430767-5.23082609z"
                        />
                    </g>
                </g>
            )}
        />
    );
}

export default IconTourPins;
