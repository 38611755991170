import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {filterBounds, FilterBoundsState} from "@SearchResults/features/sidebar/constants/filterBounds";

interface FiltersState {
    filterDuration: number,
    filterRouteDepartureTime: number,
    filterPrice: number,
    filterRating: number,
    filterRouteArrivalTime: number,
    filterRouteChange: any,
}

const filters: FiltersState = {
    filterDuration: null,
    filterRouteDepartureTime: null,
    filterPrice: null,
    filterRating: null,
    filterRouteArrivalTime: null,
    filterRouteChange: null,
}

type SortByType = 'departure' | 'rating' | 'travel_time' | 'price'

interface FiltersSliceState extends FiltersState {
    sortBy: SortByType,
    filterDiscounts: any[],
    filterBounds: FilterBoundsState,
    filteredRoutesCount: number,
    totalRoutesCount: number,
    maxRoutePrice: number,
}

const initialState: FiltersSliceState = {
    ...filters,
    sortBy: 'departure',
    filterDiscounts: [],
    filterBounds,
    filteredRoutesCount: 0,
    totalRoutesCount: 0,
    maxRoutePrice: 0,
}

const filterSlice = createSlice({
    name: 'filter',
    initialState,
    reducers: {
        setSortBy: (state, action: PayloadAction<SortByType>) => (
            {...state, sortBy: action.payload}
        ),

        addSortingOptions: (state, action) => (
            {...state, sort: action.payload}
        ),

        setFilterPrice: (state, action) => (
            {...state, filterPrice: action.payload}
        ),

        setFilterDuration: (state, action) => (
            {...state, filterDuration: action.payload}
        ),

        setFilterArriveBefore: (state, action) => (
            {...state, filterRouteArrivalTime: action.payload}
        ),

        setFilterDepartAfter: (state, action) => (
            {...state, filterRouteDepartureTime: action.payload}
        ),

        setFilterDiscounts: (state, action) => (
            {...state, filterDiscounts: action.payload}
        ),

        setFilterRating: (state, action) => (
            {...state, filterRating: action.payload}
        ),

        setFilterRouteChange: (state, action) => (
            {...state, filterRouteChange: action.payload}
        ),

        setFilterBoundaries: (state, action) => (
            {...state, filterBounds: action.payload}
        ),

        setTotalRoutesCount: (state, action: PayloadAction<number>) => ({
            ...state,
            totalRoutesCount: action.payload,
        }),

        setFilteredRoutesCount: (state, action: PayloadAction<number>) => ({
            ...state,
            filteredRoutesCount: action.payload,
        }),

        resetAllFilters: (state) => ({
            ...state,
            ...filters,
        }),

        setMaxRoutePrice: (state, action) => (
            {
                ...state,
                maxRoutePrice: action.payload,
            }
        ),

        clear: () => initialState,
    },
})

export const {
    setSortBy,
    addSortingOptions,
    setFilterPrice,
    setFilterDuration,
    setFilterArriveBefore,
    setFilterDepartAfter,
    setFilterDiscounts,
    setFilterRating,
    setFilterRouteChange,
    setFilterBoundaries,
    setTotalRoutesCount,
    setFilteredRoutesCount,
    setMaxRoutePrice,
    resetAllFilters,
} = filterSlice.actions

export default filterSlice.reducer