import React from 'react';
import {useTranslation} from "@Translation/hooks/useTranslation";
import {useAppSelector} from "@Hooks/selector/useAppSelector";
import useActiveFiltersCount from "../../hooks/useActiveFiltersCount";
import useSearchResults from "@SearchResults/hooks/useSearchResults";
import useSearchResultsCount from "@SearchResults/hooks/useSearchResultsCount";
import useResetAllFilters from "@SearchResults/features/sidebar/hooks/useResetAllFilters";

interface ActiveFiltersNotificationProps {
    displayFilterCount?: boolean,
}

function ActiveFiltersNotification({displayFilterCount = true}: ActiveFiltersNotificationProps) {
    const {
        filteredRoutesCount,
    } = useAppSelector((state) => state.filter)
    const totalRoutesCount = useSearchResultsCount()
    const {isLoading} = useSearchResults()

    const {
        active: activeLabel,
        results: resultsLabel,
    } = useTranslation("sys")

    const filteredCountLabel = useTranslation("sts_page.showing", {
        count: filteredRoutesCount,
        total: totalRoutesCount,
    })

    const resetFiltersLabel = useTranslation("search_results.filters.reset_filters")
    const {resetFilters} = useResetAllFilters()
    const activeFiltersCount = useActiveFiltersCount()
    const routesFiltered = totalRoutesCount !== filteredRoutesCount
    const label = routesFiltered
        ? filteredCountLabel
        : `${filteredRoutesCount} ${resultsLabel}`

    return (
        <div className="active-filters-notification">
            {!isLoading && (
                <span data-notification={label}>
                    {label}
                </span>
            )}
            {displayFilterCount && activeFiltersCount > 0 && (
                <button className="gb--emphasize-info" type="button" onClick={resetFilters}>
                    {` | ${resetFiltersLabel} `}
                    <span className="gb--label gb--label-orange">
                        {`${activeFiltersCount} ${activeLabel}`}
                    </span>
                </button>
            )}
        </div>
    );
}

export default ActiveFiltersNotification;