import React from 'react';
import {setFilterDuration} from "@SearchResults/reducers/filterSlice";
import {useTranslation} from "@Translation/hooks/useTranslation";
import {getFormattedDuration} from "@DateTime/functions/getFormattedDuration";
import {convertMillisecondsToDuration} from "@DateTime/functions/convertMillisecondsToDuration";
import FilterSlider from "./FilterSlider";
import useFilterSliderValue from "../hooks/useFilterSliderValue";
import FilterSliderLabels from "./FilterSliderLabels";

function DurationFilter() {
    const label = useTranslation("search_results.filters.duration")
    const {
        value,
        updateValue,
        min,
        max,
        setValueState,
    } = useFilterSliderValue({
        onChange: setFilterDuration,
        boundValue: "Duration",
    })

    const tripDuration = getFormattedDuration(convertMillisecondsToDuration(value ?? max))

    return (
        <FilterSlider
            onChange={setValueState}
            value={value}
            min={min}
            max={max}
            onRelease={updateValue}
        >
            <FilterSliderLabels
                label={label}
                value={tripDuration}
            />
        </FilterSlider>
    )
}

export default DurationFilter;