import {useAppSelector} from "@Hooks/selector/useAppSelector"

// get additional passenger field by a field name, for example, "nationality"
export default function useGetAdditionalPassengerFieldByName() {
    const {
        additionalInfoFields,
    } = useAppSelector((state) => state?.checkOrder?.ticket)

    const getAdditionalPassengerFieldByName = (fieldName: string) => {
        const allFields = [
            ...additionalInfoFields.additionalPassengerFields,
            ...additionalInfoFields.additionalTicketFields,
        ]

        return allFields.filter((additionalField) => (
            additionalField.key === fieldName
        ))[0]
    }

    return {
        getAdditionalPassengerFieldByName,
    }
}