import React, {useRef} from 'react';
import {useToggle} from "@Hooks/dispatch/useToggle";
import useConfirmAndPay from "@CheckOrder/hooks/useConfirmAndPay";
import {useAppSelector} from "@Hooks/selector/useAppSelector";
import TermsAndConditions from "@CheckOrder/components/MainSection/TermsAndConditions/TermsAndConditions";
import ConfirmAndPayButton from "@CheckOrder/components/MainSection/SubmissionForm/ConfirmAndPayButton";
import usePurchasedRoute from "@CheckOrder/hooks/usePurchasedRoute";
import useGetDisplayPriceWithAncillaries from "@CheckOrder/hooks/useGetDisplayPriceWithAncillaries";
import RouteType from "@Route/types/Route.type";

function SubmissionForm() {
    const {
        ticket,
        userConfirmed,
        passengerDataUpdating,
        isBuyingPremiumSupport,
    } = useAppSelector((state) => state?.checkOrder)

    const displayTicketType = (route: RouteType) => {
        return route.status.providerId !== 23
    }

    const purchasedRoute = usePurchasedRoute()
    const displayTicket = (displayTicketType(purchasedRoute.singleRoute) || (purchasedRoute?.returnRoute && displayTicketType(purchasedRoute.returnRoute)))
    const needsToBePrinted = displayTicket && (!purchasedRoute.singleRoute.status.hasMTicket || (purchasedRoute?.returnRoute && !purchasedRoute?.returnRoute?.status?.hasMTicket))

    const newsletter = useToggle()
    const needsToBePrintedNotice = useToggle()
    const privacyPolicy = useToggle()
    const ipgFormRef = useRef()
    const confirmAndPay = useConfirmAndPay({
        ipgFormRef,
        privacyPolicyChecked: privacyPolicy.value,
        mustBePrintedPolicyChecked: needsToBePrinted ? needsToBePrintedNotice.value : true,
        newsletterAccepted: newsletter.value,
    })
    const {currSymbol, price: premiumSupportPrice} = useAppSelector((state) => state?.checkOrder.ancillaries.premiumSupport);

    const {
        singleRouteCompanyTermsUrl,
        returnRouteCompanyTermsUrl,
        totalPrice,
    } = ticket

    const carrierName = !ticket.isIntermodal ? purchasedRoute.companies[ticket.singleCompanyId].name : null

    const companyId = ticket.isIntermodal
        ? ticket.baseTickets[0].singleCompanyId
        : ticket.singleCompanyId
    return (
        <>
            <TermsAndConditions
                newsletter={newsletter}
                needsToBePrintedNotice={needsToBePrintedNotice}
                privacyPolicy={privacyPolicy}
                needsToBePrinted={needsToBePrinted}
                singleCompanyId={companyId}
                userConfirmed={userConfirmed}
                ipgFormRef={ipgFormRef}
                singleRouteCompanyTermsUrl={singleRouteCompanyTermsUrl}
                returnRouteCompanyTermsUrl={returnRouteCompanyTermsUrl}
                carrierName={carrierName}
            />
            <ConfirmAndPayButton
                price={useGetDisplayPriceWithAncillaries(true)}
                onClick={confirmAndPay}
                loading={passengerDataUpdating}
            />
        </>
    );
}

export default SubmissionForm;