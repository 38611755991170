import React from 'react';
import {useTranslation} from "@Translation/hooks/useTranslation";
import useIsMobile from "@Hooks/selector/useIsMobile";
import useBuyTicket from "@Route/features/ticketPurchase/hooks/useBuyTicket";
import LoaderButton from "@Generic/Loaders/LoaderButton";
import IntermodalRouteWithDetailsType from "@Intermodal/types/IntermodalRouteWithDetails.type";
import RouteWithAddedPropsAndPricesType from "@Route/types/RouteWithAddedPropsAndPrices.type";
import {useAppSelector} from "@Hooks/selector/useAppSelector";
import ParamsType from "@SearchResults/types/params/Params.type";
import BuyTicketLabel from "@Route/features/ticketPurchase/components/BuyTicketLabel";
import IconChevron from "@Icons/IconChevron";
import {TourType} from "@SearchResults/features/tours/types/Tour.type";
import {isEmpty} from "@Array/isEmpty";

interface BuyButtonProps {
    route: IntermodalRouteWithDetailsType | RouteWithAddedPropsAndPricesType | TourType,
    params: ParamsType
}

function BuyButton({route, params}: BuyButtonProps) {
    const {
        allotment: {
            totalAllotmentLeft,
        },
        status: {
            isSuggestion,
            isTour,
        },
    } = route

    const isMobile = useIsMobile()
    const buyTicketTranslation = useTranslation("search_results.buy_ticket")
    // @ts-ignore
    const passengers = useAppSelector((state) => state?.form.passengers)
    const {passengers: totalPassenger} = passengers
    const className = totalAllotmentLeft < totalPassenger ? 'btn--disabled' : 'btn--blue'
    const buttonCompact = isSuggestion || buyTicketTranslation.length >= 15 ? 'btn--compact' : ''
    const suggestionClass = isSuggestion ? 'btn--suggestion' : ''
    const tourClass = isTour ? 'gb--tour-btn' : ''
    const {buyTicket, loading} = useBuyTicket(route, params)

    return (
        <button
            type="submit"
            style={loading ? {paddingTop: "7px", paddingBottom: "7px"} : {}}
            className={`btn ${className} ${buttonCompact} ${suggestionClass} ${loading ? '' : tourClass} ${isMobile ? 'btn--mobile' : ''} routes-list__cta routes-list__cta--operator`}
            onClick={buyTicket}
        >
            {loading ? (
                <LoaderButton/>
            ) : (
                <>
                    <BuyTicketLabel
                        route={route}
                    />
                    {isTour && (
                        <div className="gb--tour-btn-arrow">
                            <IconChevron
                                chevronOrientation="right"
                                fill="#ffffff"
                                width="24"
                                height="24"
                            />
                        </div>
                    )}
                </>
            )}
        </button>
    );
}

export default BuyButton