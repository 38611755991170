import filterDuplicates from "@Array/filterDuplicates";
import {SearchResultsRtkQueryResponseType} from "@SearchResults/api/types/SearchResultsRtkQueryResponse.type";
import RouteType from "@Route/types/Route.type";
import {RoutesOrganizedByTransportTypeType} from "@SearchResults/types/RoutesOrganizedByTransportType.type";
import IntermodalRouteType from "@Intermodal/types/IntermodalRoute.type";
import {isSuggestedRoute} from "@SearchResults/functions/isSuggestedRoute";
import {isIntermodalRoute} from "@SearchResults/functions/isIntermodalRoute";

export default function getSearchResultsCountPerType(searchResults: SearchResultsRtkQueryResponseType, includeUnavailable = true) {
    const getCount = (object: RoutesOrganizedByTransportTypeType|IntermodalRouteType[]) => {
        const allRoutes: RouteType[] = filterDuplicates(Object.values(object).flat(Infinity), 'key')
        if (includeUnavailable) {
            return allRoutes.length
        }

        return allRoutes.filter((route: RouteType|IntermodalRouteType) => {
            if (isSuggestedRoute(route) || isIntermodalRoute(route)) {
                if (route.baseRoutes.every((route) => route.status.status === "open")) {
                    return route
                }
            } else {
                return route.status.status === 'open'
            }
        }).length
    }

    if (searchResults?.result) {
        const {
            singleRoutes,
            intermodalRoutes,
            suggestedRoutes,
            tourRoutes,
        } = searchResults.result;

        return {
            singleRoutesCount: getCount(singleRoutes),
            intermodalRoutesCount: getCount(intermodalRoutes),
            suggestedRoutesCount: getCount(suggestedRoutes),
            tourRoutesCount: getCount(tourRoutes),
        }
    }

    return {
        singleRoutesCount: 0,
        intermodalRoutesCount: 0,
        suggestedRoutesCount: 0,
    }
}