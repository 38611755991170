import React from 'react'
import Row from "@Containers/components/Row";
import Column from "@Containers/components/Column";

interface StationDisplayProps {
    name: string,
    description?: string,
    customClassName?: string,
    circleColor?: string,
    lineClassName?: string
}

const StationDisplay = ({name, description, customClassName, lineClassName = "", circleColor = "blue"}: StationDisplayProps) => (
    <div className="tour-itinerary-station-container">
          <div className="gb--connect-divider_vertical gb--connect-divider-itinerary">
            <span className={`station-list-connecter station-list-connecter-${lineClassName}`}/>
          </div>
        <span className={`gb--connect-circle-itinerary gb--connect-circle-itinerary--${circleColor}`}/>
        <Column className={customClassName ?? "tour-itinerary-station-display"}>
         <span className="gb--emphasize-default">
                {name}
         </span>
            {description && (
                <span className="gb--input-label">
                    {description}
                </span>
            )}
        </Column>
    </div>
)

export default StationDisplay