import React from 'react'

interface TicketTourTitleProps {
    tourName: string
}

const TicketTourTitle = ({tourName}: TicketTourTitleProps) => (
    <span className="station-info gb--emphasize-default">
        {tourName}
    </span>
)

export default TicketTourTitle