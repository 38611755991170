import useOnMount from "@Hooks/lifecycle/useOnMount";
import dismissPlaceholders from "@Features/domElements/functions/dismissPlaceholders";
import {useAppSelector} from "@Hooks/selector/useAppSelector";
import useIsMobile from "@Hooks/selector/useIsMobile";
import useIsSmallTablet from "@Hooks/selector/useIsSmallTablet";
import useRouteMatch from "@Routing/hooks/useRouteMatch";

/**
 * This hook is currently required due to css/element interaction outside react-scope
 * This is far from ideal and should be updated at some point.
 */
export default function useToggleDOMElements() {
    const isMobile = useIsMobile()
    const isSmallTablet = useIsSmallTablet()
    const isCheckOrder = useRouteMatch('checkOrder')
    const isSearchResults = useRouteMatch('searchResults')
    const isHomepage = useRouteMatch('homepage')
    const isAdditionalInfo = useRouteMatch('additionalInfo')
    const isMyProfile = useRouteMatch('myProfile')
    const {
        mobileOverlayActive,
        formInputSelected,
        mobileLanguagesActive,
        sortingOptionsActive,
    } = useAppSelector((state) => state?.mobile)
    useOnMount(dismissPlaceholders)
    const isSmallScreen = isMobile || isSmallTablet
    const hide = isSmallScreen
        && (mobileOverlayActive
            || formInputSelected
            || mobileLanguagesActive
            || isAdditionalInfo
            || !!sortingOptionsActive)

    const footer = document.getElementsByTagName('footer')[0];
    const body = document.getElementsByTagName('body')[0];
    const creditCards = document.getElementsByClassName('gb-credit__cards')[0];
    const mainContent = document.getElementById('main-content')

    if (hide || isCheckOrder || (isMyProfile && isSmallScreen)) {
        if (!!footer && !!creditCards) {
            footer.classList.add('hide')
            creditCards.classList.add('hide')
        }
        if (!!mainContent && !isCheckOrder) {
            mainContent.classList.add('hide')
        }
    } else {
        if (!!footer && !!creditCards) {
            footer.classList.remove('hide')
            creditCards.classList.remove('hide')
        }
        if (mainContent) {
            mainContent.classList.remove('hide')
        }
    }

    if (isSearchResults) {
        body.classList.add('gb--search-results')
        body.classList.add('search-results')
        body.classList.remove('gb--check-order')
        body.classList.remove('homepage')
        body.classList.remove('homepage--gbb')
    }

    if (isHomepage) {
        body.classList.remove('gb--search-results')
        body.classList.remove('search-results')
        body.classList.add('homepage')
        body.classList.add('homepage--gbb')
    }

    if (isCheckOrder) {
        body.classList.add('gb--main-content')
        body.classList.remove('gb--search-results')
        body.classList.remove('search-results')
    }
}