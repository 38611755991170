import React from 'react'
import IconCalendarWithDate from "@Icons/IconCalendarWithDate";
import Column from "@Containers/components/Column";
import formatDate from "@DateTime/functions/formatDate";

interface TourTitleProps {
    departureStationName: string,
    tourName: string,
    date: string
}

const TourTitle = ({departureStationName, tourName, date}: TourTitleProps) => {
    return (
        <div className="gb--row gb--row-middle gb--travel-info__title">
            <IconCalendarWithDate width="5vh" height="4.5vh" date={date}/>
            <Column>
                <div>
                    <span className="gb--emphasize gb--emphasize-default">
                        {tourName}
                    </span>
                    <small className="gb--emphasize-default">
                        {`(from ${departureStationName})`}
                    </small>
                </div>

                <small>
                    {formatDate(date, "eeee, dd MMMM yyyy", true)}
                </small>
            </Column>
        </div>
    )
}

export default TourTitle