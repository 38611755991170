import {useScroll} from "@Scroll/hooks/useScroll";
import {useEffect, useState} from "react";
import {useLazyGetArchivedTicketsQuery, useLoadMoreArchivedTicketsMutation} from "@MyProfile/API/myProfileApi";
import {useAppSelector} from "@Hooks/selector/useAppSelector";

export default function useGetArchivedTickets() {
    const {showArchived} = useAppSelector((state) => state.userProfile.filter)

    const [page, setPage] = useState(1)

    const {elRef, executeScroll} = useScroll({block: "start"})

    const [fetchArchivedTickets, archivedTickets] = useLazyGetArchivedTicketsQuery()
    const [fetchMoreArchivedTickets, {isLoading: moreDataLoading}] = useLoadMoreArchivedTicketsMutation()

    const {
        data,
        isLoading,
    } = archivedTickets

    useEffect(() => {
        if (elRef.current && !isLoading) {
            executeScroll()
        }
    }, [isLoading])

    // On initial render, or if data was changed and data is empty (on clearing state) fetch first batch of tickets
    useEffect(() => {
        if (!data && showArchived) {
            fetchArchivedTickets({page, showArchived})
        }
    }, [data, showArchived])

    // If page changes and data exists, fetch more valid tickets
    useEffect(() => {
        if (!!data && showArchived) {
            fetchMoreArchivedTickets({page, showArchived})
        }
    }, [page])

    return {
        data,
        elRef,
        page,
        setPage,
        loading: isLoading,
        batchLoading: moreDataLoading,
    }
}
