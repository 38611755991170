import {RoutesOrganizedByTransportTypeType} from "@SearchResults/types/RoutesOrganizedByTransportType.type";
import {ToursOrganizedByTransportType} from "@SearchResults/types/ToursOrganizedByTransportType.type";

export default function mergeRoutesAndTours(singleRoutes: RoutesOrganizedByTransportTypeType, tourRoutes?: ToursOrganizedByTransportType): RoutesOrganizedByTransportTypeType {
    const routes = []
    Object.keys(singleRoutes).map((routeType) => {
        const singleRoutesPerType = singleRoutes[routeType] ?? []
        const tourRoutesPerType = tourRoutes[routeType] ?? []
        routes[routeType] = [...singleRoutesPerType, ...tourRoutesPerType]
    })

    return routes
}