import {useCountArchivedVouchersQuery} from "@MyProfile/API/myProfileApi";

export default function useCountArchivedVouchers() {
    const {
        data,
        isUninitialized,
        isLoading,
        isFetching,
    } = useCountArchivedVouchersQuery()

    const archivedVouchersCountLoading = isUninitialized || isLoading || isFetching

    return {
        archivedVouchersCount: data?.count,
        archivedVouchersCountLoading,
    }
}