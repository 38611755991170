import React from 'react';

interface EmphasizedStringSectionProps {
    className?: string,
    prefix: string,
    emphasized: string,
    suffix: string,
}

function EmphasizedStringSection(props: EmphasizedStringSectionProps) {
    const {
        prefix,
        emphasized = "gb--emphasize gb--emphasize-default",
        suffix,
        className,
    } = props

    return (
        <span>
            {`${prefix} `}
            <span className={className}>
                {emphasized}
            </span>
            {` ${suffix}`}
        </span>
    );
}

export default EmphasizedStringSection;