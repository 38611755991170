import React from 'react'
import PriceDetails from "@Route/components/Price/PriceDetails";
import GenericPopover from "@Popover/components/GenericPopover";
import RoutePerPassengerPrice from "@Route/components/Price/RoutePerPassengerPrice";
import RouteWithAddedPropsAndPricesType from "@Route/types/RouteWithAddedPropsAndPrices.type";
import TourWithAddedPropsAndPricesType from "@SearchResults/features/tours/types/TourWithAddedPropsAndPrices.type";

interface RoutePerPassengerPriceContainerProps {
    route: RouteWithAddedPropsAndPricesType | TourWithAddedPropsAndPricesType,
    totalPrice: number,
    className?: string
}

const RoutePerPassengerPriceContainer = ({route, totalPrice, className}: RoutePerPassengerPriceContainerProps) => (
    <GenericPopover
        id={route.id}
        overlay={(
            <PriceDetails
                totalPrice={totalPrice}
                route={route}
            />
        )}
        trigger={(
            <RoutePerPassengerPrice
                route={route}
                className={className}
            />
        )}
    />
)

export default RoutePerPassengerPriceContainer