import React from 'react'
import useIsMobile from "@Hooks/selector/useIsMobile";
import {isMinivanRoute} from "@Route/features/transportType/functions/isMinivanRoute";
import {isPrivateBusRoute} from "@Route/features/transportType/functions/isPrivateBusRoute";
import RouteClosedOrUnavailable from "@Route/components/Price/RouteClosedOrUnavailable";
import TotalAllotmentLeft from "@Route/components/Price/TotalAllotmentLeft";
import Discount from "@Route/components/Price/Discount/Discount";
import RouteActionButton from "@Route/features/ticketPurchase/components/RouteActionButton";
import ParamsType from "@SearchResults/types/params/Params.type";
import BuyButtonMobile from "@SearchResults/features/tours/components/BuyButtonMobile";
import Column from "@Containers/components/Column";
import MTicketIconTooltip from "@Route/components/MTicket/MTicketIconTooltip";
import Row from "@Containers/components/Row";
import TourWithAddedPropsAndPricesType from "@SearchResults/features/tours/types/TourWithAddedPropsAndPrices.type";
import RouteStatusLabels from "@Route/components/Labels/RouteStatusLabels";
import RoutePerPassengerPriceContainer from "@Route/components/Price/RoutePerPassengerPriceContainer";

interface TourStatusContainerProps {
    route: TourWithAddedPropsAndPricesType,
    isReturn: boolean,
    params: ParamsType,
}

const TourStatusContainer = (props: TourStatusContainerProps) => {
    const {
        route,
        isReturn,
        params,
    } = props

    const {
        id,
        prices,
        status: {
            status,
            soldOut,
            isSuggestion,
            hasMTicket,
        },
        allotment: {
            totalAllotmentLeft,
        },
        travelInfo: {
            departureStation: {
                departureDate,
            },
            destinationStation: {
                arrivalDate,
            },
        },
        type,
    } = route

    const {discounts, price} = prices
    const isMobile = useIsMobile()
    const hasDiscounts = !isSuggestion && !!Object.keys(discounts)?.length && status === 'open'
    const overnightTravel = departureDate !== arrivalDate
    const isMinivan = isMinivanRoute(type.routeTransferType)
    const isPrivateBus = isPrivateBusRoute(type.routeTransferType)

    return (
        <Column justify alignEnd>
            <div>
                {(status === 'closed' || status === 'unavailable') ? (
                    <RouteClosedOrUnavailable
                        soldOut={soldOut}
                        closed={status === 'closed'}
                        unavailable={status === 'unavailable'}
                    />
                ) : (
                    <>
                        <TotalAllotmentLeft
                            totalAllotmentLeft={totalAllotmentLeft}
                        />
                        <Row>
                            <MTicketIconTooltip
                                id={id}
                                className="m-ticket-icon"
                            />
                            <RoutePerPassengerPriceContainer
                                route={route}
                                totalPrice={price}
                                className="gb--emphasize-default gb--tour-price-total"
                            />
                        </Row>
                    </>
                )}

                <RouteStatusLabels
                    isMinivan={isMinivan}
                    isPrivateBus={isPrivateBus}
                    overnightTravel={overnightTravel}
                    isPrivate={type.isPrivate}
                />

                {hasDiscounts && (
                    <Discount
                        prices={prices}
                    />
                )}
            </div>

            {status === 'open' && (
                <div>
                    {!isMobile ? (
                        <RouteActionButton
                            params={params}
                            route={route}
                            expandedPanel={false}
                            className="gb--tour-element"
                            isReturn={isReturn}
                        />
                    ) : (
                        <BuyButtonMobile route={route} params={params}/>
                    )}
                </div>
            )}

        </Column>
    );
}

export default TourStatusContainer