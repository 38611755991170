import {createSlice} from '@reduxjs/toolkit'
import rootExtractor from "@Dataset/rootExtractor";
import IntToBool from "@Number/intToBool";
import setStringAsKey from "@Utils/setStringAsKey";
import DepartureStationModel from "@Form/models/DepartureStationModel";
import DestinationStationModel from "@Form/models/DestinationStationModel";
import FormErrorsModel from "@Form/models/FormErrorsModel";
import isCurrentRouteSearchResults from "@SearchResults/functions/IsCurrentRouteSearchResults";
import {serialize} from "@Object/serialize";
import resolveUrlParamsFromSearchResults from "../resolvers/resolveUrlParamsFromSearchResults";
import {passengerFetcher} from "../resolvers/passengerFetcher";
import PassengerCountType from "@Form/types/PassengerCount.type";
import StationInputFieldType from "@Form/types/StationInputField.type";
import UrlParamsType from "@Form/types/UrlParams.type";

const searchResults = JSON.parse(rootExtractor('searchResults'));
const {innerWidth} = window
const isSearchResults = isCurrentRouteSearchResults()
const formVisible = false

interface ResolvedFormState {
    urlParams: UrlParamsType,
    departureValue: string,
    departureStationObject: StationInputFieldType,
    destinationStationObject: StationInputFieldType,
    destinationValue: string,
}

interface FormSliceState extends ResolvedFormState {
    passengers: PassengerCountType,
    transportTypeRouteTranslation: string,
    passengerPickerActive: boolean,
    singleCalendarActive: boolean,
    returnCalendarActive: boolean,
    stationsListActive: boolean,
    reloadOnSearch: boolean,
    formVisible: boolean,
    isBig: boolean,
    // Disabled as of 05.02.2021 - IntToBool(rootExtractor('showBusRent')),
    busRentMinPax: any,
    departureTopStations: any[],
    destinationSuggestions: any[],
    destinationTopStations: any[],
    formErrors: any,
    submitTimestamp?: number,
}

const resolvedState: ResolvedFormState = resolveUrlParamsFromSearchResults(searchResults?.result?.params)

const initialState: FormSliceState = {
    ...resolvedState,
    passengers: passengerFetcher(),
    transportTypeRouteTranslation: rootExtractor('transportTypeRouteTranslation'),
    passengerPickerActive: false,
    singleCalendarActive: false,
    returnCalendarActive: false,
    stationsListActive: false,
    reloadOnSearch: false,
    formVisible,
    isBig: IntToBool(rootExtractor('isBig')),
    busRentMinPax: JSON.parse(rootExtractor('busRentMinPax')),
    departureTopStations: [],
    destinationSuggestions: [],
    destinationTopStations: [],
    formErrors: serialize(new FormErrorsModel(resolvedState.urlParams)),
    submitTimestamp: null,
}

const formSlice = createSlice({
    name: 'form',
    initialState,
    reducers: {
        togglePassengerPicker: (state) => (
            {...state, passengerPickerActive: !state.passengerPickerActive}
        ),

        setSingleCalendarActive: (state, action) => (
            {...state, singleCalendarActive: action.payload}
        ),

        setReturnCalendarActive: (state, action) => (
            {...state, returnCalendarActive: action.payload}
        ),

        setFormVisibility: (state, action) => (
            {...state, formVisible: action.payload}
        ),

        setFormVisibilityByResize: (state) => ({
            ...state,
            formVisible: window.innerWidth >= 680 || !isSearchResults,
        }),

        setTopStations: (state, action) => ({
            ...state,
            ...setStringAsKey(action.payload, 'TopStations'),
        }),

        setDepartureStationObject: (state, action) => {
            const {payload} = action
            const {
                departureStationObject,
                urlParams,
            } = serialize(new DepartureStationModel({
                payload,
                urlParams: state.urlParams,
            }))

            return {
                ...state,
                departureStationObject,
                urlParams,
            }
        },

        setDestinationStationObject: (state, action) => {
            const {payload} = action
            const {
                destinationStationObject,
                urlParams,
            } = serialize(new DestinationStationModel({
                payload,
                urlParams: {...state.urlParams},
            }))

            return {
                ...state,
                destinationStationObject,
                urlParams,
            }
        },

        toggleStations: (state) => ({
            ...state,
            destinationStationObject: state.departureStationObject,
            departureStationObject: state.destinationStationObject,
            urlParams: {
                ...state.urlParams,
                fromSlug: state.urlParams.toSlug,
                fromId: state.urlParams.toId,
                toSlug: state.urlParams.fromSlug,
                toId: state.urlParams.fromId,
            },
            departureValue: state.destinationValue,
            destinationValue: state.departureValue,
        }),

        setDate: (state, action) => ({
            ...state,
            urlParams: {
                ...state.urlParams,
                date: action.payload,
            },
        }),

        setReturnDate: (state, action) => ({
            ...state,
            urlParams: {
                ...state.urlParams,
                returnDate: action.payload,
            },
        }),

        addPassenger: (state, action) => ({
            ...state,
            passengers: {
                ...state.passengers,
                passengers: state.passengers.passengers + 1,
                [action.payload]: state.passengers[action.payload] + 1,
            },
        }),

        removePassenger: (state, action) => {
            if (state.passengers.passengers > 1 && state.passengers[action.payload] >= 1) {
                return {
                    ...state,
                    passengers: {
                        ...state.passengers,
                        passengers: state.passengers.passengers - 1,
                        [action.payload]: state.passengers[action.payload] - 1,
                    },
                }
            }
        },

        setFormErrors: (state, action) => ({
            ...state,
            formErrors: action.payload,
            submitTimestamp: Date.now(),
        }),

        resolveFromPartialUrl: (state, action) => ({
            ...state,
            ...action.payload,
            reloadOnSearch: true,
        }),

        updateFormDataFromHistory: (state, action) => ({
            ...state,
            urlParams: action.payload,
            passengers: action.payload.passengers,
        }),

        toggleForm: (state) => ({...state, formVisible: !state.formVisible}),
        clearForm: () => initialState,
    },
})

export const {
    togglePassengerPicker,
    setSingleCalendarActive,
    setReturnCalendarActive,
    clearForm,
    setFormVisibility,
    setFormVisibilityByResize,
    setDepartureStationObject,
    setDestinationStationObject,
    setDate,
    setReturnDate,
    addPassenger,
    removePassenger,
    toggleStations,
    setTopStations,
    toggleForm,
    setFormErrors,
    resolveFromPartialUrl,
    updateFormDataFromHistory,
} = formSlice.actions

export default formSlice.reducer