import {useCountValidTicketsQuery} from "@MyProfile/API/myProfileApi";

export default function useCountValidTickets() {
    const {
        data,
        isUninitialized,
        isLoading,
    } = useCountValidTicketsQuery()

    const validTicketsCountLoading = isUninitialized || isLoading

    return {
        validTicketsCount: data?.count,
        validTicketsCountLoading,
    }
}