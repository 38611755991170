import parseISO from "date-fns/parseISO";
import {useEffect, useState} from "react";
import isAfter from "date-fns/isAfter";
import formatDate from "@DateTime/functions/formatDate";

/**
 * Returns formatted date string from milliseconds
 */
export default function useMillisecondsToDateConverter(value: number | string, searchedDate: any): string {
    const [date, setDate] = useState("Anytime")
    const generateDate = () => {
        if (value) {
            const dateFromMilliseconds = new Date(parseInt(`${value}`, 10))
            const onlyDate = parseISO(formatDate(dateFromMilliseconds))
            const dateInFuture = isAfter(onlyDate, parseISO(searchedDate))
            const format = dateInFuture ? "MM-dd | HH:mm" : "HH:mm"
            setDate(formatDate(dateFromMilliseconds, format))
        }
    }

    useEffect(generateDate, [value])

    return date
}