import React, {useEffect, useRef, useState} from 'react';
import Slider from "react-slick";
import useIsMobile from "@Hooks/selector/useIsMobile";
import useIsTablet from "@Hooks/selector/useIsTablet";
import DateSlickSlide from "@SearchResults/features/dateSlick/components/DateSlickSlide";
import useLimitSlidesToShow from "@SearchResults/features/dateSlick/hooks/useLimitSlidesToShow";
import createNewSliderDates from "@SearchResults/features/dateSlick/functions/createNewSliderDates";
import {sliderMoveDirections} from "@SearchResults/features/dateSlick/constants/sliderMoveDirections";
import useMoveSlider from "@SearchResults/features/dateSlick/hooks/useMoveSlider";
import useSelectNewSearchDate from "@SearchResults/features/dateSlick/hooks/useSelectNewSearchDate";
import DaysLimitType from "@SearchResults/features/dateSlick/types/DaysLimit.type";
import {sliderSetting} from "@SearchResults/features/dateSlick/constants/sliderSetting";
import DateSlickArrow from "@SearchResults/features/dateSlick/components/DateSlickArrow";
import useResetAllFilters from "@SearchResults/features/sidebar/hooks/useResetAllFilters";

interface DateSlickProps {
    /** Date for which search is executed */
    date: string,
    isReturn: boolean,
}

function DateSlick({date, isReturn}: DateSlickProps) {
    const isMobile = useIsMobile()
    const isTablet = useIsTablet()
    const limitSlidesToShow = useLimitSlidesToShow()

    const [
        daysLimit,
        setDaysLimit,
    ] = useState<DaysLimitType>({min: -10, max: 10})

    const [
        clientXonMouseUp,
        setClientXonMouseUp,
    ] = useState<number>(null)

    const [
        clientXonMouseDown,
        setClientXonMouseDown,
    ] = useState<number>(null)

    const [
        centralDate,
        setCentralDate,
    ] = useState<Date | string>(date)

    const [
        datesInSlider,
        setDatesInSlider,
    ] = useState(createNewSliderDates(date, daysLimit))

    const [
        datesInSliderLimit,
        setDatesInSliderLimit,
    ] = useState(limitSlidesToShow())

    const sliderRef = useRef<Slider>()

    useEffect(() => {
        setCentralDate(date)
    }, [date])

    useEffect(() => {
        setDatesInSlider(createNewSliderDates(centralDate, daysLimit))
    }, [centralDate])

    useEffect(() => {
        setDatesInSliderLimit(limitSlidesToShow())
    }, [isMobile, isTablet])

    const moveSlider = useMoveSlider({
        daysLimit,
        setDaysLimit,
        centralDate,
        setCentralDate,
    })

    const {selectNewSearchDate} = useSelectNewSearchDate(isReturn)
    const {resetFilters} = useResetAllFilters()
    const onClick = (date: string) => {
        selectNewSearchDate(date).then(() => {
            resetFilters()
        })
    }

    return (
        <div className="datestrip">
            <Slider
                ref={(slider) => (sliderRef.current = slider)}
                {...sliderSetting}
                initialSlide={daysLimit.max}
                prevArrow={(
                    <DateSlickArrow
                        customClassName="slick-prev"
                        orientation="left"
                        click={() => moveSlider(sliderMoveDirections.left)}
                    />
                )}
                nextArrow={(
                    <DateSlickArrow
                        customClassName="slick-next"
                        orientation="right"
                        click={() => moveSlider(sliderMoveDirections.right)}
                    />
                )}
                slidesToShow={datesInSliderLimit}
            >
                {datesInSlider.map((dateToDisplay) => (
                    <DateSlickSlide
                        key={dateToDisplay.toDateString()}
                        date={dateToDisplay}
                        isReturn={isReturn}
                        searchedDate={date}
                        setClientXonMouseDown={setClientXonMouseDown}
                        setClientXonMouseUp={setClientXonMouseUp}
                        selectNewSearchDate={onClick}
                    />
                ))}
            </Slider>
        </div>
    )
}

export default DateSlick