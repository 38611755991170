import React from 'react';
import RouteOtherDatesSuggestions from "@RouteSuggestions/components/RouteOtherDatesSuggestions";
import getRouteDateTime from "@Route/functions/getRouteDateTime";
import formatDate from "@DateTime/functions/formatDate";
import Notification from "@Features/notification/components/Notification";
import IconExclamationCircle from "@Icons/IconExclamationCircle";
import Translation from "@Translation/components/Translation";
import SuggestionsDataType from "@SearchResults/types/suggestions/SuggestionsData.type";
import ParamsType from "@SearchResults/types/params/Params.type";
import {PreparedRouteType} from "@Route/types/PreparedRoute.type";

interface NoReturnRoutesNotificationProps {
    singleSelectedRoute: PreparedRouteType,
    suggestionsData: SuggestionsDataType,
    params: ParamsType,
}

function NoReturnRoutesNotification({suggestionsData, singleSelectedRoute, params}: NoReturnRoutesNotificationProps) {
    const arrival = formatDate(getRouteDateTime({route: singleSelectedRoute, type: 'arrival'}));

    return (
        <>
            <Notification
                notificationType="orange"
                notificationIcon={<IconExclamationCircle width="18" height="18" fill="#f5a623"/>}
            >
                <Translation
                    translationKey="search_results.no_return_routes"
                    translationVariables={{arrivalTime: arrival}}
                />
            </Notification>
            <RouteOtherDatesSuggestions
                suggestionData={suggestionsData}
                params={params}
            />
        </>
    )
}

export default NoReturnRoutesNotification;