import React, {Fragment} from 'react';
import VehicleAddon from "@CheckOrder/features/addons/features/jadrolinija/components/VehicleAddon";
import {useAppSelector} from "@Hooks/selector/useAppSelector";
import Passenger from "@CheckOrder/components/MainSection/PassengersInfo/Passenger";
import {isPassengerVehicle} from "@CheckOrder/functions/isPassengerVehicle";

const PassengersList = () => {
    const passengers = useAppSelector((state) => state?.checkOrder.passengers)
    return (
        <>
            {passengers.map((passenger, index) => (
                <Fragment key={passenger.passengerId}>
                    {isPassengerVehicle(passenger) ? (
                        <VehicleAddon
                            key={passenger.passengerId}
                            vehicle={passenger}
                            index={index}
                        />
                    ) : (
                        <Passenger
                            key={passenger.passengerId}
                            index={index}
                            passenger={passenger}
                        />
                    )}
                </Fragment>
            ))}
        </>
    )
};

export default PassengersList